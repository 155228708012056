import React from 'react'
import Skeleton from 'react-loading-skeleton'

export default function ReportyDetailsSkeleton() {
    return (
        <div className="userInfo">
            <div className='cell'>
                <div>
                    <div className='icon-wrapper'>
                        <Skeleton className='userImg' height={"35px"} width={"35px"} />
                    </div>
                </div>
                <div>
                    <p className='userName'><Skeleton height={"20px"} width={"100px"} /></p>
                    <p className='userRole'><Skeleton height={"10px"} width={"100%"} /></p>
                </div>
            </div>
            <div className='cell'>
                <div className='icon-wrapper'>
                    <Skeleton className='userImg' height={"35px"} width={"35px"} />
                </div>
                <div>
                    <p className='label'><Skeleton height={"20px"} width={"100px"} /></p>
                    <p className='labelValue'><Skeleton height={"24px"} width={"100%"} /></p>
                </div>
            </div>
            <div className='cell'>
                <div className='icon-wrapper'>
                    <Skeleton className='userImg' height={"35px"} width={"35px"} />
                </div>
                <div>
                    <p className='label'><Skeleton height={"20px"} width={"100px"} /></p>
                    <p className='labelValue'><Skeleton height={"24px"} width={"100%"} /></p>
                </div>
            </div>
            <div className='cell'>
                <div className='icon-wrapper'>
                    <Skeleton className='userImg' height={"35px"} width={"35px"} />
                </div>
                <div>
                    <p className='label'><Skeleton height={"20px"} width={"100px"} /></p>
                    <p className='labelValue'><Skeleton height={"24px"} width={"100%"} /></p>
                </div>
            </div>
        </div>

    )
}
