import { createSlice } from "@reduxjs/toolkit";
import { defaultState } from "../../../../interfaces/auth";
import { getResponsibilityThunk } from "../../../../asyncThunks/manage/setups";

const initialState: defaultState = {
    error: null,
    data: [],
    loading: false,
};

export const getResponsibility = createSlice({
    name: "getResponsibility",
    initialState,
    reducers: {
        updateResponsibilitiesData: (state, action) => {
            if (state.data) {
                const updatedResponsibilities = action.payload;
                const index = state.data?.responsibility?.findIndex((responsibility: { _id: any; }) => responsibility._id === updatedResponsibilities._id);
                if (index !== -1) {
                    state.data.responsibility[index] = updatedResponsibilities;
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getResponsibilityThunk.pending, (state, action) => {
            state.error = null;
            // state.data = null;
            state.loading = true;
        });
        builder.addCase(getResponsibilityThunk.fulfilled, (state, action) => {
            if (action?.payload?.status?.toLowerCase() === "success") {
                state.error = null;
                state.data = action?.payload?.data;
            } else {
                state.error = action?.payload;
                state.data = [];
            }
            state.loading = false;
        });
        builder.addCase(getResponsibilityThunk.rejected, (state, action) => {
            state.error = action.payload;
            state.data = [];
            state.loading = false;
        });
    },
});

export const { updateResponsibilitiesData } = getResponsibility.actions;

export default getResponsibility.reducer;
