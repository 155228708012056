import { createAsyncThunk } from "@reduxjs/toolkit";
import { changestatusFrequency, getreporteesByIdUrl, getreporteesData, getreporteesListUrl } from "../../../helpers/backend_helper";
import toast from "react-hot-toast";

//change status reporteesList
export const getReporteesList = createAsyncThunk(
    "overView/reporteesList",
    async (props: any) => {
        try {
            const response = await getreporteesListUrl(props);
            if (response.status?.toLowerCase() === "success") {
                return await response;
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

interface ReporteesData {

    userId: string | undefined;
    division?: string;
    department?: string;
}

//change status getReporteesById
export const getReporteesById = createAsyncThunk(
    "overView/reporteesById",
    async (payload: ReporteesData) => {
        try {
            const response = await getreporteesByIdUrl(payload?.userId, payload);
            if (response.status?.toLowerCase() === "success") {
                return await response;
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

export const getReporteesThunk = createAsyncThunk(
    "overView/getReporteesThunk",
    async (payload: ReporteesData) => {
        try {
            const response = await getreporteesData(payload?.userId);
            if (response.status?.toLowerCase() === "success") {
                return await response;
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);