import { Label, Modal } from "reactstrap";
import closeIcon from '../../../assets/images/svgIcons/close_icon.svg';
import SecondaryButton from "../../buttons/SecondaryButton";
import PrimaryButton from "../../buttons/PrimaryButton";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { getClassificationThunk, getFrequencyThunk, getResponsibilityThunk, manageResponsibilityThunk } from "../../../store/asyncThunks/manage/setups";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { useEffect, useState } from "react";
import DropDown from "../../DropDown";
import { hoursOptions, minutesOptions } from "../../../constants/datas/dropdownOptions";
import ErrorValidationMessage from "../../ErrorValidationMessage";
import { descriptionLimit } from "../../../constants/HandleFormLimit";
import { ResponsibilityNameLimit } from "../../../constants/HandleFormLimit";
import { updateResponsibilitiesData } from "../../../store/slices/manage/setups/Responsibility/getResponsibility";

interface ManageResponsibilityModalProps {
    showModal: boolean,
    toggle: any,
    mode: 'Add' | 'Edit',
    EditData: any,
    handleModalPagination?: Function,
}

const ManageResponsibilityModal = (props: ManageResponsibilityModalProps) => {
    const { showModal, toggle, mode, EditData, handleModalPagination } = props;

    const dispatch = useAppDispatch();

    const ClassificationList = useAppSelector((state) => state?.getClassificationReducer?.data?.classification);
    const classificationListLoader = useAppSelector((state) => state?.getClassificationReducer?.loading);
    const FrequencyList = useAppSelector((state) => state?.getFrequencyReducer?.data?.frequency);
    const frequencyListLoader = useAppSelector((state) => state?.getFrequencyReducer?.loading);
    const addResponsibilityLoader = useAppSelector((state) => state?.manageResponsibilityReducer?.loading);

    const [selectedHours, setSelectedHours] = useState({ label: 'Hours', value: "" });
    const [selectedMinutes, setSelectedMinutes] = useState({ label: 'Minutes', value: "" });
    const [selectedClassification, setSelectedClassification] = useState('');
    const [selectedFrequency, setSelectedFrequency] = useState('');

    const ClassificationListData = ClassificationList?.filter((item: any) => {
        return item.label === EditData?.classificationFKId;
    });
    const FrequencyListdata = FrequencyList?.filter((item: any) => {
        return item.label === EditData?.frequencyFKId
    })

    const FormShcema = yup.object().shape({
        label: yup.string().required().test("maxLength", '-', val => {
            if (!val) return false;
            return val.length <= ResponsibilityNameLimit;
        }).trim().trim(),
        scope: yup.string().required().test("maxLength", '-', val => {
            if (!val) return false;
            return val.length <= descriptionLimit;
        }).trim(),
        classificationFKId: yup.string().test("select", '-', val => {
            if (!val) return false;
            else return true
        }),
        frequencyFKId: yup.string().test("select", '-', val => {
            if (!val) return false;
            else return true
        }),
        hr: yup.string().test("select", '-', val => {
            if (!val) return false;
            else return true
        }),
        min: yup.string().test("select", '-', val => {
            if (!val) return false;
            else return true
        }),
        textColorCode: yup.string(),
        bgColorCode: yup.string()
    });
    const formValues = {
        label: '',
        scope: '',
        classificationFKId: '',
        frequencyFKId: '',
        hr: "",
        min: "",
        textColorCode: '',
        bgColorCode: '',
    }

    const { handleSubmit, register, formState: { errors }, reset, setValue, clearErrors } = useForm({
        defaultValues: formValues,
        resolver: yupResolver(FormShcema),
    })

    const afterSuccess = () => {
        toggleClose();
        handleModalPagination && handleModalPagination();
    };


    const toggleModel = () => {
        if (EditData?._id) {
            resetFormValues();
        } else {
            toggleClose();
        }
    }

    const toggleClose = () => {
        if (mode === 'Add') {
            reset();
            handleClear();
        }
        toggle();
    }

    const onSubmit = (values: any) => {
       
        const newValues = {
            label: values?.label,
            scope: values?.scope,
            classificationFKId: values?.classificationFKId,
            frequencyFKId: values?.frequencyFKId,
            duration: `${values?.hr}:${values?.min}`,
            textColorCode:values?.textColorCode,
            bgColorCode:values?.bgColorCode,
        };
        const data: any = {}
        data['data'] = newValues;
        data['afterSuccess'] = afterSuccess;
       
        if (mode === 'Add') {
            dispatch(manageResponsibilityThunk(data));
        } else if (mode === 'Edit') {
            data['id'] = EditData?._id;
            dispatch(manageResponsibilityThunk(data));
        }
    }

    const resetFormValues = () => {
        reset({
            label: EditData?.label || "",
            scope: EditData?.scope || "",
            hr: EditData?.duration.split(':')[0] || "",
            min: EditData?.duration.split(':')[1] || "",
            classificationFKId: EditData?.classificationFKId || "",
            frequencyFKId: EditData?.frequencyFKId || "",
            textColorCode:EditData?.textColorCode ,
            bgColorCode:EditData?.bgColorCode 
        });
        setSelectedHours({ label: EditData?.duration.split(':')[0], value: EditData?.duration.split(':')[0] });
        setSelectedMinutes({ label: EditData?.duration.split(':')[1], value: EditData?.duration.split(':')[1] });
        setSelectedClassification(ClassificationListData);
        setSelectedFrequency(FrequencyListdata);
    }

    useEffect(() => {
        if (EditData?._id) {
            resetFormValues();
        }
    }, [EditData, EditData?._id])

    const handleClear = () => {
        reset({
            label: '',
            scope: '',
            classificationFKId: '',
            frequencyFKId: '',
            hr: '',
            min: '',
        });
        clearErrors();
        setSelectedHours({ label: 'Hours', value: '' });
        setSelectedMinutes({ label: 'MInutes', value: '' });
        setSelectedClassification('');
        setSelectedFrequency('');
    }

    return (
        <>
            <Modal size="md" isOpen={showModal} centered={true}
                className='manage-responsibility add-employee-model'
                toggle={toggleModel}
            >
                <div className="modal-header">
                    <h1 className='title'>{mode === 'Add' ? 'Add new responsibilities' : 'Update responsibilities'}</h1>
                    <span onClick={toggleClose} aria-hidden="true" className='close-icon'><img src={closeIcon} alt="close" /></span>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                    <div className="modal-body">
                        <div className="groups">
                            <div className='group-item'>
                                <Label className='required'>Responsibility name</Label>
                                <input type="text" className={`custom-input form-control ${errors.label?.message ? "error-input" : ""}`} {...register("label")} />
                                {errors.label?.message && <ErrorValidationMessage errors={errors.label} name="responsibility name" sizeMax={ResponsibilityNameLimit} />}
                            </div>
                        </div>
                        <div className="groups">
                            <div className="group-item">
                                <Label className='required'>Description</Label>
                                <textarea
                                    className={`custom-input form-control  custom-scroll text-area ${errors.scope?.message ? "error-input" : ""}`} {...register("scope")}
                                ></textarea>
                                {errors.scope?.message && <ErrorValidationMessage errors={errors.scope} name="description" sizeMax={descriptionLimit} />}
                            </div>
                        </div>
                        <div className="groups">
                            <div className="group-item">
                                <Label className='required'>Classification</Label>
                                <div className="dropdowngroup">
                                    <DropDown
                                        loading={classificationListLoader}
                                        mainClass="filter-dropdown"
                                        placeholder="Classification"
                                        options={ClassificationList || []}
                                        value={selectedClassification}
                                        onChange={(value) => {
                                            clearErrors('classificationFKId');
                                            setSelectedClassification(value);
                                            setValue('textColorCode',value?.textColorCode)
                                            setValue('bgColorCode',value?.bgColorCode)
                                            setValue('classificationFKId', value?.label)
                                        }}
                                    />
                                </div>
                                {errors.classificationFKId?.message && <ErrorValidationMessage errors={errors.classificationFKId} name="classification" />}
                            </div>
                            <div className="group-item">
                                <Label className='required'>Frequency</Label>
                                <div className="dropdown-group">
                                    <DropDown
                                        loading={frequencyListLoader}
                                        mainClass="filter-dropdown"
                                        placeholder="Frequency"
                                        options={FrequencyList || []}
                                        value={selectedFrequency}
                                        onChange={(value) => {
                                            clearErrors('frequencyFKId')
                                            setSelectedFrequency(value);
                                            setValue("frequencyFKId", value?.label)
                                        }}
                                    />
                                </div>
                                {errors.frequencyFKId?.message && <ErrorValidationMessage errors={errors.frequencyFKId} name="frequency" />}
                            </div>
                        </div>

                        <Label className='required'>Time duration</Label>
                        <div className=" duration-container">
                            <div className="groups">
                                <div className="group-item">
                                    <div>
                                        <div className="dropdown-group">
                                            <div className="duration">
                                                <div className="time-label">
                                                    <p>
                                                        HR
                                                    </p>
                                                </div>
                                                <div>
                                                    <DropDown
                                                        loading={false}
                                                        mainClass="filter-dropdown"
                                                        placeholder="Hours"
                                                        options={hoursOptions}
                                                        value={selectedHours}
                                                        onChange={(value) => {
                                                            clearErrors('hr')
                                                            setSelectedHours(value);
                                                            setValue('hr', value?.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {errors.hr?.message && <ErrorValidationMessage errors={errors.hr} name="hours" />}
                                    </div>
                                </div>
                                <div className="group-item">
                                    <div className="dropdown-group">
                                        <div className="duration">
                                            <div className="time-label">
                                                <p>
                                                    MIN
                                                </p>
                                            </div>
                                            <div>
                                                <DropDown
                                                    loading={false}
                                                    mainClass="filter-dropdown"
                                                    placeholder="Minutes"
                                                    options={minutesOptions}
                                                    value={selectedMinutes}
                                                    onChange={(value) => {
                                                        clearErrors('min')
                                                        setSelectedMinutes(value);
                                                        setValue('min', value?.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {errors.min?.message && <ErrorValidationMessage errors={errors.min} name="minutes" />}
                                </div>
                            </div>
                        </div>
                        <div className="model-actions">
                            <SecondaryButton btnName={mode === 'Add' ? 'Cancel' : 'Clear'} btnType='button' className='cancel-btn btn-position' onClick={toggleModel} />
                            <PrimaryButton btnName={mode === 'Add' ? 'Add' : 'Update'} btnType='submit' loader={addResponsibilityLoader} />
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    )
}
export default ManageResponsibilityModal


