import React, { SetStateAction } from 'react'
import { useAppSelector } from '../store/store';

interface tabsProps {
    tabsList: Array<string>,
    activeTab: number | null | undefined,
    setActiveTab: SetStateAction<any>,
    onClick?: Function,
    menu: string,
    submenu?: string
}

const Tabs = (props: tabsProps) => {
    const { tabsList, activeTab, setActiveTab, onClick, menu, submenu } = props;

    const userRecord = useAppSelector((state) => state?.decodeTokenSliceReducer?.data);

    return (
        <ul className="tab-group custom-scroll">
            {tabsList.map((tab, index) => {
                const object = submenu ? userRecord?.action[menu][submenu] : userRecord?.action[menu];

                if (userRecord?.action[menu]?.pageView) {
                    return Object.keys(object)?.length > 0 &&
                        Object.keys(object)?.map((item) => {
                            if ((item?.toLowerCase() === tab?.toLowerCase()?.split(" ").join("")) && object[item]?.pageView) {
                                return <li key={"main-tabs-" + index} className={`tab-item ${activeTab === index ? "active" : ""}`}><span onClick={() => {
                                    setActiveTab(index);
                                    if (onClick) {
                                        onClick();
                                    }
                                }}>{tab}</span></li>
                            }
                        })
                }
            })}
        </ul>
    )
}

export default Tabs