import { Label, Modal } from "reactstrap"
import closeIcon from '../../../assets/images/svgIcons/close_icon.svg';
import SecondaryButton from "../../buttons/SecondaryButton";
import PrimaryButton from "../../buttons/PrimaryButton";
import { useForm } from "react-hook-form"
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { useEffect } from "react";
import { updateorganisationThunk, getorganisationThunk } from '../../../store/asyncThunks/scope/scope';
import { setScopeData } from "../../../store/slices/scope/getorganisation";
import ErrorValidationMessage from "../../ErrorValidationMessage";
import { nameLimit } from "../../../constants/HandleFormLimit";

interface OrganisationScopeModalProps {
    showModal: boolean,
    toggle: any,
    EditData: any,
}

const OrganisationScopeModal = (props: OrganisationScopeModalProps) => {

    const { showModal, toggle, EditData } = props;
    const dispatch = useAppDispatch();
    const updateOrganisationLoader = useAppSelector((state) => state?.updateorganisationReducer?.loading);

    const toggleModel = () => {
        if (EditData?._id) {
            resetFormValues();
        } else {
            toggleClose();
        }
    }

    const toggleClose = () => {
        reset();
        toggle();
    }

    const FormShcema = yup.object().shape({
        label: yup.string().required().trim(),
        scope: yup.string().required().trim(),
    })

    const formValues = {
        label: '',
        scope: '',
    }

    const { handleSubmit, register, formState: { errors }, reset, setValue } = useForm({
        defaultValues: formValues,
        resolver: yupResolver(FormShcema),
    })

    const afterSuccess = (payload: any) => {
        toggle();
        dispatch(setScopeData(payload));
    };

    const onSubmit = (values: any) => {
        const data: any = {}
        data['data'] = {
            ...values,
            id: EditData._id
        };
        data['afterSuccess'] = afterSuccess
        dispatch(updateorganisationThunk(data));
    }

    useEffect(() => {
        if (EditData?._id) {
            resetFormValues();
        }
    }, [EditData, EditData?._id])

    const resetFormValues = () => {
        reset({
            label: EditData?.label || "",
            scope: EditData?.scope || "",
        });
    }

    return (
        <>
            <Modal size="md" isOpen={showModal} centered={true}
                className='manage-setup add-employee-model'
                toggle={toggleModel}
            >
                <div className="modal-header">
                    <h1 className='title'>Update Scope</h1>
                    <span onClick={toggleClose} aria-hidden="true" className='close-icon'><img src={closeIcon} alt="close" /></span>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                    <div className="modal-body">
                        <div className="groups">
                            <div className='group-item'>
                                <Label className='required'>Scope Label</Label>
                                <input type="text" className={`custom-input form-control ${errors.label?.message ? "error-input" : ""}`} {...register("label")} />
                                {errors.label?.message && <ErrorValidationMessage errors={errors.label} name="scope label" />}
                            </div>
                        </div>
                        <div className="groups">
                            <div className="group-item">
                                <Label className='required'>Description</Label>
                                <textarea
                                    className={`custom-input form-control  custom-scroll text-area ${errors.scope?.message ? "error-input" : ""}`} {...register("scope")}
                                ></textarea>
                                {errors.scope?.message && <ErrorValidationMessage errors={errors.scope} name="description" />}
                            </div>
                        </div>
                        <div className="model-actions">
                            <SecondaryButton btnName='Clear' btnType='button' className='cancel-btn btn-position' onClick={toggleModel} />
                            <PrimaryButton btnName='Update' btnType='submit' loader={updateOrganisationLoader} />
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    );
}

export default OrganisationScopeModal