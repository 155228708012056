export const weeklyCounts = 7;
export const halfmonthlyCounts = 4;
export const monthlyCounts = 5;
export const quarterlyCounts = 1;    // This count calcuate years 
export const halfyearlyCounts = 3;
export const yearlyCounts = 4;

// ***************WEEKLY****************
export const weeklyData = () => {

    function getStartOfWeek(date: Date): Date {
        const startOfWeek = new Date(date);
        startOfWeek.setDate(date.getDate() - date.getDay()); // Adjust to Sunday of the current week
        return startOfWeek;
    }

    function getLastWeekRange(date: Date): { startDate: Date, endDate: Date } {
        const lastSunday = new Date(date);
        const lastSaturday = new Date(date);


        // Set lastSunday to the previous Sunday
        lastSunday.setDate(date.getDate() - date.getDay() - 6);
       
        // Set lastSaturday to the previous Saturday
        lastSaturday.setDate(date.getDate() - date.getDay());

        return {
            startDate: lastSunday,
            endDate: lastSaturday
        };
    }

    function getLastSixWeeksRanges(): Array<{ startDate: string, endDate: string }> {
        const today = new Date();
        const lastSixWeeksArray = [];

        // Get current week's range
        const currentWeekStart = getStartOfWeek(today);
        currentWeekStart.setDate(currentWeekStart.getDate() + 1)
        const currentWeekEnd = new Date(currentWeekStart); // Copy start of the week
        currentWeekEnd.setDate(currentWeekEnd.getDate() + 6); // Move to Saturday

        lastSixWeeksArray.push({
            startDate: currentWeekStart.toDateString(),
            endDate: currentWeekEnd.toDateString()
        });

        for (let i = 0; i < weeklyCounts - 1; i++) { // Loop based on weeklyCounts
            const startOfWeek = getStartOfWeek(today);
            const lastWeekRange = getLastWeekRange(startOfWeek);

            lastSixWeeksArray.unshift({
                startDate: lastWeekRange.startDate.toDateString(), // Convert Date object to date string
                endDate: lastWeekRange.endDate.toDateString() // Convert Date object to date string
            });
            today.setDate(today.getDate() - 7); // Move back one week
        }

        return lastSixWeeksArray.reverse();
    }

    const lastSixWeeksData = getLastSixWeeksRanges();
    return lastSixWeeksData;
}

// ****************HALF-MONTH*****************
export const halfMonthData = () => {

    function getStartOfMonth(date: Date) {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }
    
    function getEndOfMonth(date: Date) {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }
    
    function getFirstHalfOfMonth(date: Date) {
        const startOfMonth = getStartOfMonth(date);
        const endOfFirstHalf = new Date(startOfMonth);
        endOfFirstHalf.setDate(15);
        return {
            startDate: startOfMonth.toDateString(),
            endDate: endOfFirstHalf.toDateString()
        };
    }
    
    function getSecondHalfOfMonth(date: Date) {
        const endOfMonth = getEndOfMonth(date);
        const startOfSecondHalf = new Date(endOfMonth);
        startOfSecondHalf.setDate(16);
        return {
            startDate: startOfSecondHalf.toDateString(),
            endDate: endOfMonth.toDateString()
        };
    }
    
    function getCurrentMonthRanges() {
        const today = new Date();
        const currentDate = today.getDate();
    
        if (currentDate <= 15) {
            return [getFirstHalfOfMonth(today)];
        } else {
            return [getSecondHalfOfMonth(today)];
        }
    }
    
    function getLastTwoMonthsRanges() {
        const today = new Date();
        const lastMonths = [];
    
        // Get the ranges for the last  months
        for (let i = 1; i <= halfmonthlyCounts; i++) {
            const currentMonth = new Date(today);
            currentMonth.setMonth(today.getMonth() - i);
            lastMonths.push(getSecondHalfOfMonth(currentMonth));
            lastMonths.push(getFirstHalfOfMonth(currentMonth));
        }
    
        return lastMonths;
    }
    
    const currentMonthRanges = getCurrentMonthRanges();
    const lastMonthsRanges = getLastTwoMonthsRanges();
    
    const allRanges = [...currentMonthRanges, ...lastMonthsRanges];
    
    return allRanges;
    
}

// ***************MONTHLY********************
export const monthData = () => {

    function daysInMonth(month: number, year: number): number {
        return new Date(year, month, 0).getDate();
    }

    function getLastFourMonthsDates() {
        let date = new Date();
        let lastFourMonthsArray = [];

        for (let i = 0; i < monthlyCounts; i++) {
            let currentMonth = date.getMonth() - i;
            let year = date.getFullYear();

            // Adjust for negative month index
            if (currentMonth < 0) {
                currentMonth += 12;
                year -= 1;
            }

            let firstDay = new Date(year, currentMonth, 1);
            let lastDay = new Date(year, currentMonth + 1, 0);

            // Set last day to end of month
            lastDay.setDate(daysInMonth(currentMonth + 1, year));

            lastFourMonthsArray.unshift({
                startDate: firstDay.toDateString(),
                endDate: lastDay.toDateString()
            });
        }

        return lastFourMonthsArray.reverse();
    }

    const lastFourMonthsData = getLastFourMonthsDates();
    return lastFourMonthsData;
}

// ***************QUARTERLY*******************
export const quarterlyData = () => {

    function getQuarterDates() {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth(); 
    
        const quarters = [];
        for (let year = currentYear - quarterlyCounts; year <= currentYear + 2; year++) {
            for (let i = 0; i < 4; i++) {
                const startMonth = i * 3;
                const endMonth = startMonth + 3; 
                const startDate = new Date(year, startMonth, 1);
                const endDate = new Date(year, endMonth, 0);
                const quarter = {
                    startDate: startDate.toDateString(),
                    endDate: endDate.toDateString()
                };
    
    
                if ((year < currentYear && endDate <= today) || (year === currentYear && endMonth <= currentMonth)) {
                    quarters.push(quarter);
                } else if (year === currentYear && startMonth <= currentMonth && currentMonth <= endMonth) {
                    quarters.push(quarter); 
                }
            }
        }   
        return quarters.reverse();
    }
    const quarterdata =  getQuarterDates();
    return quarterdata;
}    

// ************HALF-YEARLY***********
export const halfyearlyData = () => {

    function getDateRangess() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const ranges = [];
        const loopTill = currentYear - halfyearlyCounts;

        for (let year = currentYear; year >= loopTill; year--) {
            const startJan = new Date(year, 0, 1);
            const endJun = new Date(year, 5, 30);
            const startJul = new Date(year, 6, 1);
            const endDec = new Date(year, 11, 31);

            const newEndJun = endJun 
            const newEndDec = endDec 

            if (startJul < currentDate) {
                ranges.push({
                    startDate: startJul.toDateString(),
                    endDate: newEndDec.toDateString()
                });
            }
            if (startJan < currentDate) {
                ranges.push({
                    startDate: startJan.toDateString(),
                    endDate: newEndJun.toDateString()
                });
            }


        }

        return ranges;
    }

    const dateRangess = getDateRangess();
    return dateRangess;
}

// *************YEARLY**************
export const yearlyData = () => {

    function getLastYearlyPeriods(): { startDate: string; endDate: string }[] {
        let date = new Date();
        const currentYear = date.getFullYear();
        let yearlyPeriods = [];

        for (let i = 0; i < yearlyCounts; i++) {
            const year = currentYear - i;

            const startDate = new Date(year, 0, 1);
            const endDate =  new Date(year, 11, 31);

            yearlyPeriods.unshift({
                startDate: startDate.toDateString(),
                endDate: endDate.toDateString()
            });
        }

        return yearlyPeriods.reverse();
    }

    const yearlyPeriodsData = getLastYearlyPeriods();
    return yearlyPeriodsData;
}