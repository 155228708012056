import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import threeDots from '../assets/images/svgIcons/three_dots.svg';
import ManageSalaryModal from "./Models/ManageSalaryModal";
import { useState } from "react";
import { userCardProps } from "./card/UserCard";
import MapResponsibilitiesModal from "./Models/MapResponsibilitiesModal";
import ConfirmationModal from "./Models/ConfirmationModal";
import EditEmployeeModal from "./Models/EditEmployeeModal";
import { changeStatusThunk, getAllEmployeesThunk } from "../store/asyncThunks/manage/mapping";
import { useAppDispatch, useAppSelector } from "../store/store";
import { resetGetEmployeeByIdData } from "../store/slices/manage/mapping/getByEmployeeId";

const UserCardMenuDropdown = (props: userCardProps) => {

  const dispatch = useAppDispatch();

  const [showManageSalaryModal, setShowManageSalaryModal] = useState(false);
  const [showMapResponsibilitiesModal, setShowMapResponsibilitiesModal] = useState(false);
  const [showActivateDeactivateModal, setShowActivateDeactivateModal] = useState(false);
  const EmployeeStatusLoader = useAppSelector((state) => state?.employeeStatusReducer?.loading)

  const toggleManageSalaryModal = () => {
    setShowManageSalaryModal(!showManageSalaryModal);
  }

  const toggleMapResponsibilitiesModal = () => {
    setShowMapResponsibilitiesModal(!showMapResponsibilitiesModal);
  }

  const toggleActivateDeactivateModal = () => {
    setShowActivateDeactivateModal(!showActivateDeactivateModal);
  }

  const [showEditEmployeeModel, setShowEditEmployeeModel] = useState(false);

  const toggleEditEmployeeModel = () => {
    setShowEditEmployeeModel(!showEditEmployeeModel);
  }

  const closeModal = () => {
    setShowActivateDeactivateModal(false)
  }
  const afterSucces = () => {
    const data: any = { payload: { status: "Active" } }
    dispatch(getAllEmployeesThunk(data));
  }
  //Change Status
  const changeActiveStatus = () => {
    let status
    if (props?.status === "INACTIVE") {
      status = "ACTIVE"
    } else {
      status = "INACTIVE"
    }
    const data: any = {}
    data['status'] = { status: status }
    data['employeeId'] = props?.employeeId
    data['closeModal'] = closeModal
    data['afterSuccess'] = afterSucces
    dispatch(changeStatusThunk(data));
  }

  return (
    <>
      <UncontrolledDropdown group>
        <DropdownToggle
          caret
          className="px-2"
          color="primary"
        ><img src={threeDots} alt="icon" /></DropdownToggle>
        <DropdownMenu end>
          <DropdownItem onClick={() => setShowMapResponsibilitiesModal(true)}>Map responsibilities</DropdownItem>
          <DropdownItem onClick={() => setShowActivateDeactivateModal(true)}>{`${props?.status === "ACTIVE" ? 'Deactivate' : 'Activate'}`}</DropdownItem>
          <DropdownItem onClick={() => setShowManageSalaryModal(true)}>Manage salary</DropdownItem>
          <DropdownItem onClick={() => {
            dispatch(resetGetEmployeeByIdData());
            toggleEditEmployeeModel();
          }}>Edit</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>

      {/* manage salary modal */}
      {showManageSalaryModal &&
        <ManageSalaryModal
          secondaryAction={toggleManageSalaryModal}
          showModal={showManageSalaryModal}
          toggle={toggleManageSalaryModal}
          userData={props}
        />}

      {/* map responsibilities modal */}
      {showMapResponsibilitiesModal &&
        <MapResponsibilitiesModal
          showModal={showMapResponsibilitiesModal}
          toggle={toggleMapResponsibilitiesModal}
          userProps={props}
        />
      }
      {/* activate and deactivate modal */}
      <ConfirmationModal
        description={`Are you sure do you want to ${props?.status === "ACTIVE" ? 'deactivate' : 'activate'} this employee`}
        modalTitle="Confirmation"
        primaryAction={changeActiveStatus}
        loader={EmployeeStatusLoader}
        primaryBtnName={`${props?.status === "ACTIVE" ? 'Deactivate' : 'Activate'}`}
        secoundaryBtnName="Cancel"
        showModal={showActivateDeactivateModal}
        toggle={toggleActivateDeactivateModal}
        secondaryAction={() => { toggleActivateDeactivateModal() }}
      />

      {showEditEmployeeModel &&
        <EditEmployeeModal modalTitle=''
          showModal={showEditEmployeeModel}
          toggle={toggleEditEmployeeModel}
          viewEmpId={props?.employeeId}
        />
      }
    </>
  )
}

export default UserCardMenuDropdown