import { SetStateAction, useEffect, useState } from 'react';
import UserCard from '../../../../components/card/UserCard';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { getEmployeesThunk, getAllEmployeesThunk } from '../../../../store/asyncThunks/manage/mapping';
import EmployeesCardSkeleton from '../../../../components/skeleton/EmployeesCardSkeleton';
import NoData from '../../../../components/NoData';
import { updateEmployeeData } from '../../../../store/slices/manage/mapping/getEmployee';
import Pagination from '../../../../components/Table/Pagination/Pagination';
import { Card, Label } from 'reactstrap';
import PageLoader from '../../../../components/PageLoader';

interface UserProps {
    currentPage: number;
    setCurrentPage: SetStateAction<any>;
    searchValue: string
}

const EmployeeSubComponent = (props: UserProps) => {
    const { currentPage, setCurrentPage, searchValue } = props;

    const dispatch = useAppDispatch();

    const getEmployeesData = useAppSelector((state) => state?.getEmployeesReducer?.data?.data?.associates);
    const getallEmployeesData = useAppSelector((state) => state?.getEmployeesReducer?.data?.data?.associates);
    const getEmployeeTotalCount = useAppSelector((state) => state?.getEmployeesReducer?.data?.data?.totalCount);
    const getEmployeesLoading = useAppSelector((state) => state?.getEmployeesReducer?.loading);
    const statusSucess = useAppSelector((state) => state?.employeeStatusReducer?.data);
    const pageSize = process.env.REACT_APP_PAGE_SIZE || 12;

    useEffect(() => {
        const data: any = {}
        data["currentPage"] = currentPage
        data["pageSize"] = pageSize
        data["payload"] = { search: searchValue }
        dispatch(getEmployeesThunk(data));
    }, [currentPage, pageSize, searchValue]);

    useEffect(() => {
        const data: any = { payload: { status: "Active" } }
        dispatch(getAllEmployeesThunk(data));
    },[])

    useEffect(() => {
        if (statusSucess?.status?.toLowerCase() === "success") {
            const data: any = {}
            dispatch(updateEmployeeData(statusSucess?.data))
        }
    }, [statusSucess]);

    return (
        <div>
            <>
                <div className="cards mt-4">
                    {getEmployeesData?.map((empData: any, index: number) => (
                        <div key={index}>
                            <UserCard
                                name={empData?.name}
                                designation={empData?.designation?.label}
                                email={empData?.email}
                                reportTo={empData?.reporteeFKId?.name}
                                empId={empData?.employeeId}
                                status={empData?.status}
                                employeeId={empData?._id}
                                profileImg={empData?.profilePath}
                            />
                        </div>
                    ))}
                </div>
                <div>
                    {getEmployeesLoading && (getEmployeesData?.length === 0 || !getEmployeesData || !getallEmployeesData) &&
                    <Card className='loader-card'>
                            <PageLoader />
                    </Card>
                    }
                </div>
                {!getEmployeesLoading && (getEmployeesData?.length === 0 || !getEmployeesData) && <NoData />}
                {/* pagination container */}
                {getEmployeeTotalCount > 0 &&
                    <div className="pagination-wrapper">
                        <div className="total-count">
                            <Label className={`mb-0`}>Total count: <span>{getEmployeeTotalCount}</span></Label>
                        </div>
                        <div>
                            {getEmployeeTotalCount > 0 ? (
                                <div className="page-count">
                                    <Pagination
                                        className="pagination-bar custom-pagination"
                                        currentPage={currentPage}
                                        totalCount={getEmployeeTotalCount || 0}
                                        pageSize={pageSize}
                                        onPageChange={(page: any) => setCurrentPage(page)}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                }
            </>
        </div>

    )
}

export default EmployeeSubComponent