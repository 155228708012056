import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import login from "./slices/auth/login";
import forgotPassword from "./slices/auth/forgotpassword";
import resetPassword from "./slices/auth/resetPassword";
import addEmployee from "./slices/manage/mapping/addEmployee";
//Manage setups
import manageRole from "./slices/manage/setups/role/manageRole";
import manageDepartment from "./slices/manage/setups/Department/manageDepartment";
import manageDivision from "./slices/manage/setups/Division/manageDivision";
import manageResponsibility from "./slices/manage/setups/Responsibility/manageResponsibility";
import manageClassification from "./slices/manage/setups/Classification/manageClassification";
import manageFrequency from "./slices/manage/setups/Frequency/manageFrequency";
//Chnage Status setups
import changestatusRole from "./slices/manage/setups/role/changeStatusRole";
import changestatusDepartment from "./slices/manage/setups/Department/changeStatusDepartment";
import changeStatusDivision from "./slices/manage/setups/Division/changeStatusDivision";
import changestatusResponsibility from "./slices/manage/setups/Responsibility/changeStatusResponsibility";
import changeStatusClassification from "./slices/manage/setups/Classification/changeStatusClassification";
import changeStatusFrequency from "./slices/manage/setups/Frequency/changeStatusFrequency";
//Get Setups
import getRole from "./slices/manage/setups/role/getRole";
import getDepartment from "./slices/manage/setups/Department/getDepartment";
import getDivision from "./slices/manage/setups/Division/getDivision";
import getResponsibility from "./slices/manage/setups/Responsibility/getResponsibility";
import getClassification from "./slices/manage/setups/Classification/getClassification";
import getFrequency from "./slices/manage/setups/Frequency/getFrequency";
import getEmployee from "./slices/manage/mapping/getEmployee";
import getResponsibilityEmployee from "./slices/manage/mapping/getResponsibilityEmployee";
import myResponsibility from "./slices/overView/getMyResponsibility";
import assignEmp from "./slices/overView/assignEmp";
import myDivResponsibility from "./slices/overView/getDivsionResponsibility";
//Get Scope
import getorganisation from "./slices/scope/getorganisation";
import getDndResponsibilities from "./slices/manage/mapping/responsibilities/getDndResponsibilities";
import getByEmployee from "./slices/manage/mapping/getByEmployeeId";
import updateEmployee from "./slices/manage/mapping/updateEmployee";
import changeEmployeeStatus from "./slices/manage/mapping/changeStatus";
import getManageSalary from "./slices/manage/mapping/getManageSalary";
import addManageSalary from "./slices/manage/mapping/addManageSalary";
import mapResponsibility from "./slices/manage/mapping/mapResponsibility";
import statusResponsibility from "./slices/manage/mapping/statusResponsibility";
import getMappingReporties from "./slices/manage/mapping/getReporteesMappingById";


import updateorganisation from "./slices/scope/updateorganisation";
import getdivision from "./slices/scope/getdivision";
import getdepartment from "./slices/scope/getdepartment";
import reporteesList from "./slices/overView/reporteesSlice";
import reporteesById from "./slices/overView/getReporteesByIdSlice";
import addDndResponsibilities from "./slices/manage/mapping/responsibilities/addDndResponsibilities";
import decodeTokenSlice from "./slices/auth/decodeToken";
import getUserDndResponsibilities from "./slices/manage/mapping/responsibilities/getUserDndResponsibilities";
import assignDndByEmpId from "./slices/manage/mapping/responsibilities/assignDndByEmpId";
import changePassword from "./slices/myProfile/changePassword";
import updateProfileImg from "./slices/myProfile/updateProfileImg";
import getProfileImg from "./slices/myProfile/getProfileImg";
import reporteesdata from "./slices/overView/reporteesdata";

const store = configureStore({
  reducer: {
    loginReducer: login,
    addEmployeeReducer: addEmployee,
    manageRoleReducer: manageRole,
    manageClassificationReducer: manageClassification,
    manageDepartmentReducer: manageDepartment,
    manageResponsibilityReducer: manageResponsibility,
    manageDivisionReducer: manageDivision,
    manageFrequencyReducer: manageFrequency,
    chnageStatusRoleReducer: changestatusRole,
    changestatusDepartmentReducer: changestatusDepartment,
    changeStatusDivisionReducer: changeStatusDivision,
    changestatusClassificationReducer: changeStatusClassification,
    changeStatusFrequencyReducer: changeStatusFrequency,
    changeStatusResponsibilityReducer: changestatusResponsibility,
    getRoleReducer: getRole,
    getDepartmentReducer: getDepartment,
    getDivisionReducer: getDivision,
    getResponsibilityReducer: getResponsibility,
    getClassificationReducer: getClassification,
    getFrequencyReducer: getFrequency,
    forgotPasswordReducer: forgotPassword,
    resetPasswordReducer: resetPassword,
    getEmployeesReducer: getEmployee,
    getResponsibilityEmployeesReducer: getResponsibilityEmployee,
    getMyResponsibilityReducer: myResponsibility,
    getDivisionResponsibilityReducer: myDivResponsibility,
    getorganisationReducer: getorganisation,
    updateorganisationReducer: updateorganisation,
    getDndResponsibilitiesReducer: getDndResponsibilities,
    getByEmployeeIdReducer: getByEmployee,
    updateEmployeeIdReducer: updateEmployee,
    employeeStatusReducer: changeEmployeeStatus,
    getManageSalaryReducer: getManageSalary,
    addManageSalaryReducer: addManageSalary,
    mapResponsibilityReducer: mapResponsibility,
    getdivisionReducer: getdivision,
    getdepartmentReducer: getdepartment,
    getReporteesList: reporteesList,
    getReporteesById: reporteesById,
    reporteesDataReducer: reporteesdata,
    addDndResponsibilitiesReducer: addDndResponsibilities,
    decodeTokenSliceReducer: decodeTokenSlice,
    getUserDndResponsibilities: getUserDndResponsibilities,
    assignDndByEmpId: assignDndByEmpId,
    assignEmp: assignEmp,
    changePasswordReducer: changePassword,
    updateProfileImgReducer: updateProfileImg,
    getProfileImgReducer: getProfileImg,
    statusResponsibilityReducer:statusResponsibility,
    getMappingReportiesReducer:getMappingReporties,
  },
});
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
