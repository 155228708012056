import { createSlice } from "@reduxjs/toolkit";
import { defaultState } from "../../../interfaces/auth";
import { getByEmployeeIdThunk } from "../../../asyncThunks/manage/mapping";

const initialState: defaultState = {
    error: null,
    data: null,
    loading: false,
};

export const getByEmployee = createSlice({
    name: "getByEmployee",
    initialState,
    reducers: {
        resetGetEmployeeByIdData: (state) => {
            state.data = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getByEmployeeIdThunk.pending, (state, action) => {
            state.error = null;
            state.data = null;
            state.loading = true;
        });
        builder.addCase(getByEmployeeIdThunk.fulfilled, (state, action) => {
            if (action?.payload?.status?.toLowerCase() === "success") {
                state.error = null;
                state.data = action.payload;
            } else {
                state.error = action?.payload;
                state.data = null;
            }
            state.loading = false;
        });
        builder.addCase(getByEmployeeIdThunk.rejected, (state, action) => {
            state.error = action.payload;
            state.data = null;
            state.loading = false;
        });
    },
});

export const { resetGetEmployeeByIdData} = getByEmployee.actions;


export default getByEmployee.reducer;
