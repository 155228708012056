import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getMyResponsiblity,
  getDivisionResponsiblity,
  assignEmp,
} from "../../../helpers/backend_helper";
import toast from "react-hot-toast";

interface assignEmpProps {
  payload?: object | any;
  mappingSuccess: (data:any) => void;
}
interface divResProps {
  department?: string;
  division?: string;
}
interface getDivresProps {
  data?: any;
  currentPage?: number;
  pageSize?: number;
}

export const getMyResponsibilityThunk = createAsyncThunk(
  "getResponsibilityThunk",
  async (data: divResProps) => {
    try {
      const response = await getMyResponsiblity(data);
      return await response;
    } catch (error: any) {
      return await error?.response?.data;
    }
  }
);

export const getdivResponsibilityThunk = createAsyncThunk(
  "getDivisionResponsibilityThunk",
  async (props: getDivresProps) => {
    try {
      const { data, currentPage = 0, pageSize = 0 } = props;

      const response = await getDivisionResponsiblity(data, currentPage, pageSize);
      return await response;
    } catch (error: any) {
      return await error?.response?.data;
    }
  }
);

//assign emp
export const assignEmpThunk = createAsyncThunk(
  "assignEmp",
  async (data: assignEmpProps) => {
    try {
      const { payload, mappingSuccess } = data;
      const response = await assignEmp(payload);
      if (response.status?.toLowerCase() === "success") {
        if (mappingSuccess) mappingSuccess(response.data);
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
      return await response;
    } catch (error: any) {
      return await error?.response?.data;
    }
  }
);
