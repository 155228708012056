import { useState, useEffect } from 'react';
import { getorganisationThunk } from '../../../store/asyncThunks/scope/scope';
import OrganisationScopeModel from '../../../components/Models/ScopeModal/OrganisationScopeModal';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import NoData from '../../../components/NoData';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
interface organisationProps {
  print?: any
}
const Organisation = (props: organisationProps) => {
  const { print } = props

  const [EditData, setEditData] = useState(null);
  const [showModal, setshowModal] = useState(false);

  const dispatch = useAppDispatch();
  const organisationState = useAppSelector((state) => state.getorganisationReducer?.data?.data);
  const organisationStateLoading = useAppSelector((state) => state.getorganisationReducer?.loading);

  useEffect(() => {
    dispatch(getorganisationThunk());
  }, []);

  const handleEdit = (organisationState: any) => {
    setEditData(organisationState);
    setshowModal(true);
  };

  const toggleModal = () => {
    setshowModal(!showModal);
  };

  return (
    <>
      <div ref={print}>
        <section className="organiscope">
          <div className="card">
            <div className='title d-flex justify-content-between align-items-center flex-wrap'>
              {organisationStateLoading === true &&
                <div className="page-loader-overlay">
                  <div className='page-loader'>  </div>
                </div>
              }
              {
                organisationState?.label &&
                <>
                  <h5>{organisationState?.label}</h5>
                  <div className='model-actions '>
                  <PrimaryButton btnName={'Edit'} btnType={'button'} className="edit-btn" onClick={() => handleEdit(organisationState)}/>
                  </div>
                </>
              }
            </div>
            <div className="alert-box">
              <div className='scope-description'>
                {organisationState ?
                  (organisationState?.scope) :
                  (<>
                    {
                      organisationStateLoading === false &&
                      <NoData />
                    }
                  </>
                  )
                }
              </div>
            </div>
          </div>
          {EditData && (
            <OrganisationScopeModel
              showModal={showModal}
              toggle={toggleModal}
              EditData={EditData}
            />
          )}
        </section >
      </div>
    </>
  );
}

export default Organisation;
