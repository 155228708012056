import { createSlice } from "@reduxjs/toolkit";
import { defaultState } from "../../interfaces/auth";
import { getdepartmentThunk } from "../../asyncThunks/scope/scope";


const initialState: defaultState = {
    error: null,
    data: [],
    loading: false,
};

export const getdepartment = createSlice({
    name: "getdepartment",
    initialState,
    reducers: {
        setScopeData: (state, action) => {
            state.data = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getdepartmentThunk.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getdepartmentThunk.fulfilled, (state, action) => {
            if (action?.payload?.status?.toLowerCase() === "success") {
                state.error = null;
                state.data = action.payload;
            } else {
                state.error = action?.payload;
                state.data = [];
            }
            state.loading = false;
        });
        builder.addCase(getdepartmentThunk.rejected, (state, action) => {
            state.error = action.payload;
            state.data = [];
            state.loading = false;
        });
    },

})

export const { setScopeData } = getdepartment.actions;

export default getdepartment.reducer;