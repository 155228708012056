import { Label, Modal } from "reactstrap"
import closeIcon from '../../../assets/images/svgIcons/close_icon.svg';
import SecondaryButton from "../../buttons/SecondaryButton";
import PrimaryButton from "../../buttons/PrimaryButton";
import { useForm } from "react-hook-form"
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { manageDepartmentThunk, getDepartmentThunk } from "../../../store/asyncThunks/manage/setups";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { useEffect } from "react";
import ErrorValidationMessage from "../../ErrorValidationMessage";
import { descriptionLimit } from "../../../constants/HandleFormLimit";
import { updateDepartmentData } from "../../../store/slices/manage/setups/Department/getDepartment";

interface ManageDepartmentModalProps {
    showModal: boolean,
    toggle: any,
    mode: 'Add' | 'Edit',
    EditData: any,
    handleModalPagination?: Function,
}

const ManageDepartmentModal = (props: ManageDepartmentModalProps) => {
    const { showModal, toggle, mode, EditData, handleModalPagination } = props;

    const dispatch = useAppDispatch();

    const addDepartmentLoader = useAppSelector((state) => state?.manageDepartmentReducer?.loading);

    const FormShcema = yup.object().shape({
        label: yup.string().required().trim(),
        scope: yup.string().required().test("maxLength", '-', val => {
            if (!val) return false;
            return val.length <= descriptionLimit;
        }).trim(),
    })

    const formValues = {
        label: '',
        scope: '',
    }

    const { handleSubmit, register, formState: { errors }, reset, setValue } = useForm({
        defaultValues: formValues,
        resolver: yupResolver(FormShcema),
    })

    const afterSuccess = (updatedDepartment:any) => {
        toggleClose();
        handleModalPagination && handleModalPagination(); 
    };

    const toggleModel = () => {
        if (EditData?._id) {
            resetFormValues()
        } else {
            toggleClose()
        }
    }

    const toggleClose = () => {
        reset();
        toggle();
    }

    const onSubmit = (values: any) => {
        const data: any = {}
        data['data'] = values;
        data['afterSuccess'] = afterSuccess;
        if (mode === 'Add') {
            dispatch(manageDepartmentThunk(data));
        } else if (mode === 'Edit') {
            data['id'] = EditData?._id;
            dispatch(manageDepartmentThunk(data));
        }
    }

    useEffect(() => {
        if (EditData?._id) {
            resetFormValues();
        }
    }, [EditData, EditData?._id])

    const resetFormValues = () => {
        reset({
            label: EditData?.label || "",
            scope: EditData?.scope || "",
        });
    }

    return (
        <>
            <Modal size="md" isOpen={showModal} centered={true}
                className='manage-setup add-employee-model'
                toggle={toggleModel}
            >
                <div className="modal-header">
                    <h1 className='title'>{mode === 'Add' ? 'Add new department' : 'Update department'}</h1>
                    <span onClick={toggleClose} aria-hidden="true" className='close-icon'><img src={closeIcon} alt="close" /></span>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                    <div className="modal-body">
                        <div className="groups">
                            <div className='group-item'>
                                <Label className='required'>Department name</Label>
                                <input type="text" className={`custom-input form-control ${errors.label?.message ? "error-input" : ""}`} {...register("label")} />
                                {errors.label?.message && <ErrorValidationMessage errors={errors.label} name="department name" />}
                            </div>
                        </div>
                        <div className="groups">
                            <div className="group-item">
                                <Label className='required'>Description</Label>
                                <textarea
                                    className={`custom-input form-control  custom-scroll text-area ${errors.scope?.message ? "error-input" : ""}`} {...register("scope")}
                                ></textarea>
                                {errors.scope?.message && <ErrorValidationMessage errors={errors.scope} name="description" sizeMax={descriptionLimit} />}
                            </div>
                        </div>
                        <div className="model-actions">
                            <SecondaryButton btnName={mode === 'Add' ? 'Cancel' : 'Clear'} btnType='button' className='cancel-btn btn-position' onClick={toggleModel} />
                            <PrimaryButton btnName={mode === 'Add' ? 'Add' : 'Update'} btnType='submit' loader={addDepartmentLoader} />
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    )
}
export default ManageDepartmentModal