import { SetStateAction } from 'react'

interface outsideClickProps {
    wrapperRef: any,
    setisOpen: SetStateAction<any>
}

const OutsideClick = (props: outsideClickProps) => {
    const { wrapperRef, setisOpen } = props;

    function handleClickOutside(event: MouseEvent) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setisOpen(false);
        }
    }
    // Bind the event listener
    document.addEventListener("mousedown", (e) => handleClickOutside(e));

    return () => {
        document.addEventListener("mousedown", (e) => handleClickOutside(e));
    }
}

export default OutsideClick