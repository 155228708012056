import { createSlice } from "@reduxjs/toolkit";
import { defaultState } from "../../interfaces/auth";
import { getReporteesById } from "../../asyncThunks/overView/reportees";

const initialState: defaultState = {
  error: null,
  data: null,
  loading: false,
};

export const reporteesById = createSlice({
  name: "overView/reporteesById",
  initialState,
  reducers: {
    clearReporteesById: (state) => {
      state.error = null;
      state.data = null;
      state.loading = false;
    },
    replaceReporteesById: (state, action) => {
      if (state.data?.data?.responsibility) {
        const index = state?.data?.data?.responsibility?.findIndex((item: any) => {
          return item?.mapResId === action.payload?.mapResId
        })
        state.data.data.responsibility[index] = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getReporteesById?.pending, (state, action) => {
      state.error = null;
      state.data = null;
      state.loading = true;
    });
    builder.addCase(getReporteesById?.fulfilled, (state, action) => {
      if (action?.payload?.status?.toLowerCase() === "success") {
        state.error = null;
        state.data = action.payload;
      } else {
        state.error = action?.payload;
        state.data = null;
      }
      state.loading = false;
    });
    builder.addCase(getReporteesById?.rejected, (state, action) => {
      state.error = action.payload;
      state.data = null;
      state.loading = false;
    });
  },
});

export const { clearReporteesById, replaceReporteesById } = reporteesById.actions;

export default reporteesById.reducer;
