interface ErrorValidationMessageProps {
    errors: any,
    name: string,
    sizeMax?: number | string,
    sizeMin?: number | string,
    matchField?: string,
}

const ErrorValidationMessage = ({ errors, name = '', sizeMax, sizeMin, matchField }: ErrorValidationMessageProps) => {

    const message = () => {
        if (errors?.type === 'required') {
            return `Enter ${name}`;
        } else if (errors?.type === 'pwd_length') {
            return `Password should reach atleast medium strength`;
        } else if (errors?.type === 'oneOf') {
            if (matchField) {
                return `${matchField.charAt(0).toUpperCase() + matchField.slice(1)} and ${name?.toLowerCase()} must match`;
            }
        } else if (errors?.type === 'email') {
            return `Enter valid ${name?.toLowerCase()} address`;
        } else if (errors?.type === 'minLength') {
            return `${name.charAt(0).toUpperCase() + name.slice(1)} must be more than ${sizeMin} characters.`;
        } else if (errors?.type === 'maxLength') {
            return `${name.charAt(0).toUpperCase() + name.slice(1)} must be less than ${sizeMax} characters.`;
        } else if (errors?.type === 'min') {
            return `${name} must be more than ${sizeMin} `;
        } else if (errors?.type === 'max') {
            return `${name} must be less than ${sizeMax} `;
        } else if (errors?.type === 'select') {
            return `Select ${name}`;
        } else if (errors?.type === 'choose') {
            return `Choose ${name}`;
        } else if (errors?.type === 'custom') {
            return errors.message;
        } else if (errors?.type === 'typeError') {
            return errors.message;
        }
    }

    return <span className={`invalid-feedback mt-2 d-block`} >{message()}</span>
};

export default ErrorValidationMessage;