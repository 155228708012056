import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import closeIcon from '../../assets/images/svgIcons/close_icon.svg';
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';
import searchIcon from '../../assets/images/svgIcons/search-icon.svg'
import NoData from '../NoData';
interface AssignEmployee {
    showModal: boolean,
    toggle: () => void,
    modalTitle: string;
    data: any[],
    onSelect?: (data: string) => void
    onSubmit?: () => void
    selected?: any[] | object | any;
    loader?: boolean | Boolean
}

const AssignEmployees = (props: AssignEmployee) => {
    const { showModal, toggle, modalTitle, data, onSelect, onSubmit, selected, loader } = props;
    const [showData, setshowData] = useState(data)
    const [search, setsearch] = useState("")
    const loaclSearch = (e: any) => {
        let value: any = e.target.value
        setsearch(value)
        if (value) {
            const filterData = data?.filter((item: any) =>
                item?.name?.trim().toLocaleLowerCase().includes(value.trim().toLocaleLowerCase()) ||
                item?.employeeId?.trim().toLocaleLowerCase().includes(value.trim().toLocaleLowerCase()) ||
                item?.designation?.label?.trim().toLocaleLowerCase().includes(value.trim().toLocaleLowerCase())
            );
            setshowData(filterData)
        }
        else setshowData(data)
    }
    const handlePopup = () => {
        toggle()
        setsearch("")
        setshowData(data)
    }
    const handelSubmit = () => {
        if (onSubmit) onSubmit()
        setsearch("")
        setshowData(data)
    }

    useEffect(() => {
        setshowData(data);
    }, [data]);
    return (
        <div>
            <Modal size="lg" isOpen={showModal} centered={true}
                className='add-employee-model assign-employee-modal'
                toggle={handlePopup}
            >
                <div className="modal-header">
                    <h1 className='title'>{modalTitle}</h1>
                    <span onClick={handlePopup} aria-hidden="true" className='close-icon'><img src={closeIcon} alt="close" /></span>
                </div>
                <ModalBody>
                    <div className='body-content'>
                        <div className='emp-search'>
                            <div className='search-icon'>
                                <img src={searchIcon} alt='searchIcon' />
                            </div>
                            <input type='text' className='form-control' placeholder='Search' value={search} onChange={loaclSearch} />
                        </div>
                        <div className='table-wrapper'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Designation</th>
                                        <th>Employee Code</th>
                                    </tr>
                                </thead>
                                <tbody className='custom-scroll'>
                                    {(showData && showData?.length > 0) ? showData?.map((item: any) => {
                                        const condition = selected.includes(item._id)
                                        return <tr key={item?._id}>
                                            <td>{item?.name}</td>
                                            <td>{item?.designation.label}</td>
                                            <td>{item?.employeeId}
                                                <div className="form-check">
                                                    {condition === true ?
                                                        <input className="form-check-input" checked type="checkbox" value="" id={item?._id} onChange={() => onSelect && onSelect(item?._id)} />
                                                        :
                                                        <input className="form-check-input" type="checkbox" value="" id={item?._id} onChange={() => onSelect && onSelect(item?._id)} />
                                                    }
                                                    <label className="form-check-label" htmlFor={item?._id}>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    }) : <NoData />}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ModalBody>
                <div className='d-flex gap-3 pt-3 justify-content-end'>
                    <SecondaryButton btnName='Cancel' btnType='button' className='cancel-btn' onClick={handlePopup} />
                    <PrimaryButton btnName={modalTitle === "Assign Employees" ? "Assign" : "Remove"} onClick={handelSubmit} loader={loader} />
                </div>
            </Modal>
        </div>
    );
};

export default AssignEmployees;