
import { Label, Modal } from 'reactstrap';
import closeIcon from '../../assets/images/svgIcons/close_icon.svg';
import { useEffect, useState } from 'react';
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';
import Datepicker from '../Datepicker';
import { useForm } from "react-hook-form"
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import DropDown from '../DropDown';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { getByEmployeeIdThunk, getEmployeesThunk, updateEmployeeThunk } from '../../store/asyncThunks/manage/mapping';
import { getRoleThunk } from '../../store/asyncThunks/manage/setups';
import sampleImg from '../../assets/images/svgImages/avatar.svg';
import editIcon from '../../assets/images/svgIcons/edit-white.svg';
import ErrorValidationMessage from '../ErrorValidationMessage';
import PageLoader from '../PageLoader';
import { nameLimit } from '../../constants/HandleFormLimit';
import { phoneNumberLimit } from '../../constants/HandleFormLimit';
import { updateEmployeeData } from '../../store/slices/manage/mapping/getEmployee';

interface EditEmployeeProps {
    showModal: boolean,
    toggle: any,
    modalTitle: string,
    viewEmpId: string
}
interface Reporting {
    label: string;
    value: string;
}
const EditEmployeeModal = (props: EditEmployeeProps) => {
    const { showModal, toggle, modalTitle, viewEmpId } = props;

    const dispatch = useAppDispatch();

    const maxFileSize = 250;
    const defaultProfile = { path: "", name: "" };

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [profile, setProfile] = useState(defaultProfile);
    const [designationValue, setDesignationValue] = useState<any>();
    const [reporting, setReporting] = useState<Array<Reporting>>([{ label: 'Select reporting', value: "" }])
    const [reportingValue, setReportingValue] = useState<Reporting>({ label: 'Select reporting', value: "" })
    const getEmployeesData = useAppSelector((state: any) => state?.getEmployeesReducer?.data?.data?.associates);
    const getallEmployeesData = useAppSelector((state) => state?.getResponsibilityEmployeesReducer?.data?.data?.associates);
    const getRoleList = useAppSelector((state: any) => state?.getRoleReducer?.data?.role);
    const getRoleListLoader = useAppSelector((state: any) => state?.getRoleReducer?.loading);
    const getByEmpData = useAppSelector((state: any) => state?.getByEmployeeIdReducer?.data?.data);
    const getByEmpDataLoader = useAppSelector((state: any) => state?.getByEmployeeIdReducer?.loading);
    const updateEmployeeLoader = useAppSelector((state: any) => state?.updateEmployeeIdReducer?.loading);

    const addEmployeeFormSchema = yup.object().shape({
        name: yup.string().required().test("maxLength", '-', val => {
            if (!val) return false;
            return val.length < nameLimit;
        }).trim(),
        employeeId: yup.string().required().trim(),
        email: yup.string().email().required().trim(),
        phoneNumber: yup.string().required().required().test("maxLength", '-', val => {
            if (!val) return false;
            return val.length < phoneNumberLimit;
        }).trim(),
        designation: yup.string().test("select", '-', val => {
            if (!val) return false;
            else return true
        }),
        dob: yup.string().required().trim(),
        // profile: yup.mixed().required().test('custom', `File size should be less than ${maxFileSize}kb`, (value: any) => {
        //     if (value && (value.size / 1024) > maxFileSize) {
        //         return false;
        //     } else {
        //         return true;
        //     }
        // }),
        reporteeFKId: yup.string().test("select", '-', val => {
            if (!val) return false;
            else return true
        }),
    });

    const formValues = {
        name: '',
        employeeId: '',
        email: '',
        phoneNumber: '',
        designation: '',
        // profile: {},
        dob: '',
        reporteeFKId: '',
    }

    const { handleSubmit, register, reset, formState: { errors }, setValue, clearErrors } = useForm({
        defaultValues: formValues,
        resolver: yupResolver(addEmployeeFormSchema),
    })

    const datasubmited = async (data: any) => {
        dispatch(updateEmployeeData(data))
    }

    const onSubmit = (values: any) => {
        const data: any = {}
        data['data'] = values;
        data['afterSuccess'] = toggleModel;
        data['datasubmited'] = datasubmited;
        data['viewEmpId'] = viewEmpId;
        dispatch(updateEmployeeThunk(data));

    }

    const resetFormValues = () => {
        reset({
            name: getByEmpData?.name || "",
            employeeId: getByEmpData?.employeeId || "",
            email: getByEmpData?.email || "",
            dob: getByEmpData?.dob || "",
            phoneNumber: getByEmpData?.phoneNumber || "",
            designation: getByEmpData?.designation?._id || "",
            reporteeFKId: getByEmpData?.reporteeFKId?._id || "",
        });

        //Date formate change
        const [year, month, day] = getByEmpData?.dob?.split("/").map(Number);
        const date = new Date(day, month - 1, year);
        const formattedDate = date;
        setStartDate(formattedDate);

        setReportingValue({
            label: getByEmpData?.reporteeFKId?.name,
            value: getByEmpData?.reporteeFKId?._id
        });
        setDesignationValue(
            {
                label: getByEmpData?.designation?.label,
                value: getByEmpData?.designation?._id
            }
        );
    }

    useEffect(() => {
        if (getByEmpData) {
            resetFormValues();
        }
    }, [getByEmpData])
    // const handleFileChange = (event: ChangeEvent<any>) => {
    //     if (event) {
    //         const file = event.target.files[0];
    //         setValue('profile', file);
    //         if (file) {
    //             setProfile({ path: URL.createObjectURL(file), name: file.name });
    //         }
    //     }
    // };

    const handleDateChange = (date: Date) => {
        setStartDate(date)
        setValue('dob', `${date}`)
        clearErrors('dob')
    }

    const toggleModel = () => {
        toggle();
        setProfile(defaultProfile);
    }

    const handleDesignationChange = (value: any) => {
        setDesignationValue(value);
        setValue('designation', value?._id);
        clearErrors('designation');
    }

    //Reporting DropDown
    const reportingDrop = (value: any) => {
        setReportingValue(value);
        setValue('reporteeFKId', value?._id);
        clearErrors('reporteeFKId');
    }

    useEffect(() => {
        if (viewEmpId) {
            const data: any = {}
            data['id'] = viewEmpId
            dispatch(getByEmployeeIdThunk(data?.id));
        }
    }, []);
 
    useEffect(() => {
        if (viewEmpId) {
            const reportToOptions =  getallEmployeesData?.length > 0 &&  getallEmployeesData?.map((item: any) => {
                return { label: item?.name, value: item?._id, ...item }
            });
            setReporting(reportToOptions)
        }
    }, [getallEmployeesData]);

    //Designation
    const filterRole = getRoleList?.filter((item: any) => {
        return item.status === "ACTIVE";
    });

    useEffect(() => {
        const data: any = {}
        dispatch(getRoleThunk(data));
    }, []);

    return (
        <Modal size="lg" isOpen={showModal} centered={true}
            className='add-employee-model'
            toggle={toggleModel}
        >
            {!getByEmpData && <PageLoader />}
            <div className="modal-header align-items-start">
                <div className='d-flex align-items-center'>
                    <div className='avatar-image'>
                        {/* <div className='img-overlay'>
                            <img src={editIcon} alt='editIcon' />
                        </div> */}
                        {/* <span>M</span> */}
                        <div className='pro-pic'>
                            {/* <img src={getByEmpData?.profilePath || sampleImg} alt='sampleImg' /> */}
                            <p className='profile-circle-md'>{getByEmpData?.name?.charAt(0).toUpperCase()}</p>
                        </div>
                    </div>
                    <div className='profile-info'>
                        <h2 className='text-capitalize'>{getByEmpData?.name}</h2>
                        <p className="designation mt-2 badge-primary text-capitalize">{getByEmpData?.designation?.label}</p>
                    </div>
                </div>
                {/* <h1 className='title'>{modalTitle}</h1> */}
                <span onClick={toggleModel} aria-hidden="true" className='close-icon'><img src={closeIcon} alt="close" /></span>
            </div>
            <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-body">
                    <div className="groups">
                        <div className='group-item'>
                            <Label className='required'>Name</Label>
                            <input type="text" className={`custom-input form-control ${errors.name?.message ? "error-input" : ""}`} {...register("name")} />
                            {errors.name?.message && <ErrorValidationMessage errors={errors.name} name="name" sizeMax={nameLimit} />}
                        </div>
                        <div className='group-item'>
                            <Label className='required'>Employee ID</Label>
                            <input type="text" className={`custom-input form-control ${errors.employeeId?.message ? "error-input" : ""}`} {...register("employeeId")} />
                            {errors.employeeId?.message && <ErrorValidationMessage errors={errors.employeeId} name="employee Id" />}
                        </div>
                    </div>
                    <div className="groups">
                        <div className='group-item'>
                            <Label className='required'>Email</Label>
                            <input type="text" className={`custom-input form-control ${errors.email?.message ? "error-input" : ""}`} {...register("email")} />
                            {errors.email?.message && <ErrorValidationMessage errors={errors.email} name="email address" />}
                        </div>
                        <div className='group-item'>
                            <Label className='required'>Phone number</Label>
                            <input type="number" className={`custom-input form-control ${errors.phoneNumber?.message ? "error-input" : ""}`} {...register("phoneNumber")} />
                            {errors.phoneNumber?.message && <ErrorValidationMessage errors={errors.phoneNumber} name="phone number" sizeMax={phoneNumberLimit} />}
                        </div>
                    </div>
                    <div className="groups">
                        <div className='group-item'>
                            <Label className='required'>Designation</Label>
                            <DropDown loading={getRoleListLoader} classNamePrefix={errors.designation?.message ? "error-input " : ""} options={filterRole} value={designationValue} onChange={handleDesignationChange} />
                            {errors.designation?.message && <ErrorValidationMessage errors={errors.designation} name="designation" />}
                        </div>
                        <div className='group-item'>
                            <Label className='required'>DOB</Label>
                            <Datepicker selected={startDate} onChange={handleDateChange} className={errors.dob?.message ? "error-input" : ""} />
                            {errors.dob?.message && <ErrorValidationMessage errors={errors.dob} name="date of birth" />}
                        </div>
                    </div>
                    <div className="groups">
                        <div className='group-item'>
                            <Label className='required'>Reporting to</Label>
                            <DropDown loading={getByEmpDataLoader} classNamePrefix={errors.reporteeFKId?.message ? "error-input " : ""} options={reporting} value={reportingValue} onChange={reportingDrop} />
                            {errors.reporteeFKId?.message && <ErrorValidationMessage errors={errors.reporteeFKId} name="reportee" />}
                        </div>
                    </div>

                    <div className="model-actions">
                        <SecondaryButton btnName='Clear' btnType='button' className='cancel-btn btn-position' onClick={() => {
                            reset();
                            resetFormValues();
                        }} />
                        <PrimaryButton btnName='Update' btnType='submit' loader={updateEmployeeLoader} />
                    </div>
                </div>
            </form>
        </Modal >
    )
}

export default EditEmployeeModal