import { createSlice } from "@reduxjs/toolkit";
import { defaultState } from "../../../interfaces/auth";
import { getEmployeesThunk } from "../../../asyncThunks/manage/mapping";

const initialState: defaultState = {
    error: null,
    data: null,
    loading: false,
};

export const getEmployee = createSlice({
    name: "getEmployee",
    initialState,
    reducers: {
        updateEmployeeData: (state, action) => {
            const updateEmployees = action.payload;
            if (state.data && state.data.data && state.data.data.associates) {
                const index = state.data.data.associates.findIndex((associates: { _id: any; }) => associates?._id === updateEmployees?._id);
                if (index !== -1) {
                    state.data.data.associates[index] = updateEmployees;
                }
            } 
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getEmployeesThunk.pending, (state, action) => {
            state.error = null;
            // state.data = null;
            state.loading = true;
        });
        builder.addCase(getEmployeesThunk.fulfilled, (state, action) => {
            if (action?.payload?.status?.toLowerCase() === "success") {
                state.error = null;
                state.data = action.payload;
            } else {
                state.error = action?.payload;
                state.data = null;
            }
            state.loading = false;
        });
        builder.addCase(getEmployeesThunk.rejected, (state, action) => {
            state.error = action.payload;
            state.data = null;
            state.loading = false;
        });
    },
});

export const { updateEmployeeData } = getEmployee.actions;

export default getEmployee.reducer;
