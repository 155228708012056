import { SetStateAction, useEffect, useMemo, useState } from 'react'
import InputSearch from '../../../components/InputSearch';
import arrow from '../../../assets/images/svgIcons/accordian_arrow.svg';
import avatar from '../../../assets/images/svgImages/avatar.svg';
import filterIcon from '../../../assets/images/svgIcons/filter.svg';
import DropDown from '../../../components/DropDown';
import FilterDropDown from '../../../components/FilterDropDown';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import InfoCard from '../../../components/card/InfoCard';
import { getDepartmentThunk, getDivisionThunk } from '../../../store/asyncThunks/manage/setups';
import { Card, Label } from 'reactstrap';
import TableWithCardLoader from '../../../components/TableWithCardLoader';

interface DivisionResponsibility {
    handleChange?: (data: any) => void;
    divisionList?: any;
    departmentList?: any;
    divsonId?: string;
    deptId?: string;
    currentSubTab?: string;
    setCurrentSubTab?: (data: any) => void;
    showData?: any;
    handleSearch?: (data: any) => void;
    searchVal?: string | any;
    popup?: SetStateAction<any>;
    selectDivison?: any;
    alreadySelected?: any;
    setSortBy?: SetStateAction<any>;
    sortBy?: object;
    print?: any;
    expandTableRow?: Boolean;
    filterValue?: any[];
    setFilterValue?: SetStateAction<any>;
    lists?: any;
    currentPage: number;
    setCurrentPage: SetStateAction<any>;
    pageSize:string | number;
    restInput: boolean;
}
export default function DivisionResponsibility(props: DivisionResponsibility) {

    const {
        currentSubTab,
        deptId,
        divsonId,
        handleChange,
        setCurrentSubTab,
        showData,
        searchVal,
        handleSearch,
        popup,
        selectDivison,
        alreadySelected, setSortBy, sortBy, print, expandTableRow,
        filterValue,
        setFilterValue,
        lists,
        currentPage,
        setCurrentPage,
        pageSize,
        restInput
    } = props

    const dispatch = useAppDispatch();

    const getdivsionResLoading = useAppSelector((state) => state?.getDivisionResponsibilityReducer?.loading);
    const getDivisionList = useAppSelector((state) => state?.getDivisionReducer?.data?.divisions);
    const getDepartmentList = useAppSelector((state) => state?.getDepartmentReducer?.data?.departments);
    const divisionList = getDivisionList?.filter((item: any) => item.status.toLocaleLowerCase() === "active");
    const departmentList = getDepartmentList?.filter((item: any) => item.status.toLocaleLowerCase() === "active");
    const getDivisionLoading = useAppSelector((state) => state?.getDivisionReducer?.loading);
    const getDepartmentLoading = useAppSelector((state) => state?.getDepartmentReducer?.loading);
    const totalDivResponsibilityCount = useAppSelector((state) => state?.getDivisionResponsibilityReducer?.data?.data?.totalCount);

  
    useEffect(() => {
        const payload: any = {
            data: { status: "ACTIVE" }
        }
        dispatch(getDivisionThunk(payload));
        dispatch(getDepartmentThunk(payload))
    }, []);

    const getAssignedEmployeeBages = (assignedEmployees: Array<any>) => {
        return assignedEmployees?.map((item: any, index: number) => {
            return <div className='profile-badge' key={`assigned-employee-badge-${index}`}>
                <div className='profile-img'>
                    {/* <img src={item?.profilePath || avatar} alt="profile" /> */}
                    <p className='profile-circle-md responsibilityprofile-circle'>{item?.name?.charAt(0).toUpperCase()}</p>
                </div>
                <p className='name'>{item?.name}</p>
            </div>
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: 'List of responsibilities',
                accessor: 'label',
                sort: true
            },
            {
                Header: 'Classification',
                sort: true,
                Cell: (cell: any) => {
                    return <div className={`badge-md`}
                        style={{ backgroundColor: `${cell?.row?.original?.id?.responsibilityFKId?.bgColorCode}` }}>
                        <span style={{ color: `${cell?.row?.original?.id?.responsibilityFKId?.textColorCode}` }}>
                            {cell.row.original?.classification}</span>
                    </div>
                }
            },
            {
                Header: 'Frequency',
                sort: true,
                accessor: "frequency"
            },
            {
                Header: 'Assigned employees',
                Cell: (cell: any) => {
                    const lengthOfAssignedEmployess = cell.row.original?.employees?.length;
                    const assignedEmployees = lengthOfAssignedEmployess >= 2 ? cell.row.original?.employees?.slice(0, 2) : cell.row.original?.employees;
                    return <div className='profile-badge-wrapper  allow-max-width'>
                        {getAssignedEmployeeBages(assignedEmployees)}
                        <p className="plus" onClick={(e) => {
                            e.stopPropagation()
                            const selectedEmp = cell.row.original?.employees?.map((item: any) => item._id)
                            alreadySelected({ selectedEmp, add: true })
                            popup(true)
                            selectDivison(cell.row.original?.id?._id)
                        }} >+</p>
                        <p className="plus" onClick={(e) => {
                            e.stopPropagation()
                            const selectedEmp = cell.row.original?.employees?.map((item: any) => item._id)
                            alreadySelected({ selectedEmp, add: false })
                            popup(true)
                            selectDivison(cell.row.original?.id?._id)
                        }} >-</p>
                        {lengthOfAssignedEmployess > 2 &&
                            <>
                                <p className="plus-count">+{lengthOfAssignedEmployess - 2}</p>
                            </>
                        }
                    </div>
                }
            },
            {
                accessor: 'id',
                Cell: () => {
                    return <div className={`arrow`}>
                        <img src={arrow} alt="arrow" />
                    </div>
                }
            },
        ], []);

    const subTable = (row: any) => {
        const lengthOfAssignedEmployess = row.original?.employees?.length;
        const assignedEmployees = lengthOfAssignedEmployess < 2 ? row.original?.employees?.slice(0, 2) : row.original?.employees;
        return <>
            {/* <p className="description">{row.original?.dndFKId?.responsibilityFKId?.scope}</p> */}
            <div className='profile-badge-wrapper'>
                {getAssignedEmployeeBages(assignedEmployees)}
            </div>
        </>
    }

    useEffect(() => {
        if (divisionList?.length > 0 && !divsonId) {
            if (handleChange) handleChange({ data: divisionList[0], key: "divResDiv" })
        }
        if (departmentList?.length > 0 && !deptId) {
            if (handleChange) handleChange({ data: departmentList[0], key: "divResDept" })
        }
    }, [divisionList, departmentList])

    return (
        <div ref={print}>
            <div className='divsionResponsibility employee-main'>
                <div className='reportee-filters'>
                    <div className="dropdowns mb-0">
                        <DropDown loading={getDepartmentLoading} onChange={(data: any) => { if (handleChange) handleChange({ data, key: "divResDiv" }) }} options={divisionList && divisionList} mainClass="filter-dropdown" placeholder="Division" value={divsonId} />
                        <DropDown loading={getDepartmentLoading} onChange={(data: any) => { if (handleChange) handleChange({ data, key: "divResDept" }) }} options={departmentList && departmentList} mainClass="filter-dropdown" placeholder="Department" value={deptId} />
                    </div>
                    <div className='table-search mb-0'>
                        <div className='d-flex align-items-center gap-3'>
                            <InputSearch onSearch={handleSearch} value={searchVal} resetValue={restInput}/>
                            {/* <FilterDropDown filterIcon={filterIcon} checkedValues={filterValue} setCheckedValues={setFilterValue} lists={lists} /> */}
                        </div>
                    </div>
                </div>
                <div className='h-100 responsibility-sub-component'>
                    {
                        divsonId && deptId ?
                            <Card className={showData?.length === 0 ? `loader-card` : ""} >
                                <TableWithCardLoader
                                    data={showData}
                                    columns={columns}
                                    sortBy={sortBy}
                                    setSortBy={setSortBy}
                                    renderRowSubComponent={subTable}
                                    isExpanded={true}
                                    expandAll={expandTableRow}
                                    totalCount={totalDivResponsibilityCount}
                                    customPageSize={Number(pageSize)}
                                    dynamicPagination={true}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    loader={getdivsionResLoading || getDivisionLoading || getDepartmentLoading}
                                />
                            </Card>
                            :
                            <InfoCard info={divsonId && deptId ? "" : <p>  Please select {!divsonId && "division"} {!divsonId && !deptId && "and"} {!deptId && "department"} </p>} />
                    }
                </div>
            </div>
        </div>
    )
}
