import { NavLink } from "react-router-dom";
import loginLogo from "../../../assets/images/logo.png";

interface authImgProps {
    loginImg: string,
    heading: string,
    description: string,
}

const AuthImg = ({ loginImg, heading, description }: authImgProps) => {
    return (
        <div className="col-7 auth-img p-0">
            <NavLink className={`logo`} to={`/`} >
                <img src={loginLogo} alt="logo" />
            </NavLink>
            {/* TODO */}
            {/* <div className="about-desc">
                <h1 className="heading">{heading}</h1>
                <p className="desc">{description}</p>
            </div> */}
            <div className="auth-img-wrapper">
                <img className="full-img" src={loginImg} alt="sign-img" />
            </div>
        </div>
    )
}

export default AuthImg