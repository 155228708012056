import { Modal } from 'reactstrap';
import closeIcon from '../../assets/images/svgIcons/close_icon.svg';
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';
import { useAppSelector } from '../../store/store';

interface ConfirmationModalProps {
    showModal: boolean,
    toggle: Function,
    modalTitle: string,
    description: string
    primaryBtnName: string,
    secoundaryBtnName: string,
    primaryAction: any,
    hideCancel?: boolean,
    secondaryAction?: any,
    loader?: boolean | Boolean
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
    const { showModal, toggle, modalTitle, primaryAction, description, primaryBtnName, secoundaryBtnName, hideCancel, secondaryAction, loader } = props;

    const toggleModel = () => {
        toggle();
    }

    return (
        <>
            <Modal size="md" isOpen={showModal} centered={true}
                className='add-employee-model confirmation-modal'
                toggle={toggleModel}
            >
                <div className="modal-header">
                    <h1 className='title'>{modalTitle}</h1>
                    <span onClick={toggleModel} aria-hidden="true" className='close-icon'><img src={closeIcon} alt="close" /></span>
                </div>
                <div className="modal-body">
                    <div>
                        <p>{description}</p>
                    </div>
                    <div className="model-actions">
                        {!hideCancel && (
                            <SecondaryButton
                            className='btn-position'
                                btnName={secoundaryBtnName}
                                onClick={secondaryAction}
                            />
                        )}
                        <PrimaryButton btnName={primaryBtnName} onClick={primaryAction} loader={loader} />
                    </div>
                </div>
            </Modal >
        </>
    )
}

export default ConfirmationModal