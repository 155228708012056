import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { NavLink } from 'react-router-dom';
import { Label } from 'reactstrap';
import AuthImg from './component/AuthImg';
import loginImg from '../../assets/images/login_img.png';
import logo from "../../assets/images/logo.png";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useNavigate } from "react-router";
import { forgotPswThunk } from "../../store/asyncThunks/auth";
import ErrorValidationMessage from '../../components/ErrorValidationMessage';

const Forgot = () => {

    document.title = `Forgot Password | ${process.env.REACT_APP_DOCUMENT_TITLE}`;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const forgotPasswordLoader = useAppSelector((state) => state?.forgotPasswordReducer?.loading);


    const forgotFormSchema = yup.object().shape({
        email: yup.string().email().required().trim(),
    });

    const forgotFormValues = {
        email: '',
    }

    const { handleSubmit, register, formState: { errors }, setValue, clearErrors, watch } = useForm({
        defaultValues: forgotFormValues,
        resolver: yupResolver(forgotFormSchema),
    })

    const navigateAfterSignup = () => {
        navigate(`/reset-password`);
    };

    const onSubmit = (values: any) => {
        const data: any = {}
        data['data'] = values;
        data["navigate"] = navigateAfterSignup;
        dispatch(forgotPswThunk(data));
    }

    return (
        <div className='auth'>
            <div className="row auth-wrapper">
                <AuthImg loginImg={loginImg} description={`Lorem Ipsum is simply dummy text of the printing and typesetting industry.`} heading={`Welcome`} />

                <div className="col-5 auth-form p-0">
                    <div className='form-wrapper'>
                        <div className="logo-container">
                            <img src={logo} alt="logo" />
                        </div>
                        <div className="auth-header">
                            <h1 className="page-title">Forgot password</h1>
                            <p className='desc'>Enter your email for the verification process. We will send 4 digits code to your email.</p>
                        </div>
                        <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                            <div className="input-wrapper">
                                <Label className='required'>Email</Label>
                                <input type="text" className={`custom-input form-control ${errors.email?.message ? "error-input" : ""}`} {...register("email")} />
                                {errors.email?.message && <ErrorValidationMessage errors={errors.email} name="email" />}
                            </div>
                            <div className="actions">
                                <PrimaryButton btnName='Next' loader={forgotPasswordLoader} />
                            </div>
                            <div className="change-action text-center">
                                <NavLink to={`/signin`}>Back to signin</NavLink>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Forgot