import InputSearch from "../../../../components/InputSearch";
import NoData from "../../../../components/NoData";
import PageLoader from "../../../../components/PageLoader";
import ResponsibilitiesMappingSkeleton from "../../../../components/skeleton/ResponsibilitiesMappingSkeleton";

interface mapping {
    mappingHead: string,
    mappingdata: any,
    register: any,
    resetValue: boolean,
    responsibilitySearch: Function
    searchVal?: string,
    isSkeleton?: Boolean
}

const ResponsibilitiesMapping = (props: mapping) => {
    const { mappingHead, mappingdata, register, resetValue, responsibilitySearch, searchVal, isSkeleton } = props;


    return (
        <div className='card h-100 me-0'>
            <div className='mp-head'>
                <h5>{mappingHead}</h5>
                <InputSearch onSearch={responsibilitySearch} resetValue={resetValue} value={searchVal} />
            </div>
            <div className='mapping-grp custom-scroll'>
                {isSkeleton ? (
                    <PageLoader />
                ) : mappingdata?.length > 0 ? (
                    <>
                        {mappingdata?.map((item: any, index: number) => (
                            <div className="mapping-wrapper" key={"responsibilities-" + item?._id + "-" + index}>
                                <label className='mapping-item'>
                                    <div className="map-label">
                                        <h5>{item?.label}</h5>
                                        <div className='d-flex align-items-center pt-2'>
                                            <p className={`badge-md me-2`}
                                                style={{
                                                    backgroundColor: `${item.bgColorCode}`
                                                }}>
                                                <span style={{ color: `${item.textColorCode}` }}>
                                                    {item?.classificationFKId}</span></p>

                                            <p className='badge-normal'>{item?.frequencyFKId}</p>
                                        </div>
                                        <div>
                                            <h5 className="time-duration">Duration:<span className="time">{item?.duration}<span className="hr-min">(HR:MIN)</span></span></h5>
                                        </div>
                                        <h5 className="description-head">Description</h5>
                                        <div >
                                            <p className="me-3 description-content">{item?.scope}</p>
                                        </div>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" defaultChecked={item?.isSelected} {...register(`responsibility`)} type="checkbox" value={item?._id} id={item?._id} onChange={(e) => e.target.value} />
                                        <label className="form-check-label" htmlFor={item?._id}>
                                        </label>
                                    </div>
                                </label>
                            </div>
                        ))
                        }
                    </>
                ) : (
                    <NoData />
                )}
            </div >
        </div >
    );
};

export default ResponsibilitiesMapping;