interface toggleProps {
    id?: string | number,
    defaultChecked?: boolean,
    disabled?: boolean,
    onChange: Function,
    switchStyle?: Record<string, any>
    [key: string]: any
}

const ToggleSwitch = ({ id = '', onChange, defaultChecked, disabled, switchStyle, ...rest }: toggleProps) => {
    return (
        <label htmlFor={`toggle-${id}`}>
            <input id={`toggle-${id}`} defaultChecked={defaultChecked} disabled={disabled} className="toggle-checkbox" type="checkbox" onChange={(e) => {
                onChange(e);
            }} {...rest} />
            <div className="toggle-switch" style={switchStyle}></div>
        </label>
    )
}

export default ToggleSwitch