import { createSlice } from "@reduxjs/toolkit";
import { defaultState } from "../../../../interfaces/auth";
import { addDndResponsibilitiesThunk } from "../../../../asyncThunks/manage/mapping";

const initialState: defaultState = {
    error: null,
    data: null,
    loading: false,
};

export const addDndResponsibilities = createSlice({
    name: "addDndResponsibilities",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addDndResponsibilitiesThunk.pending, (state, action) => {
            state.error = null;
            state.data = null;
            state.loading = true;
        });
        builder.addCase(addDndResponsibilitiesThunk.fulfilled, (state, action) => {
            if (action?.payload?.status?.toLowerCase() === "success") {
                state.error = null;
                state.data = action.payload;
            } else {
                state.error = action?.payload;
                state.data = null;
            }
            state.loading = false;
        });
        builder.addCase(addDndResponsibilitiesThunk.rejected, (state, action) => {
            state.error = action.payload;
            state.data = null;
            state.loading = false;
        });
    },
});

export default addDndResponsibilities.reducer;
