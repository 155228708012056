import { useState } from 'react';
import eyeClose from '../assets/images/svgIcons/eye_close.svg';
import eyeOpenSvg from '../assets/images/svgIcons/eye_open.svg';

interface passwordInputProps {
    register?: any,
    registerName?: string,
    [key: string]: any,
}

const PasswordInput = (props: passwordInputProps) => {
    const { register, registerName, ...rest } = props;

    const [eyeOpen, setEyeOpen] = useState(false);

    return (
        <div className="password-input">
            <input type={eyeOpen ? "text" : "password"} {...register(registerName)} {...rest} />
            <img className='eye-img' onClick={() => setEyeOpen(!eyeOpen)} src={eyeOpen ? eyeOpenSvg : eyeClose} alt="eye" />
        </div>
    )
}

export default PasswordInput