import React from 'react';
import noDataImage from '../assets/images/svgImages/no-data.svg'

const NoData = () => {
    return (
        <div>
            <div className="no-data-msg">
                <div>
                    <img src={noDataImage} alt='No Data Image' />
                    <h6 className="text-center">No data is available</h6>
                </div>
            </div>
        </div>
    );
};

export default NoData;