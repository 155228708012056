import { createSlice } from "@reduxjs/toolkit";
import { signupRequestInterface } from "../../interfaces/signupRequest";

const initialState: signupRequestInterface = {
    data: null,
};

export const decodeTokenSlice = createSlice({
    name: "decodeTokenSlice",
    initialState,
    reducers: {
        decodeToken: ((state, action) => {
            const { payload } = action;
            state.data = payload;
        })
    }
});

// Action creators are generated for each case reducer function
export const { decodeToken } = decodeTokenSlice.actions;

export default decodeTokenSlice.reducer;
