import { ChangeEvent, useEffect, useRef, useState } from 'react';
import searchIcon from '../assets/images/svgIcons/search-icon.svg';
import { debounce } from 'lodash';

interface searchProps {
    target?: any;
    onSearch?: Function;
    event?: HTMLInputElement,
    value?: string
    resetValue?: boolean
}

const InputSearch = ({ onSearch, value, resetValue = false }: searchProps) => {
    const [searchValue, setSearchValue] = useState("");
    const handleInputChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
        if (onSearch) {
            onSearch(event.target.value);
        }
    }, 2000);   

    const ref = useRef<any>(null);

    useEffect(() => {
        if (resetValue === true) {
            setSearchValue("")
        }
        if (value) {
            setSearchValue(value)
            ref.current.focus()
        }
    }, [resetValue, value])

    return (
        <div className="search">
            <input ref={ref} type="text" className="filter-search custom-input" value={searchValue} onChange={(e) => {
                setSearchValue(e.target.value)
                handleInputChange(e)
            }} />
            <div className="search-icon" onClick={() => ref.current.focus()}>
                <img src={searchIcon} alt="search" />
            </div>
        </div>
    )
}

export default InputSearch