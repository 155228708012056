import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "../layout/Layout";
import ProtectedRoute from "./ProtectedRoute";
import OverView from "../pages/overView/OverView";
import Scope from "../pages/scope/Scope";
import Mapping from "../pages/manage/Mapping";
import Setup from "../pages/manage/Setup";
import Login from "../pages/authentication/Login";
import Forgot from "../pages/authentication/Forgot";
import ResetPassword from "../pages/authentication/ResetPassword";
import { Toaster } from "react-hot-toast";
import MyProfile from "../pages/myProfile/MyProfile";
import ReporteesDetails from "../pages/overView/ReporteesDetails";
import { RoleGuard } from "./RoleGuard";
import { OVERVIEW, MANAGE, SCOPE } from "../constants/datas/roleslist";
import NotFound from "../pages/NotFound";

const Routing = () => {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/overview" replace />} />
          <Route path="/" element={<Layout />}>
            <Route element={<ProtectedRoute />}>
              <Route path="/overview" element={<RoleGuard allowRoles={OVERVIEW} element={<OverView />} />} />
              <Route path="/overview/reportees/:id" element={<RoleGuard allowRoles={OVERVIEW} element={<ReporteesDetails />} />} />
              <Route path="/profile" element={<RoleGuard allowRoles={OVERVIEW} element={<MyProfile />} />} />
              <Route path="/scope" element={<RoleGuard allowRoles={SCOPE} element={<Scope />} />} />
              <Route path="/manage/setups" element={<RoleGuard allowRoles={MANAGE} element={<Setup />} />} />
              <Route path="/manage/mapping" element={<RoleGuard allowRoles={MANAGE} element={<Mapping />} />} />
            </Route>
          </Route>
          <Route path="/signin" element={<Login />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <Toaster position="bottom-right" reverseOrder={false} toastOptions={{
        success: {
          iconTheme: {
            primary: '#fff',
            secondary: '#34c38f',
          },
          style: {
            background: '#34c38f',
            color: '#fff',
          },

        },
        error: {
          iconTheme: {
            primary: 'white',
            secondary: '#f46a6a',
          },
          style: {
            background: '#f46a6a',
            color: '#fff',
          },
        },
      }} />
    </div >
  );
};

export default Routing;
