import overview from '../assets/images/svgIcons/sidebar/overview.svg'
import overview_active from '../assets/images/svgIcons/sidebar/overview_active.svg'
import scope from '../assets/images/svgIcons/sidebar/scope.svg'
import scope_active from '../assets/images/svgIcons/sidebar/scope_active.svg'
import manage from '../assets/images/svgIcons/sidebar/manage.svg'
import manage_active from '../assets/images/svgIcons/sidebar/manage_active.svg'
import setup from '../assets/images/svgIcons/sidebar/setup.svg'
import setup_active from '../assets/images/svgIcons/sidebar/setup_active.svg'
import mapping from '../assets/images/svgIcons/sidebar/mapping.svg'
import mapping_active from '../assets/images/svgIcons/sidebar/mapping_active.svg'
import { SCOPE, OVERVIEW, MANAGE } from './datas/roleslist'

export const MenuItems = [
    {
        label: 'Overview',
        icon: overview,
        active_icon: overview_active,
        path: "/overview",
        role: OVERVIEW
    },
    {
        label: 'Scope',
        icon: scope,
        active_icon: scope_active,
        path: "/scope",
        role: SCOPE
    },
    {
        label: 'Manage',
        icon: manage,
        active_icon: manage_active,
        role: MANAGE,
        subMenu: [
            {
                label: 'Setups',
                icon: setup,
                active_icon: setup_active,
                path: "/manage/setups",
                role: MANAGE,
            },
            {
                label: 'Mapping',
                icon: mapping,
                active_icon: mapping_active,
                path: "/manage/mapping",
                role: MANAGE,
            }
        ]
    }
]