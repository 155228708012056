import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import { useNavigate } from 'react-router';
import { tokenKey } from '../../helpers/api_helper';
import idle_image from "../../assets/images/svgImages/idle_timer.svg";

interface IdleModalProps {
    showModal: boolean,
    toggle: Function,
}

const IdleModal = (props: IdleModalProps) => {
    const { showModal, toggle } = props;

    const navigate = useNavigate();

    const [timer, setTimer] = useState(10);

    useEffect(() => {
        if (showModal) {
            setInterval(() => {
                setTimer(prev => {
                    if (prev === 0) {
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000)
        }
    }, [showModal]);

    useEffect(() => {
        if (showModal && timer === 0) {
            localStorage.removeItem(tokenKey)
            navigate('/signin');
        }
    }, [timer, showModal])

    return (
        <Modal size="md" isOpen={showModal} centered={true}
            className='add-employee-model confirmation-modal'
        >
            <div className="modal-header d-block">
                <div className='text-center'>
                    <img className='w-75' src={idle_image} alt="idle-img" />
                </div>
                <h1 className='title mt-4'>{"Your idle for too long please click continue to stay otherwise we will be redirect to signin."}</h1>
            </div>
            <div className="modal-body">
                <div>
                    <PrimaryButton btnName={"Continue"} onClick={() => toggle()} />
                </div>
            </div>
        </Modal >
    )
}

export default IdleModal