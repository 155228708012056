export const mappingTabsList = ["Employees", "Responsibilities"];

export const setupsTabsList = [
  "Roles",
  "Department",
  "Divisions",
  "Classifications",
  "Frequency",
  "Responsibilities",
];

export const overViewTabsList = [
  "My responsibilities",
  "Division responsibilities",
  "Reportees",
];

export const scopeTabsList = [
  "Organisation scope",
  "Division scope",
  "Department scope",
];

export const dayList = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Half month", value: "halfmonth" },
  { label: "Monthly", value: "monthly" },
  { label: "Quarterly", value: "quarterly" },
  { label: "Half yearly", value: "halfyearly" },
  { label: "Yearly", value: "Yearly" }

];

export const mappingList = [{ label: "Employee", value: "Employee" }, { label: "Responsibility", value: "Responsibility" }];

export const mapResponsibility = [{ label: "Responsibilities", value: "Responsibilities" }, { label: "Responsibilities Mapped", value: "Responsibilities Mapped" }];
