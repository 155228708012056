import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { NavLink } from 'react-router-dom';
import { Label } from 'reactstrap';
import AuthImg from './component/AuthImg';
import loginImg from '../../assets/images/login_img.png';
import logo from "../../assets/images/logo.png";
import { useEffect, useState } from 'react';
import PasswordInput from '../../components/PasswordInput';
import PasswordStrengthMeter from '../../components/PasswordStrengthMeter';
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useNavigate } from "react-router";
import { forgotPswThunk, resetPswThunk } from "../../store/asyncThunks/auth";
import ErrorValidationMessage from '../../components/ErrorValidationMessage';
import { passwordLimit } from '../../constants/HandleFormLimit';

const ResetPassword: React.FC = () => {

    document.title = `Reset Password | ${process.env.REACT_APP_DOCUMENT_TITLE}`;

    const decodedEmail: any = localStorage.getItem("register");
    const userEmail = atob(decodedEmail);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(30);
    const [password, setPassword] = useState<string | undefined>('');
    const [PasswordValidate, setPasswordValidate] = useState<number | undefined>(0);
    const ResetPasswordLoader = useAppSelector((state) => state?.resetPasswordReducer?.loading);

    const resetpwdFormSchema = yup.object({
        verificationToken: yup.string().required(),
        password: yup.string().required().test("minLength", '-', val => {
            if (!val) return false;
            return val.length >= passwordLimit;
        }).test('custom', 'Password must be reach medium strength', val => {
            if (PasswordValidate && PasswordValidate >= 2) {
                return true;
            } else {
                return false;
            }
        }).trim(),
        confPwd: yup.string().oneOf([yup.ref('password'), ''], 'Passwords must match').required('Confirm Password is required'),
    });

    const resetpwdFormValues = {
        email: userEmail,
        password: '',
        confPwd: '',
        verificationToken: ''
    };

    const { handleSubmit, register, formState: { errors }, watch } = useForm({
        defaultValues: resetpwdFormValues,
        resolver: yupResolver(resetpwdFormSchema),
    })

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === 'password') {
                setPassword(value?.password);
            }
        });
        return () => subscription.unsubscribe()
    }, [watch])

    const navigateAfterSignup = () => {
        navigate("/signin");
    };

    const onSubmit = (values: any) => {
        const data: any = {}
        data['data'] = values;
        data["navigate"] = navigateAfterSignup;
        dispatch(resetPswThunk(data));
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const resendOTP = () => {
        const data: any = {};
        data["data"] = { email: userEmail };
        setMinutes(1);
        setSeconds(30);
        dispatch(forgotPswThunk(data));
    };

    return (
        <div className='auth'>
            <div className="row auth-wrapper">
                <AuthImg loginImg={loginImg} description={`Lorem Ipsum is simply dummy text of the printing and typesetting industry.`} heading={`Welcome`} />

                <div className="col-5 auth-form p-0">
                    <div className='form-wrapper'>
                        <div className="logo-container">
                            <img src={logo} alt="logo" />
                        </div>
                        <div className="auth-header">
                            <h1 className="page-title">Reset password</h1>
                            <p className='desc'>Enter the 6 digits code that you received on your email and set the new password for your account.</p>
                        </div>
                        <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                            <div className="input-wrapper">
                                <Label className='required'>Verification code</Label>
                                <input type="number" className={`custom-input form-control ${errors.verificationToken?.message ? "error-input" : ""}`} {...register("verificationToken")} />
                                {errors.verificationToken?.message && <ErrorValidationMessage errors={errors.verificationToken} name="verification code" />}
                                <div className="change-action mt-2 d-flex justify-content-end">
                                    {seconds > 0 || minutes > 0 ? (
                                        <p className="timing">Time remaining: <span className="remaining-time">{minutes < 10 ? `0${minutes}` : minutes}:
                                            {seconds < 10 ? `0${seconds}` : seconds}</span>
                                        </p>
                                    ) : (
                                        <p className="f-size-12 timing">Didn't receive code?</p>
                                    )}
                                    <button className="ms-2 border-0 bg-transparent p-0 resend-code"
                                        disabled={seconds > 0 || minutes > 0}
                                        style={{
                                            color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#DF0C00",
                                        }}
                                        onClick={resendOTP}
                                    >
                                        Resend code
                                    </button>
                                </div>
                            </div>
                            <div className="input-wrapper">
                                <Label className='required'>New password</Label>
                                <PasswordInput className={`custom-input form-control ${errors.password?.message ? "error-input" : ""}`} register={register} registerName={"password"} />
                                {errors.password?.message && <ErrorValidationMessage errors={errors.password} name="new password" sizeMin={passwordLimit} />}
                                <PasswordStrengthMeter password={password} setPasswordValidate={setPasswordValidate} />
                            </div>

                            <div className="input-wrapper">
                                <Label className='required'>Confirm password</Label>
                                <PasswordInput className={`custom-input form-control ${errors.confPwd?.message ? "error-input" : ""}`} register={register} registerName={"confPwd"} />
                                {errors.confPwd?.message && <ErrorValidationMessage errors={errors.confPwd} matchField="new password" name="confirm password" />}
                            </div>
                            <div className="actions">
                                <PrimaryButton btnName='Save' loader={ResetPasswordLoader} />
                            </div>
                            <div className="change-action text-center">
                                <NavLink to={`/signin`}>Back to signin</NavLink>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default ResetPassword