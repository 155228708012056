import React, { useEffect, useRef } from 'react'
import { useState } from "react"
import PageTitle from '../../components/PageTitle';
import { scopeTabsList } from "../../constants/datas/tabsList";
import Tabs from "../../components/Tabs";
import Organisation from './scopeComponents/Organisation';
import DepartmentScope from './scopeComponents/DepartmentScope';
import DivisionScope from './scopeComponents/DivisionScope';
import printImg from '../../assets/images/svgIcons/printer.svg';
import downloadImg from '../../assets/images/svgIcons/download.svg';
import { useReactToPrint } from 'react-to-print';
import csvDownload from '../printFiles/csvDownload';
import { useAppSelector } from '../../store/store';
import getActiveTabBasedOnRolePrevilege from '../../constants/getActiveTabBasedOnRolePrevilege';


const Scope = () => {
  document.title = `Scope | ${process.env.REACT_APP_DOCUMENT_TITLE}`;

  const organisationRef = useRef<any>();
  const divisionRef = useRef<any>();
  const departmentRef = useRef<any>();

  const organisationState = useAppSelector((state) => state.getorganisationReducer?.data?.data);
  const divisionState = useAppSelector((state) => state.getdivisionReducer?.data?.data);
  const departmentState = useAppSelector((state) => state.getdepartmentReducer?.data?.data);
  const organisationStateLoading = useAppSelector((state) => state.getorganisationReducer?.loading);
  const divisionStateLoading = useAppSelector((state) => state.getdivisionReducer?.loading);
  const departmentStateLoading = useAppSelector((state) => state.getdepartmentReducer?.loading);
  const userRecord = useAppSelector((state) => state?.decodeTokenSliceReducer?.data);

  const [activeTab, setActiveTab] = useState<number | null | undefined>(null);
  const [expandTableRow, setExpandTableRow] = useState(false);

  const organisationPrint = useReactToPrint({
    content: () => organisationRef.current || null,
    onAfterPrint: () => setExpandTableRow(false)
  });

  const divisionPrint = useReactToPrint({
    content: () => divisionRef.current || null,
    onAfterPrint: () => setExpandTableRow(false)
  });

  const departmentPrint = useReactToPrint({
    content: () => departmentRef.current || null,
    onAfterPrint: () => setExpandTableRow(false)
  });

  const print = () => {
    if (activeTab === 0) organisationPrint()
    else if (activeTab === 1) divisionPrint()
    else if (activeTab === 2) departmentPrint()
  }

  const handleDownload = () => {
    if (activeTab === 0 && organisationState) {
      const printData = [organisationState]?.map((data: any) => ({
        label: data?.label,
        scope: data?.scope
      }))
      csvDownload(printData);
    }
    else if (activeTab === 1 && divisionState?.divisions?.length > 0) {
      const printData = divisionState?.divisions?.map((data: any) =>
      ({
        division: data?.label,
        scope: data?.scope
      }))
      csvDownload(printData);
    }
    else if (activeTab === 2 && departmentState?.departments?.length > 0) {
      const printData = departmentState?.departments?.map((data: any) =>
      ({
        division: data?.label,
        scope: data?.scope
      }))
      csvDownload(printData);
    }
  };

  const handlePrint = () => {
    setExpandTableRow(true);
    setTimeout(() => {
      print();
    }, 500);
  };

  useEffect(() => {
    if (userRecord?.action?.scope) {
      const tab = getActiveTabBasedOnRolePrevilege({ object: userRecord?.action?.scope, tabsList: scopeTabsList });
      setActiveTab(tab);
    }
  }, [userRecord])

  return (
    <div className="scope">
      <PageTitle title={`Scope`} />
      <div className="tabSec">
        <div className="tabs custom-scroll">
          <Tabs menu={`scope`} tabsList={scopeTabsList} setActiveTab={setActiveTab} activeTab={activeTab} />
        </div>
        <div className='downAndPrint'>
          <div className={
            (activeTab === 0 && (!organisationStateLoading && organisationState ? "options" : "options optionsDisable")) ||
            (activeTab === 1 && (!divisionStateLoading && divisionState?.divisions?.length > 0 ? "options" : "options optionsDisable")) ||
            (activeTab === 2 && (!departmentStateLoading && departmentState?.departments?.length > 0 ? "options" : "options optionsDisable")) || "options optionsDisable"
          }>
            <img src={printImg} alt="print" onClick={() => {
              if (
                (activeTab === 0 && organisationState) ||
                (activeTab === 1 && divisionState?.divisions?.length > 0) ||
                (activeTab === 2 && departmentState?.departments?.length > 0)
              ) handlePrint();
            }} />
          </div>
          <div className={
            (activeTab === 0 && (!organisationStateLoading && organisationState ? "options" : "options optionsDisable")) ||
            (activeTab === 1 && (!divisionStateLoading && divisionState?.divisions?.length > 0 ? "options" : "options optionsDisable")) ||
            (activeTab === 2 && (!departmentStateLoading && departmentState?.departments?.length > 0 ? "options" : "options optionsDisable")) || "options optionsDisable"
          }>
            <img src={downloadImg} alt="download" onClick={() => {
              if (
                (activeTab === 0 && organisationState) ||
                (activeTab === 1 && divisionState?.divisions?.length > 0) ||
                (activeTab === 2 && departmentState?.departments?.length > 0)
              ) handleDownload();
            }} />
          </div>
        </div>
      </div>
      {activeTab === 0 && <Organisation print={organisationRef} />}
      {activeTab === 1 && <DivisionScope print={divisionRef} expandTableRow={expandTableRow} />}
      {activeTab === 2 && <DepartmentScope print={departmentRef} expandTableRow={expandTableRow} />}
    </div>
  )
}

export default Scope