import { SetStateAction } from "react";
import sort_active from '../../../assets/images/svgIcons/sort_active.svg'
import sort from '../../../assets/images/svgIcons/sort.svg'

interface TableHeaderSectionProps {
    headerGroups: any,
    setSortBy: SetStateAction<any>,
    setFilter: SetStateAction<any>,
    setCurrentPage: SetStateAction<any>,
    filter: any,
    currentPage: number,
    sortBy: any,
    columns: Array<any>
}

const TableHeaderSection = ({ headerGroups, setSortBy, setFilter, setCurrentPage, filter, currentPage, sortBy, columns }: TableHeaderSectionProps) => {
    let sortArray: any;

    const sorttingField = (filed: string, sort: boolean | null, value: string | null) => {
        headerGroups[0]?.headers?.map((item: any) => {
            if (!sort && !value) {
                sortArray = {
                    field: "created_at",
                    sort: null,
                    value: "descending",
                };
            } else if (filed === item.id) {
                sortArray = {
                    sort: sort,
                    field: item.id,
                    value: value,
                    subField: item?.subField,
                };
            }
        });
        setSortBy(sortArray);
        if (filter) setFilter(filter);
        if (setCurrentPage) setCurrentPage(currentPage);
    };

    const handleSortClick = (column: any) => {
        if (sortBy?.sort === null) {
            sorttingField(column.id, true, "ascending");
        } else if (sortBy?.sort === true) {
            sorttingField(column?.id, false, "descending");
        } else if (sortBy?.sort === false) {
            sorttingField(column?.id, null, null);
        }
    }

    return (
        <>
            <thead className="table-nowrap">
                {headerGroups.map((headerGroup: any) => (
                    <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => (
                            <th className="" key={column.id}>
                                <div className="cell-head">
                                    <span onClick={() => {
                                        if (column.sort) {
                                            handleSortClick(column)
                                        }
                                    }} className={`${column.sort ? "cursor-pointer" : ""}`}>
                                        {column.render("Header")}
                                    </span>
                                    {column?.sort && (
                                        <span
                                            className="ms-2 cursor-pointer"
                                            onClick={() => handleSortClick(column)}
                                        >
                                            <img className={`${sortBy?.sort ? '' : sortBy?.sort === false ? "descending" : ""}`} src={(sortBy?.sort === null || sortBy?.field !== column?.id) ? sort : sort_active} alt="sort" />
                                        </span>
                                    )}
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
        </>
    )
}

export default TableHeaderSection