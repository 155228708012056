import React from 'react'
import { clearToken, tokenKey } from '../helpers/api_helper';
import { jwtDecode } from 'jwt-decode';
import { Navigate } from 'react-router-dom';
interface roleGuardProps {
    element: any,
    allowRoles: String | any[]
}
export const RoleGuard = ({ allowRoles, element }: roleGuardProps) => {
    const token = localStorage.getItem(tokenKey)
    const user: any = token && jwtDecode(token);

    if (!user || !allowRoles.includes(user?.role)) {
        localStorage.removeItem(tokenKey)
        clearToken()
        return <Navigate to="/signin" replace />;
    }
    return element;
}
