export const designationOptions = [
  { label: "Manager", value: "MANAGER" },
  { label: "Supervisor", value: "SUPERVISOR" },
  { label: "Team Leader", value: "TEAM_LEADER" },
  { label: "Sales Representative", value: "SALES_REP" }
]

export const divisionOptions = [
  { label: "TN WL", value: "TN_WL" },
  { label: "AP WL", value: "AP_WL" },
  { label: "WEIMA", value: "WEIMA" },
  { label: "NR WL", value: "TAMIL_NADU" },
]

export const departmentOptions = [
  { label: "HR", value: "HUMAN_RESOURCE" },
  { label: "Purchase", value: "PURCHASE" },
  { label: "Sales", value: "SALES" },
  { label: "Accounts", value: "ACCOUNTS" },
  { label: "Billing", value: "BILLING" },
]

export const monthsOptions = [
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" }
]

export const yearsOptions = [
  { label: "2024", value: "2024" },
  { label: "2023", value: "2023" },
  { label: "2022", value: "2022" },
  { label: "2021", value: "2021" },
  { label: "2020", value: "2020" },
  { label: "2019", value: "2019" },
  { label: "2018", value: "2018" }
]
interface TimeOption {
  label: string;
  value: string;
}

const generateOptions = (endHour: number, endMinute: number): { hoursOptions: TimeOption[], minutesOptions: TimeOption[] } => {
  const hoursOptions: TimeOption[] = Array.from({ length: endHour + 1 }, (_, i) => ({
    label: i < 10 ? `0${i}` : String(i),
    value: i < 10 ? `0${i}` : String(i),
  }));

  const minutesOptions: TimeOption[] = Array.from({ length: endMinute + 1 }, (_, i) => ({
    label: i < 10 ? `0${i}` : String(i),
    value: i < 10 ? `0${i}` : String(i),
  }));

  return { hoursOptions, minutesOptions };
};

export const { hoursOptions, minutesOptions }: { hoursOptions: TimeOption[], minutesOptions: TimeOption[] } = generateOptions(7, 59);




