import { useEffect } from "react";
import { toggleButtonProps } from "./ButtonInterface";


const ToggleButton = (props: toggleButtonProps) => {

    const { currentTab, setCurrentTab, list } = props;


    return (
        <div className="toggle-button-wrapper">
            {list && list?.map((item: any, index: Number) => {
                return <button key={`toggle-button-${index}`} className={`${index === currentTab ? "active-btn-tab" : ""}`} onClick={() => { if (setCurrentTab) setCurrentTab(index) }}>{item.label}</button>
            })}
        </div>
    )
}

export default ToggleButton