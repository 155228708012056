import React from 'react';
import loginImg from '../../assets/images/login_img.png';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { Label } from 'reactstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import AuthImg from './component/AuthImg';
import PasswordInput from '../../components/PasswordInput';
import { NavLink } from 'react-router-dom';
import logo from "../../assets/images/logo.png";
import { setToken } from "../../helpers/api_helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { jwtDecode } from "jwt-decode";
import { loginThunk } from "../../store/asyncThunks/auth";
import { decodeToken } from "../../store/slices/auth/decodeToken";
import ErrorValidationMessage from '../../components/ErrorValidationMessage';

const Login = () => {

    document.title = `Login | ${process.env.REACT_APP_DOCUMENT_TITLE}`;

    const dispatch = useAppDispatch();
    const LoginLoader = useAppSelector((state) => state?.loginReducer?.loading);

    const navigate = useNavigate();
    const loginFormSchema = yup.object().shape({
        email: yup.string().email().required().trim(),
        password: yup.string().required().trim(),
    });

    const loginFormValues = {
        email: '',
        password: ''
    }

    const { handleSubmit, register, formState: { errors }, watch } = useForm({
        defaultValues: loginFormValues,
        resolver: yupResolver(loginFormSchema),
    })

    const navigateAfterLogin = (accessToken: any) => {
        navigate("/overview");
        setToken();
        const decoded: any = jwtDecode(accessToken);
        dispatch(decodeToken(decoded));
    };

    const onSubmit = (values: any) => {
        dispatch(loginThunk({ values, navigateAfterLogin }));
    }

    return (
        <div className='auth'>
            <div className="row auth-wrapper">
                <AuthImg loginImg={loginImg} description={`Lorem Ipsum is simply dummy text of the printing and typesetting industry.`} heading={`Welcome`} />

                <div className="col-5 auth-form p-0">
                    <div className='form-wrapper'>
                        <div className="logo-container">
                            <img src={logo} alt="logo" />
                        </div>
                        <div className="auth-header">
                            <h1 className="page-title">Sign in</h1>
                            <p className='desc'>Enter your login credential to access your account</p>
                        </div>
                        <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                            <div className="input-wrapper">
                                <Label className='required'>Email</Label>
                                <input type="text" className={`custom-input form-control ${errors.email?.message ? "error-input" : ""}`} {...register("email")} />
                                {errors.email?.message && <ErrorValidationMessage errors={errors.email} name="email" />}
                            </div>
                            <div className="input-wrapper">
                                <Label className='required'>Password</Label>
                                <PasswordInput className={`custom-input form-control ${errors.password?.message ? "error-input" : ""}`} register={register} registerName={"password"} />
                                {errors.password?.message && <ErrorValidationMessage errors={errors.password} name="password" />}
                            </div>
                            <div className="change-action text-end">
                                <NavLink to={`/forgot-password`}>Forgot password?</NavLink>
                            </div>
                            <div className="actions">
                                <PrimaryButton btnName='Sign in' loader={LoginLoader} />
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default Login