import { useState, useEffect, useRef } from 'react'
import calendar from '../assets/images/svgIcons/calendar_icon.svg';
import { Label } from "reactstrap";
import moment from 'moment';
import OutsideClick from '../constants/OutsideClick';
import { dayList } from '../constants/datas/tabsList';
import { halfMonthData, halfyearlyData, monthData, quarterlyData, weeklyData, yearlyData } from '../constants/DateFilters';


interface customdatepickerProps {
    selected: any,
    tab: any,
    onClick: Function;
}

const CustomDatePicker = (props: customdatepickerProps) => {
    const { tab, onClick, selected } = props;
 
    const [isOpen, setisOpen] = useState(false);
    const [data, setData] = useState<Array<any>>([]);

    const handleClick = () => {
        setisOpen(true)
    }
    const wrapperRef = useRef<any>();

    useEffect(() => {
        OutsideClick({ wrapperRef, setisOpen })
    }, []);

    useEffect(() => {
        const tabName = dayList[tab]?.value?.toLowerCase();

        let date: any = [];
        if (tabName === "weekly") {
            date = weeklyData()
            setData(date)
        } else if (tabName === "halfmonth") {
            date = halfMonthData()
            setData(date)
        } else if (tabName === "monthly") {
            date = monthData()
            setData(date)
        } else if (tabName === "quarterly") {
            date = quarterlyData()
            setData(date)
        } else if (tabName === "halfyearly") {
            date = halfyearlyData()
            setData(date)
        } else if (tabName === "yearly") {
            date = yearlyData()
            setData(date)
        }

        if (date.length > 0) {
            const start = date[0]?.startDate ? moment(date[0]?.startDate).format("DD/MM/YYYY") : "";
            const end = date[0]?.endDate ? moment(date[0]?.endDate).format("DD/MM/YYYY") : "";
            onClick(`${start} - ${end}`);
        }
    }, [tab]);

    return (
        <>
            <div ref={wrapperRef} className="cursor-pointer date-notification" onClick={() => {
                handleClick()
            }}>
                <div className='custom-date-picker'>
                    <div className="date-picker-wrapper">
                        <input type="text" className='date-input rmdp-input' value={selected} />
                        <Label htmlFor={""} className='calendar-icon'><img src={calendar} alt="calendar" /></Label>
                    </div>
                </div>
                <div className={`date-notification-dropdown ${isOpen ? "" : "d-none"}`} onClick={(e) => e.stopPropagation()}>
                    <div className="date-header">
                        <p className='title text-center'>CALENDER</p>
                    </div>
                    <div className="body custom-scroll">
                        {data?.map((item: any, index) => {
                            const start = item?.startDate ? moment(item?.startDate).format("DD/MM/YYYY") : "";
                            const end = item?.endDate ? moment(item?.endDate).format("DD/MM/YYYY") : "";
                            return <div onClick={() => {
                                onClick(`${start} - ${end}`);
                            }} 
                            className={`date-details ${`${start} - ${end}` === selected ? "active" : ""
                                }`}
                                key={`date-range-${index}`}>
                                <p className=''>{start} - {end}</p>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CustomDatePicker