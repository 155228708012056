import { SetStateAction, useEffect, useState } from "react";
import DropDown from "../../../components/DropDown"
import ToggleButton from "../../../components/buttons/ToggleButton";
import EmployeeSubComponent from "./subComponent/EmployeeSubComponent";
import ResponsibilitySubComponent from "./subComponent/ResponsibilitySubComponent";
import { getDepartmentThunk, getDivisionThunk } from "../../../store/asyncThunks/manage/setups";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { assignEmpThunk, getdivResponsibilityThunk } from "../../../store/asyncThunks/overView/overview";
import AssignEmployees from "../../../components/Models/AssignEmployees";
import { mappingList } from "../../../constants/datas/tabsList";
import { replaceDivResData } from "../../../store/slices/overView/getDivsionResponsibility";
import InputSearch from "../../../components/InputSearch";
import { Card } from "reactstrap";
import PageLoader from "../../../components/PageLoader";

interface employeeProps {
    currentTab: number,
    setCurrentTab: SetStateAction<any>
    currentPage: number,
    setCurrentPage: SetStateAction<any>;
}

const Employee = (props: employeeProps) => {
    const { currentTab, setCurrentTab, currentPage, setCurrentPage } = props;
    const dispatch = useAppDispatch();

    const getDivisionList = useAppSelector((state) => state?.getDivisionReducer?.data?.divisions);
    const getDivisionListLoader = useAppSelector((state) => state?.getDivisionReducer?.loading);
    const getDepartmentList = useAppSelector((state) => state?.getDepartmentReducer?.data?.departments);
    const getDepartmentListLoader = useAppSelector((state) => state?.getDepartmentReducer?.loading);
    const responsibilityData = useAppSelector((state) => state?.getDivisionResponsibilityReducer?.data?.data);
    const getEmployeesData = useAppSelector((state) => state?.getEmployeesReducer?.data?.data?.associates);
    const getallEmployeesData = useAppSelector((state) => state?.getResponsibilityEmployeesReducer?.data?.data?.associates);
    const addAssignEmployeeLoader = useAppSelector(state => state.assignEmp.loading);
    const getClassificationList = useAppSelector((state) => state?.getClassificationReducer?.data);
    const getFrequencyList = useAppSelector((state) => state?.getFrequencyReducer?.data?.frequency);
    const getEmployeesLoading = useAppSelector((state) => state?.getEmployeesReducer?.loading);
    const pageSize = process.env.REACT_APP_PAGE_SIZE || 10;

    const [resDivisionId, setResDivisionId] = useState<any>("");
    const [resDeptId, setResDeptId] = useState<any>("");
    const [onsearch, setOnsearch] = useState("");
    const [responsibilityShowData, setResponsibilityShowData] = useState([]);
    const [mapResponsibiity, setMapResponsibiity] = useState("");
    const [showModal, setshowModal] = useState(false);
    const [checkedValues, setCheckedValues] = useState<any>([]);
    const [searchValue, setSearchValue] = useState("");
    const [restInput, setResetInput] = useState(false);
    const [isAdd, setIsAdd] = useState<Boolean | null>(null);
    const [slelectedEmp, setSelectedEmp] = useState<any>([]);
    const [showemployee, setShowEmployee] = useState<any>([]);
    const [prevSelectEmp, setPrevSelectEmp] = useState<any>([])

    const [responsibilitySortBy, setResponsibilitySortBy] = useState({
        field: "created_at",
        sort: null,
        value: "descending"
    });

    const responsibility = responsibilityData?.data?.map((item: any) => {
        return {
            id: item?.dndFKId._id,
            responsibilities: item?.dndFKId.responsibilityFKId.label,
            classification: item?.dndFKId.responsibilityFKId?.classificationFKId,
            frequency: item?.dndFKId.responsibilityFKId?.frequencyFKId,
            empCount: item?.employees?.length,
            employees: item?.employees,
            scope: item?.dndFKId.responsibilityFKId?.scope,
            bgColorCode: item?.dndFKId?.responsibilityFKId?.bgColorCode,
            textColorCode: item?.dndFKId?.responsibilityFKId?.textColorCode,
        }
    })

    const getResponsibilities = () => {
        if (resDeptId && resDivisionId) {
            const data: any = {}
            data["currentPage"] = currentPage
            data["pageSize"] = pageSize
            data["data"] = { search: searchValue, department: resDeptId?._id, division: resDivisionId?._id }
            dispatch(getdivResponsibilityThunk(data))
        }
    }

    const handleSearch = (value: any) => {
        setSearchValue(value);
        setCurrentPage(1);
    }

    useEffect(() => {
        getResponsibilities()
    }, [resDeptId, resDivisionId, currentPage, searchValue])

    useEffect(() => {
        if (responsibility) {
            const filterData = responsibility?.filter((item: any) => {
                if (item.responsibilities) {

                    const { classification, frequency } = checkedValues;

                    if (!onsearch && classification && classification?.length > 0 && (!frequency || frequency && frequency?.length === 0)) {
                        if (checkedValues.classification.includes(item.classification)) {
                            return item;
                        }
                    } else if (!onsearch && (!classification || classification?.length === 0) && frequency?.length > 0) {
                        if (checkedValues?.frequency?.includes(item?.frequency)) {
                            return item;
                        }
                    } else if (!onsearch && classification?.length > 0 && frequency?.length > 0) {
                        if (checkedValues?.classification?.includes(item?.classification) && checkedValues?.frequency.includes(item?.frequency)) {
                            return item;
                        }
                    } else if (onsearch && classification && classification?.length > 0 && (!frequency || frequency && frequency?.length === 0)) {
                        if (
                            (item?.responsibilities?.toLowerCase().includes(onsearch.trim()?.toLowerCase()) ||
                                item?.employees?.some((emp: any) => emp?.name?.toLowerCase().includes(onsearch.trim()?.toLowerCase())))
                            &&
                            (checkedValues?.classification && checkedValues?.classification?.includes(item?.classification))
                        ) {
                            return item;
                        }
                    } else if (onsearch && (!classification || classification?.length === 0) && frequency?.length > 0) {
                        if (
                            (item.responsibilities.toLowerCase().includes(onsearch.trim().toLowerCase()) ||
                                item?.employees?.some((emp: any) => emp.name.toLowerCase().includes(onsearch.trim().toLowerCase())))
                            &&
                            (checkedValues?.frequency && checkedValues?.frequency?.includes(item?.frequency))
                        ) {
                            return item;
                        }
                    } else if (onsearch && classification?.length > 0 && frequency?.length > 0) {
                        if (
                            (item?.responsibilities?.toLowerCase()?.includes(onsearch?.trim()?.toLowerCase()) ||
                                item?.employees?.some((emp: any) => emp?.name?.toLowerCase()?.includes(onsearch.trim()?.toLowerCase())))
                            &&
                            (checkedValues?.classification && checkedValues?.classification?.includes(item?.classification))
                            &&
                            (checkedValues?.frequency && checkedValues?.frequency?.includes(item?.frequency))
                        ) {
                            return item;
                        }
                    } else if (onsearch && (!classification || classification?.length === 0) && (!frequency || frequency && frequency?.length === 0)) {
                        if (
                            (item?.responsibilities?.toLowerCase().includes(onsearch?.trim()?.toLowerCase()) ||
                                item?.employees?.some((emp: any) => emp?.name?.toLowerCase()?.includes(onsearch?.trim()?.toLowerCase())))
                        ) {
                            return item;
                        }
                    }
                    else {
                        return item;
                    }

                }
            }).sort((a: any, b: any) => {
                if (responsibilitySortBy.sort === true || responsibilitySortBy.sort === false) {
                    let sortOrder = responsibilitySortBy.value === "ascending" ? 1 : -1;
                    let aValue = a[responsibilitySortBy?.field?.toLowerCase()] || a[responsibilitySortBy.field] || Number(a[responsibilitySortBy.field]);
                    let bValue = b[responsibilitySortBy?.field?.toLowerCase()] || b[responsibilitySortBy.field] || Number(b[responsibilitySortBy.field]);
                    if (aValue < bValue) {
                        return -1 * sortOrder;
                    }
                    if (aValue > bValue) {
                        return 1 * sortOrder;
                    }
                    return 0;
                }
            });
            setResponsibilityShowData(filterData)
        }
    }, [responsibilityData, onsearch, responsibilitySortBy.sort, checkedValues])

    const handleChange = ({ data, key }: any) => {
        if (currentTab === 0) {
            // TODO FOR EMPLOYEES   
            // if (key === "division") setEmpDivisionId(data?._id)
            // if (key === "department") setEmpDeptId(data?._id)
        }
        else if (currentTab === 1) {
            if (key === "division") { setResDivisionId(data); setSearchValue(""); setResetInput(true); }
            if (key === "department") { setResDeptId(data); setSearchValue(""); setResetInput(true) }
        }
    }

    const assignAssicate = (id: any) => {
        if (!slelectedEmp.includes(id)) {
            setSelectedEmp([...slelectedEmp, id]);
        } else {
            setSelectedEmp(slelectedEmp.filter((empId: string) => empId !== id));
        }

    }

    const getSelectedEmp = (data: any) => {
        setIsAdd(data.add)
        setPrevSelectEmp(data.selectedEmp);
    }

    const toggleModal = () => {
        setshowModal(!showModal);
        setPrevSelectEmp([])
        setMapResponsibiity("")
        setIsAdd(null)
        setSelectedEmp([])
    };

    const mappingSuccess = (data: any) => {
        dispatch(replaceDivResData(data))
        toggleModal()
    }

    const assignEmp = () => {
        if (isAdd === true || isAdd === false) {
            const payload = { associateFKId: slelectedEmp, dndFKId: mapResponsibiity, isAdd };
            dispatch(assignEmpThunk({ payload, mappingSuccess }));
        }
    }

    useEffect(() => {
        setOnsearch("");
        setSearchValue("")
        setResDivisionId("");
        setResDeptId("");
        setCheckedValues([]);
        setCurrentPage(1)
    }, [currentTab]);

    //for setting initial dropdown values
    useEffect(() => {
        if (getDivisionList?.length > 0 && !resDivisionId) {
            if (handleChange) handleChange({ data: getDivisionList[0], key: "division" })
        }
        if (getDepartmentList?.length > 0 && !resDeptId) {
            if (handleChange) handleChange({ data: getDepartmentList[0], key: "department" })
        }
    }, [getDivisionList, getDepartmentList])
    //for dispatching div and dept for dropdowns
    useEffect(() => {
        const data: any = {};
        data['status'] = "ACTIVE";
        const payload: any = { data: data }
        dispatch(getDivisionThunk(payload));
        dispatch(getDepartmentThunk(payload));
    }, [currentTab]);

    const handledivisionSearch = (value: any) => {
        setSearchValue(value);
        setCurrentPage(1);
    }

    const getEmployeeList = () => {
        return getallEmployeesData?.filter((item: any) => {
            if (isAdd === true) {
                return (item.status.toLocaleLowerCase() === "active" && !prevSelectEmp.includes(item._id))
            } else if (isAdd === false) {
                return (item.status.toLocaleLowerCase() === "active" && prevSelectEmp.includes(item._id))
            }
        })
    }

    useEffect(() => {
        // stop render while isAdd is null scnario
        if (isAdd === true || isAdd === false) {
            setShowEmployee(getEmployeeList())
        }
    }, [isAdd])

    return (
        <div className="employee-main">
            <div className="actions-group">
                <div className="dropdown-group">
                    {/* search employees */}
                    <div className='table-search'>
                        {currentTab === 0 && <InputSearch onSearch={handleSearch} value={searchValue} />}
                    </div>
                    {/* TODO FOR FUTURE UPDATES */}
                    {currentTab === 1 &&
                        <>
                            <DropDown loading={getDivisionListLoader} onChange={(data: any) => { if (handleChange) { handleChange({ data, key: "division" }) } }} options={getDivisionList} mainClass="filter-dropdown" placeholder="Division" value={resDivisionId} />
                            <DropDown loading={getDepartmentListLoader} onChange={(data: any) => { if (handleChange) { handleChange({ data, key: "department" }) } }} options={getDepartmentList} mainClass="filter-dropdown" placeholder="Department" value={resDeptId} />
                        </>
                    }
                    {/* TODO FOR FUTURE UPDATES */}
                    {/* <DropDown options={designationOptions} mainClass="filter-dropdown" placeholder="Designation" /> */}
                </div>
                <ToggleButton currentTab={currentTab} setCurrentTab={setCurrentTab} list={mappingList} />
            </div>
            {currentTab === 0 && (
                <div className="employee-loader-position">
                    {getEmployeesLoading && getEmployeesData &&
                        <PageLoader />
                    }
                    <EmployeeSubComponent searchValue={searchValue} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                </div>
            )}
            {currentTab === 1 && <ResponsibilitySubComponent
                divsonId={resDivisionId}
                deptId={resDeptId}
                onSearch={handledivisionSearch}
                searchVal={searchValue}
                showData={responsibilityShowData}
                selectDivison={setMapResponsibiity}
                alreadySelected={getSelectedEmp}
                popup={setshowModal}
                getResponsibilities={getResponsibilities}
                sortBy={responsibilitySortBy}
                setSortBy={setResponsibilitySortBy}
                filterValue={checkedValues}
                setFilterValue={setCheckedValues}
                lists={[{ classification: getClassificationList?.classification }, { frequency: getFrequencyList }]}
                getDivisionListLoader={getDivisionListLoader}
                getDepartmentListLoader={getDepartmentListLoader}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                restInput={restInput}
            />}

            {showModal &&
                <AssignEmployees
                    data={showemployee}
                    showModal={showModal}
                    toggle={toggleModal}
                    modalTitle={isAdd ? "Assign Employees" : "Remove Employees"}
                    onSelect={assignAssicate}
                    onSubmit={assignEmp}
                    selected={slelectedEmp}
                    loader={addAssignEmployeeLoader}
                />
            }
        </div>
    )
}

export default Employee