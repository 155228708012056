import React, { useEffect } from 'react'
import { Label, Modal } from 'reactstrap'
import closeIcon from '../../assets/images/svgIcons/close_icon.svg';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ErrorValidationMessage from '../ErrorValidationMessage';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { changeStatusResponsibilityThunk } from '../../store/asyncThunks/manage/mapping';
import { replaceReporteesById } from '../../store/slices/overView/getReporteesByIdSlice';
interface RejectReasonProps {
    showModel: boolean
    toggleModel: () => void
    associateFKId?: string,
    id: Number,
    getreportieDetails?: Function
}
export default function RejectResason(props: RejectReasonProps) {
    const { showModel, toggleModel, associateFKId, id, getreportieDetails } = props;

    const statusDataLoader = useAppSelector((state: any) => state?.statusResponsibilityReducer?.loading);

    const dispatch = useAppDispatch();

    const maxCharacters = 60;
    const rejectionFormSchema = yup.object().shape({
        description: yup.string().required("").test(
            'maxLength',
            `-`,
            (value) => {
                if (value && value.length > maxCharacters) {
                    return false;
                }
                return true;
            }
        ),
    });

    const formValues = {
        description: ""
    }

    const { handleSubmit, register, formState: { errors }, setValue, clearErrors, reset } = useForm({
        resolver: yupResolver(rejectionFormSchema),
        defaultValues: formValues
    })

    const afterSuccess = (data?: any) => {
        dispatch(replaceReporteesById(data));
        closeModel()
    }

    const onSubmit = (values: any) => {
        const payload = {
            data: {
                status: "Reject",
                description: values.description,
                associateFKId: associateFKId
            },
            id: id,
            afterSuccess: afterSuccess
        };
        dispatch(changeStatusResponsibilityThunk(payload));
    };

    const closeModel = () => {
        toggleModel()
        clearErrors()
        reset()
    }

    return (
        <Modal size="md" isOpen={showModel} centered={true}
            toggle={closeModel}
        >
            <div className="rejectReasion-model-header">
                <h1 className='title'>Reject</h1>
                {/* <span aria-hidden="true" className='close-icon'><img src={closeIcon} alt="close" onClick={toggleModel} /></span> */}
            </div>
            <div className="rejectReasion-model-body">
                <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                    <label className='reject-title'>Reason for Rejection</label>
                    <div>
                        <textarea className='rejectReason text-area '  {...register("description")} />
                        {errors?.description?.message && <ErrorValidationMessage errors={errors.description} sizeMax={maxCharacters} name="Reason" />}
                    </div>

                    <div className="rejectReasion-model-footer">
                        <PrimaryButton btnName='Submit' btnType='submit' loader={statusDataLoader} disabled={(statusDataLoader) ? true : false} />
                        <SecondaryButton btnName='Cancel' btnType='button' onClick={closeModel} disabled={(statusDataLoader) ? true : false} />
                    </div>
                </form>
            </div>

        </Modal>
    )
}
