export const descriptionLimit = 250;

export const passwordLimit = 8;

export const phoneNumberLimit = 15;

export const ResponsibilityNameLimit = 100;

export const nameLimit = 60;

export const pageSize = 10;

