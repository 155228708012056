import { createAsyncThunk } from "@reduxjs/toolkit";
import { addClassification, addDepartment, addDivision, addFrequency, addResponsibility, addRole, changestatusClassification, changestatusDepartment, changestatusDivision, changestatusFrequency, changestatusResponsibility, changestatusRole, getDepartment, getDivision, getResponsibility, getRole, getclassification, getfrequency, updateClassification, updateDepartment, updateDivision, updateFrequency, updateRole, updateresponsibility } from "../../../helpers/backend_helper";
import toast from "react-hot-toast";

interface ManageProps {
    data: any,
    id: number,
    afterSuccess: Function,
}

interface ManageGetProps {
    currentPage?: number,
    pageSize?: number,
    data?: {
        search?: string,
        status?: string,
    }
}

interface GetByIDProps {
    id: number,
    data: any,
}

//manage Role
export const manageRoleThunk = createAsyncThunk(
    "manageRole/manageRoleThunk",
    async (props: ManageProps) => {
        try {
            const { data, id, afterSuccess } = props;
            let response;
            if (id) {
                response = await updateRole(id, data);
            } else {
                response = await addRole(data);
            }
            if (response?.status?.toLowerCase() === "success") {
                afterSuccess(response?.data);
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

//change status role
export const changestatusRoleThunk = createAsyncThunk(
    "chnagestatusRole/chnagestatusRoleThunk",
    async (props: ManageProps) => {
        try {
            const { data, id, afterSuccess } = props;
            const response = await changestatusRole(id, data);
            if (response.status?.toLowerCase() === "success") {
                afterSuccess(response?.data);
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

//manage Department
export const manageDepartmentThunk = createAsyncThunk(
    "manageDepartment/manageDepartmentThunk",
    async (props: ManageProps) => {
        try {
            const { data, id, afterSuccess } = props;
            let response;
            if (id) {
                response = await updateDepartment(id, data);
            } else {
                response = await addDepartment(data);
            }
            if (response.status?.toLowerCase() === "success") {
                afterSuccess(response?.data);
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

//change status Department
export const changestatusDepartmentThunk = createAsyncThunk(
    "changeStatusDepartment/changestatusDepartmentThunk",
    async (props: ManageProps) => {
        try {
            const { data, id, afterSuccess } = props;
            const response = await changestatusDepartment(id, data);
            if (response.status?.toLowerCase() === "success") {
                afterSuccess(response?.data);
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

//manage Division
export const manageDivisionThunk = createAsyncThunk(
    "manageDivision/manageDivisionThunk",
    async (props: ManageProps) => {
        try {
            const { data, id, afterSuccess } = props;
            let response;
            if (id) {
                response = await updateDivision(id, data);
            } else {
                response = await addDivision(data);
            }
            if (response.status?.toLowerCase() === "success") {
                toast.success(response?.message);
                afterSuccess(response?.data);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

//change status Division
export const changestatusDivisionThunk = createAsyncThunk(
    "changestatusDivision/changestatusDivisionThunk",
    async (props: ManageProps) => {
        try {
            const { data, id, afterSuccess } = props;
            const response = await changestatusDivision(id, data);
            if (response.status?.toLowerCase() === "success") {
                afterSuccess(response?.data);
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

//Manage Classification
export const manageClassificationThunk = createAsyncThunk(
    "manageClassification/manageClassificationThunk",
    async (props: ManageProps) => {
        try {
            const { data, id, afterSuccess } = props;
            let response;
            if (id) {
                response = await updateClassification(id, data);
            } else {
                response = await addClassification(data);
            }
            if (response.status?.toLowerCase() === "success") {
                afterSuccess(response?.data);
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

//change status Classification
export const changestatusClassificationThunk = createAsyncThunk(
    "changestatusClassification/changestatusClassificationThunk",
    async (props: ManageProps) => {
        try {
            const { data, id, afterSuccess } = props;
            const response = await changestatusClassification(id, data);
            if (response.status?.toLowerCase() === "success") {
                afterSuccess(response?.data);
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

//Manage Frequency
export const manageFrequencyThunk = createAsyncThunk(
    "manageFrequency/manageFrequencyThunk",
    async (props: ManageProps) => {
        try {
            const { data, id, afterSuccess } = props;
            let response;
            if (id) {

                response = await updateFrequency(id, data);
            } else {
                response = await addFrequency(data);
            }
            if (response.status?.toLowerCase() === "success") {
                toast.success(response?.message);
                afterSuccess(response?.data);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

//change status Frequency
export const changestatusFrequencyThunk = createAsyncThunk(
    "changestatusFrequency/changestatusFrequencyThunk",
    async (props: ManageProps) => {
        try {
            const { data, id, afterSuccess } = props;
            const response = await changestatusFrequency(id, data);
            if (response.status?.toLowerCase() === "success") {
                afterSuccess(response?.data);
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);


//manage Responsibility
export const manageResponsibilityThunk = createAsyncThunk(
    "manageResponsibility/manageResponsibilityThunk",
    async (props: ManageProps) => {
        try {
            const { data, id, afterSuccess } = props;
            let response;
            if (id) {
                response = await updateresponsibility(id, data);
            } else {
                response = await addResponsibility(data);
            }
            if (response.status?.toLowerCase() === "success") {
                toast.success(response?.message);
                afterSuccess(response?.data);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

//Chnage status Resposibility
export const changestatusResponsibilityThunk = createAsyncThunk(
    "changestatusResponsibility/changestatusResponsibilityThunk",
    async (props: ManageProps) => {
        try {
            const { data, id, afterSuccess } = props;
            const response = await changestatusResponsibility(id, data);
            if (response.status?.toLowerCase() === "success") {
                afterSuccess(response?.data);
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

//get Roles
export const getRoleThunk = createAsyncThunk(
    "getRole/getRoleThunk",
    async (props: ManageGetProps) => {
        try {
            const { currentPage = 0, pageSize = 0, data } = props;
            const response = await getRole(data, currentPage, pageSize);
            return response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);
//get  department
export const getDepartmentThunk = createAsyncThunk(
    "getDepartment/getDepartmentThunk",
    async (props: ManageGetProps) => {
        try {
            const { currentPage = 0, pageSize = 0, data = {} } = props;
            const response = await getDepartment(data, currentPage, pageSize);
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

//get  division
export const getDivisionThunk = createAsyncThunk(
    "getDivision/getDivisionThunk",
    async (props: ManageGetProps) => {
        try {
            const { currentPage = 0, pageSize = 0, data = {} } = props;
            const response = await getDivision(data, currentPage, pageSize);
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

//get  claasification
export const getClassificationThunk = createAsyncThunk(
    "getClassification/getClassificationThunk",
    async (props: ManageGetProps) => {
        try {
            const { currentPage = 0, pageSize = 0, data } = props;
            const response = await getclassification(data, currentPage, pageSize);
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

//get  frequency
export const getFrequencyThunk = createAsyncThunk(
    "getFrequency/getFrequencyThunk",
    async (props: ManageGetProps) => {
        try {
            const { currentPage = 0, pageSize = 0, data } = props;
            const response = await getfrequency(data, currentPage, pageSize);
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

//get responsibility
export const getResponsibilityThunk = createAsyncThunk(
    "getResposibility/getResposibilityThunk",
    async (props: ManageGetProps) => {
        try {
            const { currentPage = 0, pageSize = 0, data } = props;
            const response = await getResponsibility(data, currentPage, pageSize);
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

//get role by ID

// export const getRoleBYIDThunk = createAsyncThunk(
//     "getRoleBYID/getRoleBYIDThunk",
//     async (props: GetByIDProps) => {
//         try {
//             const { data } = props;
//             const response = await getRoleById(data);
//             return await response;
//         } catch (error: any) {
//             return await error?.response?.data;
//         }
//     }
// );

// export const getDepartmentBYIDThunk = createAsyncThunk(
//     "getDepartmentBYID/getDepartmentBYIDThunk",
//     async (props: GetByIDProps) => {
//         try {
//             const { data } = props;
//             const response = await getDepartmentById(data);
//             return await response;
//         } catch (error: any) {
//             return await error?.response?.data;
//         }
//     }
// );