import React, { useEffect, useState } from 'react'
import PageTitle from '../../components/PageTitle'
import downloadImg from '../../assets/images/svgIcons/download.svg'
import { useNavigate, useParams } from 'react-router-dom';
import avatar from '../../assets/images/svgImages/avatar.svg';
import emailIcon from '../../assets/images/svgIcons/email.svg';
import userIcon from '../../assets/images/svgIcons/user.svg';
import phoneIcon from '../../assets/images/svgIcons/phone.svg';
import MyResponsibility from './overViewComponent/MyResponsibility';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { getReporteesById, getReporteesThunk } from '../../store/asyncThunks/overView/reportees';
import { dayList } from '../../constants/datas/tabsList';
import { clearReporteesById } from '../../store/slices/overView/getReporteesByIdSlice';
import ReportyDetailsSkeleton from '../../components/skeleton/ReportyDetailsSkeleton';

export default function ReporteesDetails() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const id = useParams()

    const getReporteesInfoData = useAppSelector((state) => state?.getReporteesById?.data?.data);
    const getReporteesLoading = useAppSelector((state) => state?.getReporteesById?.loading);
    const getReporteesData = useAppSelector((state) => state?.reporteesDataReducer?.data?.data);
    const getReporteesDataLoading = useAppSelector((state) => state?.reporteesDataReducer?.loading);
    const getReporteesInfo = getReporteesInfoData?.responsibility && getReporteesInfoData?.responsibility?.length > 0 && getReporteesInfoData?.responsibility?.map((item: any) => {
        return {
            id: item?.dndFKId,
            label: item?.label,
            duration: item?.duration,
            classification: item?.classificationFKId,
            frequency: item?.frequencyFKId,
            scope: item?.scope,
            status: item?.status,
            mapResId: item?.mapResId,
            description: item?.description,
            bgColorCode:item?.bgColorCode,
            textColorCode:item?.textColorCode
        }
    })

    const [divRes, setDivRes] = useState<any>("")
    const [divResDept, setDivResDept] = useState<any>("");
    const [search, setSearch] = useState("")
    const [restInput, setResetInput] = useState(false); 
    const [currentSubTab, setCurrentSubTab] = useState(0);
    const [onsearch, setOnsearch] = useState("")
    const [showData, setShowData] = useState([])
    const [showModel, setShowModel] = useState(false)
    const [selected, setSelected] = useState<any>();
    const [dateFilter, setDateFilter] = useState({ startDate: "", endDate: "" });
    const [resetdata, setResetdata] = useState<Boolean>(false);


    const [myresponsibilitySortBy, setMyesponsibilitySortBy] = useState({
        field: "created_at",
        sort: null,
        value: "descending"
    });

    const onSearch = (e: string) => {
        setSearch(e)
    }

    useEffect(() => {
        const data: any = { userId: id?.id }

        dispatch(getReporteesThunk(data))
    }, [id])

    const toggle = () => {
        setShowModel(false)
    }

    useEffect(()=>{
        if(resetdata === true){
            getreportieDetails();
          setResetdata(false);
        }
      },[resetdata]);

    const getreportieDetails = () => {
        const data: any = {
            userId: id?.id,
            search: search,
        }

        if (id && divRes && divResDept) {
            const presentDate = `${new Date().getFullYear()}-${(new Date().getMonth() + 1) < 10 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1}-${(new Date().getDate()) < 10 ? `0${new Date().getDate()}` : new Date().getDate()}`;
            const newDateFilter: any = {}
            newDateFilter["startDate"] = dateFilter?.startDate ? dateFilter?.startDate : presentDate;
            newDateFilter["endDate"] = dateFilter?.endDate ? dateFilter?.endDate : presentDate;
            data["division"] = divRes?._id;
            data["department"] = divResDept?._id;
            data['dateFilter'] = newDateFilter;

            dispatch(getReporteesById(data));
        }
    }

    useEffect(() => {
        getreportieDetails()
    }, [id, divRes, divResDept, search, selected])
    
    useEffect(() => {
        if (getReporteesInfo) {
            const filterData = getReporteesInfo?.filter((item: any) => {
                if (item?.frequency?.toLocaleLowerCase() === dayList[currentSubTab]?.label?.toLocaleLowerCase()) {
                    if (onsearch) {
                        if (
                            item?.label?.toLowerCase()?.includes(onsearch?.trim()?.toLowerCase()) ||
                            item?.duration?.toLowerCase()?.includes(onsearch?.trim()?.toLowerCase()) ||
                            item?.classification?.toLowerCase()?.includes(onsearch?.trim()?.toLowerCase()) ||
                            item?.scope?.toLowerCase()?.includes(onsearch?.trim()?.toLowerCase())
                        ) {
                            return item
                        }
                        else {
                            return false
                        }
                    }
                    else return item
                }
            }).sort((a: any, b: any) => {
                if (myresponsibilitySortBy.sort === true || myresponsibilitySortBy.sort === false) {
                    let sortOrder = myresponsibilitySortBy.value === "ascending" ? 1 : -1;
                    let aValue = a[myresponsibilitySortBy.field.toLowerCase()] || a[myresponsibilitySortBy.field] || Number(a[myresponsibilitySortBy.field]);
                    let bValue = b[myresponsibilitySortBy.field.toLowerCase()] || b[myresponsibilitySortBy.field] || Number(b[myresponsibilitySortBy.field]);
                    if (aValue < bValue) {
                        return -1 * sortOrder;
                    }
                    if (aValue > bValue) {
                        return 1 * sortOrder;
                    }
                    return 0;
                }
            });
            setShowData(filterData)
        } else {
            setShowData([])
        }
        // FOR AVOIDING LOADING STATE

    }, [getReporteesInfoData, currentSubTab, onsearch, myresponsibilitySortBy.sort])

    const handleChange = ({ data, key }: any) => {
        if (key === "myResDiv") {
            setDivRes(data)
            setOnsearch("")
            setResetInput(true);
        }
        if (key === "myResDept") {
            setDivResDept(data)
            setOnsearch("")
            setResetInput(true);
        }
    }


    const goBack = () => {
        const screen = {
            reportee: 2
        }
        navigate('/overview', { state: screen })
        dispatch(clearReporteesById())
    }
    const dateChange = (date: string | undefined) => {
        if (date) {
          const [startDateString, endDateString] = date?.split(' - ');
          const formattedStartDate = startDateString?.split('/')?.reverse()?.join('-');
          const formattedEndDate = endDateString?.split('/')?.reverse()?.join('-');
          const dateObject = {
            startDate: formattedStartDate,
            endDate: formattedEndDate
          };
          setDateFilter(dateObject)
          setSelected(date)
        } else {
            setDateFilter({
              startDate: "",
              endDate: ""
            })
            setSelected("")
          }
      }

    return (
        <div className='overView mapping-list'>
            <PageTitle title={`Overview`} />
            <div className="userDetails">
                <div className="goBack" onClick={goBack}>
                    <img className='backArrow' src={downloadImg} alt="back" />
                    <p>Back to reportees</p>
                </div>
                {!getReporteesDataLoading ?
                    <div className="userInfo">
                        <div className='cell'>
                            <div className='userDetail'>
                                {/* <img className='userImg' src={getReporteesData?.profilePath ? getReporteesData?.profilePath : avatar} alt="user Image" /> */}
                                <p className='profile-circle-md '>{getReporteesData?.name?.charAt(0).toUpperCase()}</p>
                            </div>
                            <div>
                                <p className='userName'>{getReporteesData?.name}</p>
                                <p className='userRole'>{getReporteesData?.designation}</p>
                            </div>
                        </div>
                        <div className='cell'>
                            <div className='icon-wrapper'>
                                <img className='infoIcon' src={userIcon} alt="user Image" />
                            </div>
                            <div>
                                <p className='label'>Employee ID</p>
                                <p className='labelValue'>{getReporteesData?.employeeId}</p>
                            </div>
                        </div>
                        <div className='cell'>
                            <div className='icon-wrapper'>
                                <img className='infoIcon' src={emailIcon} alt="email" />
                            </div>
                            <div>
                                <p className='label'>Email</p>
                                <p className='labelValue'>{getReporteesData?.email}</p>
                            </div>
                        </div>
                        <div className='cell'>
                            <div className='icon-wrapper'>
                                <img className='infoIcon' src={phoneIcon} alt="phone" />
                            </div>
                            <div>
                                <p className='label'>Mobile</p>
                                <p className='labelValue'>{getReporteesData?.phoneNumber}</p>
                            </div>
                        </div>
                    </div>
                    :
                    <ReportyDetailsSkeleton />
                }

                <MyResponsibility
                    currentSubTab={currentSubTab}
                    context="reporteeDetails"
                    getReporteesData={getReporteesData?._id ? getReporteesData._id : null}
                    setCurrentSubTab={setCurrentSubTab}
                    showData={showData}
                    deptId={divResDept}
                    divsonId={divRes}
                    handleChange={handleChange}
                    onSearch={setOnsearch}
                    searchVal={onsearch}
                    loading={getReporteesLoading}
                    sortBy={myresponsibilitySortBy}
                    setSortBy={setMyesponsibilitySortBy}
                    showfilter={false}
                    getreportieDetails={getreportieDetails}
                    dateChange={dateChange}
                    selected={selected}
                    setResetdata={setResetdata}
                    restInput={restInput}
                />
            </div>
        </div>
    )
}
