import { Fragment } from "react";
// import Skeleton from "react-loading-skeleton";

interface tablebodyProps {
    getTableBodyProps: any,
    page: Array<any>,
    prepareRow: any,
    visibleColumns: any,
    renderRowSubComponent: any,
    isExpanded?: boolean,
}

const TableBodySection = (props: tablebodyProps) => {
    const { getTableBodyProps, page, prepareRow, visibleColumns, renderRowSubComponent, isExpanded } = props;

    return (
        <tbody {...getTableBodyProps()}>
            {page?.map((row: any) => {
                prepareRow(row);
                let props: any;
                if (isExpanded) {
                    props = { ...row.getToggleRowExpandedProps() }
                }
                return (
                    <Fragment key={row.getRowProps().key}>
                        <tr {...props} className={`${row.isExpanded ? "opened" : "closed"} ${isExpanded ? "is-expanded" : ""}`}>
                            {row.cells.map((cell: any, idx: number) => {
                                return (
                                    <td
                                        className={`ps-0 ${idx === 0 ? "arrow-icon" : ""
                                            } ${isExpanded ? "expanded-row" : ""}`}
                                        key={idx}
                                        {...cell.getCellProps()}
                                    >
                                        {/* {cell.render("Cell") || <Skeleton height={"25px"} />} */}
                                        <div className="cell-data">
                                            {cell.render("Cell") || ""}
                                        </div>
                                    </td>
                                );
                            })}
                        </tr>
                        {isExpanded && (
                            <tr className={`sub-row`}>
                                <td className={`sub-row-details ${row.isExpanded ? 'expanded' : ''}`} colSpan={visibleColumns.length}>
                                    <div className={`cell-data ${row.isExpanded ? 'open' : 'close'}`}>
                                        {renderRowSubComponent(row)}
                                    </div>
                                </td>
                            </tr>
                        )}
                    </Fragment>
                );
            })}
        </tbody>
    )
}

export default TableBodySection