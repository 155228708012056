
import { Label, Modal } from 'reactstrap';
import closeIcon from '../../assets/images/svgIcons/close_icon.svg';
import { ChangeEvent, SetStateAction, useEffect, useState } from 'react';
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';
import Datepicker from '../Datepicker';
import Fileupload from '../Fileupload';
import { useForm } from "react-hook-form"
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import DropDown from '../DropDown';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { addEmployeeThunk, getEmployeesThunk } from '../../store/asyncThunks/manage/mapping';
import { designationOptions } from '../../constants/datas/dropdownOptions';
import { getRoleThunk } from '../../store/asyncThunks/manage/setups';
import ErrorValidationMessage from '../ErrorValidationMessage';
import { nameLimit } from '../../constants/HandleFormLimit';

interface AddNewEmployeeProps {
    showModal: boolean,
    toggle: any,
    modalTitle: string
    setCurrentPage: SetStateAction<any>;
    currentPage: number
}
interface Reporting {
    label: string;
    value: string;
}
const AddEmployeeModel = (props: AddNewEmployeeProps) => {
    const { showModal, toggle, modalTitle, setCurrentPage, currentPage } = props;

    const dispatch = useAppDispatch();

    const maxFileSize = 250;
    const defaultProfile = { path: "", name: "" };

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [profile, setProfile] = useState(defaultProfile);
    const [designationValue, setDesignationValue] = useState({ label: 'Select designation', value: "" });
    const [reporting, setReporting] = useState<Array<Reporting>>([{ label: 'Select reporting', value: "" }])
    const [reportingValue, setReportingValue] = useState({ label: 'Select reporting', value: "" })
    const getEmployeesData = useAppSelector((state) => state?.getEmployeesReducer?.data?.data?.associates);
    const getallEmployeesData = useAppSelector((state) => state?.getResponsibilityEmployeesReducer?.data?.data?.associates);
    const getEmployeesDataLoader = useAppSelector((state) => state?.getEmployeesReducer?.loading);
    const getRoleList = useAppSelector((state) => state?.getRoleReducer?.data?.role);
    const getRoleListLoader = useAppSelector((state) => state?.getRoleReducer.loading);
    const addEmployeeLoader = useAppSelector((state) => state?.addEmployeeReducer?.loading);
    const pageSize = process.env.REACT_APP_PAGE_SIZE || 12;
    const [formSubmitted, setFormSubmitted] = useState(false);


    const addEmployeeFormSchema = yup.object().shape({
        name: yup.string().trim().required().test("maxLength", (val) => {
            if (!val) return false;
            return val.length <= nameLimit;
        }),
        employeeId: yup.string().required().trim(),
        email: yup.string().email().required().trim(),
        phoneNumber: yup.string().required().test("maxLength", '-', val => {
            if (!val) return false;
            return val.length < 15;
        }),
        designation: yup.string().test("select", '-', val => {
            if (!val) return false;
            else return true
        }),
        dob: yup.string().required().trim(),
        profile: yup.mixed().required().test('custom', `File size should be less than ${maxFileSize}kb`, (value: any) => {
            if (value && (value.size / 1024) > maxFileSize) {
                return false;
            } else {
                return true;
            }
        }),
        reporteeFKId: yup.string().test("select", '-', val => {
            if (!val) return false;
            else return true
        }),
    });

    const formValues = {
        name: '',
        employeeId: '',
        email: '',
        phoneNumber: '',
        designation: '',
        profile: {},
        dob: '',
        reporteeFKId: '',
    }

    const { handleSubmit, register, reset, formState: { errors }, setValue, clearErrors } = useForm({
        defaultValues: formValues,
        resolver: yupResolver(addEmployeeFormSchema),
    })

    const datasubmited = async () => {
        if (currentPage !== 1) {
            setCurrentPage(1);
        } else {
            const data: any = {};
            data['afterSuccess'] = resetAddNewForm;
            data["currentPage"] = 1
            data["pageSize"] = pageSize
            await dispatch(getEmployeesThunk(data));
        }
    }

    const onSubmit = (values: any) => {
        const data: any = {}
        data['data'] = values;
        data['afterSuccess'] = toggleModel;
        data['datasubmited'] = datasubmited;
        dispatch(addEmployeeThunk(data));
    }

    const handleFileChange = (event: ChangeEvent<any>) => {
        if (event) {
            const file = event.target.files[0];
            setValue('profile', file);
            if (file) {
                setProfile({ path: URL.createObjectURL(file), name: file.name });
            }
        }
    };

    const handleDateChange = (date: Date) => {
        setStartDate(date)
        setValue('dob', `${date}`)
        clearErrors('dob')
    }

    const toggleModel = () => {

        toggle();
        resetAddNewForm();
        setProfile(defaultProfile);
    }

    const handleDesignationChange = (value: any) => {
        setDesignationValue(value);
        setValue('designation', value?._id);
        clearErrors('designation');
    }

    //Reporting DropDown
    const reportingDrop = (value: any) => {
        setReportingValue(value);
        setValue('reporteeFKId', value?._id);
        clearErrors('reporteeFKId');
    }

    useEffect(() => {
        const reportToOptions = getallEmployeesData?.length > 0 && getallEmployeesData?.map((item: any) => {
            return { label: item?.name, value: item?._id, ...item }
        });
        setReporting(reportToOptions)
    }, [getallEmployeesData]);

    //Designation
    const filterRole = getRoleList?.filter((item: any) => {
        return item.status === "ACTIVE";
    });

    useEffect(() => {
        const data: any = {}
        dispatch(getRoleThunk(data));
    }, []);

    function resetAddNewForm() {
        reset();
        setDesignationValue({ label: 'Select reporting', value: "" });
        setReportingValue({ label: 'Select reporting', value: "" });
        setStartDate(null);
    }

    return (
        <Modal size="lg" isOpen={showModal} centered={true}
            className='add-employee-model'
            toggle={toggleModel}
        >
            <div className="modal-header">
                <h1 className='title'>{modalTitle}</h1>
                <span onClick={toggleModel} aria-hidden="true" className='close-icon'><img src={closeIcon} alt="close" /></span>
            </div>
            <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-body">
                    <div className="groups">
                        <div className='group-item'>
                            <Label className='required'>Name</Label>
                            <input type="text" className={`custom-input form-control ${errors.name?.message ? "error-input" : ""}`} {...register("name")} />
                            {errors.name?.message && <ErrorValidationMessage errors={errors.name} name="name" sizeMax={nameLimit} />}
                        </div>
                        <div className='group-item'>
                            <Label className='required'>Employee ID</Label>
                            <input type="text" className={`custom-input form-control ${errors.employeeId?.message ? "error-input" : ""}`} {...register("employeeId")} />
                            {errors.employeeId?.message && <ErrorValidationMessage errors={errors.employeeId} name="employee Id" />}
                        </div>
                    </div>
                    <div className="groups">
                        <div className='group-item'>
                            <Label className='required'>Email</Label>
                            <input type="text" className={`custom-input form-control ${errors.email?.message ? "error-input" : ""}`} {...register("email")} />
                            {errors.email?.message && <ErrorValidationMessage errors={errors.email} name="email" />}
                        </div>
                        <div className='group-item'>
                            <Label className='required'>Phone number</Label>
                            <input type="number" className={`custom-input form-control ${errors.phoneNumber?.message ? "error-input" : ""}`} {...register("phoneNumber")} />
                            {errors.phoneNumber?.message && <ErrorValidationMessage errors={errors.phoneNumber} name="phone number" sizeMax={15} />}
                        </div>
                    </div>
                    <div className="groups">
                        <div className='group-item'>
                            <Label className='required'>Designation</Label>
                            <DropDown loading={getRoleListLoader} classNamePrefix={errors.designation?.message ? "error-input " : ""} options={filterRole} value={designationValue} onChange={handleDesignationChange} />
                            {errors.designation?.message && <ErrorValidationMessage errors={errors.designation} name="designation" />}
                        </div>
                        <div className='group-item'>
                            <Label className='required'>DOB</Label>
                            <Datepicker selected={startDate} onChange={handleDateChange} className={errors.dob?.message ? "error-input" : ""} />
                            {errors.dob?.message && <ErrorValidationMessage errors={errors.dob} name="date of birth" />}
                        </div>
                    </div>
                    <div className="groups">
                        <div className='group-item'>
                            <Label className='required'>Reporting to</Label>
                            <DropDown loading={getEmployeesDataLoader} classNamePrefix={errors.reporteeFKId?.message ? "error-input " : ""} options={reporting} value={reportingValue} onChange={reportingDrop} />
                            {errors.reporteeFKId?.message && <ErrorValidationMessage errors={errors.reporteeFKId} name="reportee" />}
                        </div>
                        <div className='group-item'>

                        </div>
                    </div>
                    {/* <div className="groups">
                        <div className='group-item'>
                            <div className="item">
                                <Label className='required'>Designation</Label>
                                <DropDown loading={getRoleListLoader} classNamePrefix={errors.designation?.message ? "error-input " : ""} options={filterRole} value={designationValue} onChange={handleDesignationChange} />
                                {errors.designation?.message && <ErrorValidationMessage errors={errors.designation} name="designation" />}
                            </div>
                            <div className="item">
                                <Label className='required'>DOB</Label>
                                <Datepicker selected={startDate} onChange={handleDateChange} className={errors.dob?.message ? "error-input" : ""} />
                                {errors.dob?.message && <ErrorValidationMessage errors={errors.dob} name="date of birth" />}
                            </div>
                        </div >
                    </div >
                    <div className="item">
                        <Label className='required'>Reporting to</Label>
                        <DropDown loading={getEmployeesDataLoader} classNamePrefix={errors.reporteeFKId?.message ? "error-input " : ""} options={reporting} value={reportingValue} onChange={reportingDrop} />
                        {errors.reporteeFKId?.message && <ErrorValidationMessage errors={errors.reporteeFKId} name="reportee" />}
                    </div > */}
                    {/* <div className='group-item'>
                            <Label>Profile</Label>
                            <Fileupload file={profile} setFile={setProfile} onChange={handleFileChange} />
                            {errors.profile?.message && <ErrorValidationMessage errors={errors.profile} name="profile" />}
                        </div> */}
                    <div className="model-actions">
                        <SecondaryButton btnName='Cancel' btnType='button' className='cancel-btn btn-position' onClick={toggleModel} />
                        <PrimaryButton btnName='Add' loader={addEmployeeLoader} />
                    </div>
                </div >
            </form >
        </Modal >
    )
}

export default AddEmployeeModel