import React from "react";
import Skeleton from "react-loading-skeleton";

interface MappingSelectionSkeletonProps {
    datacount: number;
}

const MappingSelectionSkeleton: React.FC<MappingSelectionSkeletonProps> = ({ datacount}: MappingSelectionSkeletonProps) => {
    return (
        <React.Fragment>
            <div className='card '>
                <div className='mapping-grp '>
                    {Array.from({ length: datacount }).map((_, dataIndex) => (
                        <label className='mapping-item d-flex' key={`data-skeleton-${dataIndex}`}>
                            <p>
                                <Skeleton height={"16px"} width={"150px"} />
                            </p>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <Skeleton height={"16px"} width={"20px"} />
                                </label>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default MappingSelectionSkeleton;
