import { createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { changePassword, getProfile, updateProfile } from "../../../helpers/backend_helper";
import { tokenKey } from "../../../helpers/api_helper";


interface profileProps {
    data: any,
    afterSuccess: Function,
    payload?: any,
    afterUpdateImage: any
}
//chnage password
export const changePasswordThunk = createAsyncThunk(
    "changePassword/changePasswordThunk",
    async (props: profileProps) => {
        try {
            const { data, afterSuccess } = props
            const response = await changePassword(data);
            if (response.status?.toLowerCase() === "success") {
                toast.success(response?.message);
                afterSuccess();
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);
//update profile
export const updateProfileImgThunk = createAsyncThunk(
    "updateProfileImg/updateProfileImgThunk",
    async (data: profileProps) => {
        const { payload, afterUpdateImage } = data;
        try {
            const response = await updateProfile(payload, { headers: { "Content-Type": "multipart/form-data" } });
            if (response.status?.toLowerCase() === "success") {
                localStorage.setItem(tokenKey, response?.data?.token);
                toast.success(response?.message);
                afterUpdateImage(response?.data?.token);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);
//get profile
export const getProfileImgThunk = createAsyncThunk(
    "getProfileImg/getProfileImgThunk",
    async (data: profileProps) => {
        const { payload } = data;
        try {
            const response = await getProfile(payload);
            if (response.status?.toLowerCase() === "success") {
                // toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

