import { Outlet } from "react-router-dom";
import CheckIdle from "../constants/CheckIdle";

const ProtectedRoute = () => {
  return (
    <>
      <CheckIdle />
      <Outlet />
    </>
  )
}

export default ProtectedRoute;