import React, { useEffect, useRef, useState } from 'react'
import PageTitle from '../../components/PageTitle';
import Tabs from '../../components/Tabs';
import { dayList, overViewTabsList } from '../../constants/datas/tabsList';
import printImg from '../../assets/images/svgIcons/printer.svg'
import downloadImg from '../../assets/images/svgIcons/download.svg'
import MyResponsibility from './overViewComponent/MyResponsibility';
import DivisionResponsibility from './overViewComponent/DivisionResponsibility';
import Reportees from './overViewComponent/Reportees';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { getClassificationThunk, getFrequencyThunk } from '../../store/asyncThunks/manage/setups';
import { assignEmpThunk, getMyResponsibilityThunk, getdivResponsibilityThunk } from '../../store/asyncThunks/overView/overview';
import { getAllEmployeesThunk, getEmployeesThunk } from '../../store/asyncThunks/manage/mapping';
import AssignEmployees from '../../components/Models/AssignEmployees';
import { useLocation, useNavigate } from 'react-router-dom';
import { customNavigation } from '../../helpers/api_helper';
import { useReactToPrint } from 'react-to-print'
import csvDownload from '../printFiles/csvDownload';
import getActiveTabBasedOnRolePrevilege from '../../constants/getActiveTabBasedOnRolePrevilege';
import { replaceDivResData } from '../../store/slices/overView/getDivsionResponsibility';

const OverView = () => {
  document.title = `OverView | ${process.env.REACT_APP_DOCUMENT_TITLE}`;
  const dispatch = useAppDispatch()
  let location = useLocation();
  const navigate = useNavigate();
  const setNav = customNavigation(navigate)

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = process.env.REACT_APP_PAGE_SIZE || 10;

  const reporteeRef = useRef<any>();
  const myResponsibilityRef = useRef<any>();
  const mydivResponsibilityRef = useRef<any>();

  const myResponsibilityData = useAppSelector((state) => state?.getMyResponsibilityReducer?.data?.data);
  const myResponsibilityLoading = useAppSelector((state) => state?.getMyResponsibilityReducer?.loading);
  const assignEmpThunkLoading = useAppSelector((state) => state?.assignEmp?.loading);
  const myDivResponsibilityData = useAppSelector((state) => state?.getDivisionResponsibilityReducer?.data?.data);
  const myDivResponsibilityDataLOading = useAppSelector((state) => state?.getDivisionResponsibilityReducer?.loading);
  const getEmployeesData = useAppSelector((state) => state?.getEmployeesReducer?.data?.data?.associates);
  const getallEmployeesData = useAppSelector((state) => state?.getResponsibilityEmployeesReducer?.data?.data?.associates);
  const getReporteesLoading = useAppSelector((state) => state?.getReporteesList?.loading);
  const getClassificationList = useAppSelector((state) => state?.getClassificationReducer?.data);
  const getFrequencyList = useAppSelector((state) => state?.getFrequencyReducer?.data?.frequency);
  const userRecord = useAppSelector((state) => state?.decodeTokenSliceReducer?.data);
  const myResponsibility = myResponsibilityData?.responsibility?.map((item: any) => {
    return {
      id: item?.dndFKId,
      label: item?.label,
      duration: item?.duration,
      classification: item?.classificationFKId,
      frequency: item?.frequencyFKId,
      scope: item?.scope,
      status: item?.status,
      mapResId: item?.mapResId,
      description: item?.description,
      bgColorCode: item?.bgColorCode,
      textColorCode: item?.textColorCode
    }
  })

  const myDivResponsibility = myDivResponsibilityData?.data.map((item: any) => {
    return {
      id: item?.dndFKId,
      label: item?.dndFKId.responsibilityFKId.label,
      classification: item?.dndFKId.responsibilityFKId?.classificationFKId,
      frequency: item?.dndFKId.responsibilityFKId.frequencyFKId,
      scope: item?.scope,
      employees: item?.employees,
    }
  })

  const [restInput, setResetInput] = useState(false); 
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState<number | null | undefined>(null);
  const [showModal, setshowModal] = useState(false);
  const [currentSubTab, setCurrentSubTab] = useState(0);
  const [divsonId, setDivsonId] = useState<any>("")
  const [deptId, setDeptId] = useState<any>("")
  const [divRes, setDivRes] = useState<any>("")
  const [divResDept, setDivResDept] = useState<any>("")
  const [showData, setShowData] = useState([])
  const [divsionShowData, setDivsionShowData] = useState([])
  const [prevSelectEmp, setPrevSelectEmp] = useState<any>([])
  const [onsearch, setOnsearch] = useState("")
  const [mapDivision, setMapDivision] = useState("")
  const [divResSearch, setDivResSearch] = useState("")
  const [reporteesList, setReporteesList] = useState<any>();
  const [expandTableRow, setExpandTableRow] = useState(false);
  const [checkedValues, setCheckedValues] = useState<any>([]);
  const [selected, setSelected] = useState<any>();
  const [dateFilter, setDateFilter] = useState({ startDate: "", endDate: "" });
  const [resetdata, setResetdata] = useState<Boolean>(false);
  const [isAdd, setIsAdd] = useState<Boolean | null>(null);
  const [slelectedEmp, setSelectedEmp] = useState<any>([]);
  const [showemployee, setShowEmployee] = useState<any>([]);
  const [myresponsibilitySortBy, setMyesponsibilitySortBy] = useState({
    field: "created_at",
    sort: null,
    value: "descending"
  });
  const [divResponsibilitySortBy, setDivResponsibilitySortBy] = useState({
    field: "created_at",
    sort: null,
    value: "descending"
  });

  useEffect(() => {
    const data: any = { payload: { status: "Active" } };
    dispatch(getAllEmployeesThunk(data));
    const payload: any = { status: "ACTIVE" }
    dispatch(getClassificationThunk(payload));
    dispatch(getFrequencyThunk(payload));
  }, []);

  const dateChange = (date: string | undefined) => {
    if (date) {
      const [startDateString, endDateString] = date?.split(' - ');
      const formattedStartDate = startDateString?.split('/')?.reverse()?.join('-');
      const formattedEndDate = endDateString?.split('/')?.reverse()?.join('-');
      const dateObject = {
        startDate: formattedStartDate,
        endDate: formattedEndDate
      };
      setDateFilter(dateObject)
      setSelected(date)
    } else {
      setDateFilter({
        startDate: "",
        endDate: ""
      })
      setSelected("")
    }
  }

  useEffect(() => {
    getMyRes();
  }, [deptId, divsonId, dateFilter]);

  const getMyRes = () => {
    if (deptId && divsonId) {

      const presentDate = `${new Date().getFullYear()}-${(new Date().getMonth() + 1) < 10 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1}-${(new Date().getDate()) < 10 ? `0${new Date().getDate()}` : new Date().getDate()}`;
      const newDateFilter: any = {}
      newDateFilter["startDate"] = dateFilter?.startDate ? dateFilter?.startDate : presentDate;
      newDateFilter["endDate"] = dateFilter?.endDate ? dateFilter?.endDate : presentDate;
      const data: any = {};
      data['department'] = deptId?._id;
      data['division'] = divsonId?._id;
      data['dateFilter'] = newDateFilter;

      dispatch(getMyResponsibilityThunk(data));
    }
  }

  useEffect(() => {
    if (resetdata === true) {
      getMyRes();
      setResetdata(false);
    }
  }, [resetdata]);

  const getDivRes = () => {
    const data: any = {}
    data["currentPage"] = currentPage;
    data["pageSize"] = pageSize;
    data["data"] = { search: searchValue, department: divResDept?._id, division: divRes?._id }
    dispatch(getdivResponsibilityThunk(data));
  }

  useEffect(() => {
    if (divRes && divResDept) {
      getDivRes();
    }
  }, [divResDept, divRes, currentPage, searchValue])

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setCurrentPage(1);
  }

  useEffect(() => {
    if (myResponsibility) {
      const filterData = myResponsibility?.filter((item: any) => {
        if (item?.frequency?.toLocaleLowerCase() === dayList[currentSubTab]?.label?.toLocaleLowerCase()) {

          const { classification } = checkedValues;

          if (!onsearch && classification && classification?.length > 0) {
            if (checkedValues.classification.includes(item.classification)) {
              return item;
            }
          } else if (onsearch && classification && classification?.length > 0) {
            if (
              (item?.label?.toLowerCase()?.includes(onsearch.trim()?.toLowerCase())) &&
              (checkedValues.classification.includes(item.classification))
            ) {
              return item
            }
          } else if (onsearch && (!classification || classification?.length === 0)) {
            if ((item?.label?.toLowerCase().includes(onsearch?.trim()?.toLowerCase()))) {
              return item
            }
          }
          else {
            return item;
          }

        }
      }).sort((a: any, b: any) => {
        if (myresponsibilitySortBy.sort === true || myresponsibilitySortBy.sort === false) {
          let sortOrder = myresponsibilitySortBy.value === "ascending" ? 1 : -1;
          let aValue = a[myresponsibilitySortBy?.field?.toLowerCase()] || a[myresponsibilitySortBy.field] || Number(a[myresponsibilitySortBy.field]);
          let bValue = b[myresponsibilitySortBy?.field?.toLowerCase()] || b[myresponsibilitySortBy.field] || Number(b[myresponsibilitySortBy.field]);
          if (aValue < bValue) {
            return -1 * sortOrder;
          }
          if (aValue > bValue) {
            return 1 * sortOrder;
          }
          return 0;
        }
      });
      setShowData(filterData)
    }
  }, [myResponsibilityData, currentSubTab, onsearch, myresponsibilitySortBy?.sort, checkedValues])

  useEffect(() => {
    if (myDivResponsibility) {
      const filterData = myDivResponsibility?.filter((item: any) => {
        if (item.label) {

          const { classification, frequency } = checkedValues;

          if (!divResSearch && classification && classification?.length > 0 && (!frequency || frequency && frequency?.length === 0)) {
            if (checkedValues.classification.includes(item.classification)) {
              return item;
            }
          } else if (!divResSearch && (!classification || classification?.length === 0) && frequency?.length > 0) {
            if (checkedValues?.frequency?.includes(item?.frequency)) {
              return item;
            }
          } else if (!divResSearch && classification?.length > 0 && frequency?.length > 0) {
            if (checkedValues?.classification?.includes(item?.classification) && checkedValues?.frequency.includes(item?.frequency)) {
              return item;
            }
          } else if (divResSearch && classification && classification?.length > 0 && (!frequency || frequency && frequency?.length === 0)) {
            if (
              (item?.label?.toLowerCase()?.includes(divResSearch?.trim()?.toLowerCase()) ||
                (item?.employees && item.employees.some((emp: any) => emp?.name?.toLowerCase().includes(divResSearch?.trim()?.toLowerCase()))))
              &&
              (checkedValues?.classification && checkedValues?.classification?.includes(item?.classification))
            ) {
              return item;
            }
          } else if (divResSearch && (!classification || classification?.length === 0) && frequency?.length > 0) {
            if (
              (item?.label?.toLowerCase()?.includes(divResSearch?.trim()?.toLowerCase()) ||
                (item?.employees && item?.employees?.some((emp: any) => emp?.name?.toLowerCase()?.includes(divResSearch?.trim()?.toLowerCase()))))
              &&
              (checkedValues?.frequency && checkedValues?.frequency?.includes(item?.frequency))
            ) {
              return item;
            }
          } else if (divResSearch && classification?.length > 0 && frequency?.length > 0) {
            if (
              (item?.label?.toLowerCase().includes(divResSearch?.trim()?.toLowerCase()) ||
                (item?.employees && item.employees.some((emp: any) => emp?.name?.toLowerCase().includes(divResSearch?.trim()?.toLowerCase()))))
              &&
              (checkedValues?.classification && checkedValues?.classification?.includes(item?.classification))
              &&
              (checkedValues?.frequency && checkedValues?.frequency?.includes(item?.frequency))
            ) {
              return item;
            }
          } else if (divResSearch && (!classification || classification?.length === 0) && (!frequency || frequency && frequency?.length === 0)) {
            if (
              (item?.label?.toLowerCase().includes(divResSearch?.trim()?.toLowerCase()) ||
                (item?.employees && item.employees.some((emp: any) => emp?.name?.toLowerCase().includes(divResSearch?.trim()?.toLowerCase()))))
            ) {
              return item;
            }
          }
          else {
            return item;
          }

        }
      }).sort((a: any, b: any) => {
        if (divResponsibilitySortBy.sort === true || divResponsibilitySortBy.sort === false) {
          let sortOrder = divResponsibilitySortBy.value === "ascending" ? 1 : -1;
          let aValue = a[divResponsibilitySortBy?.field?.toLowerCase()] || a[divResponsibilitySortBy.field] || Number(a[divResponsibilitySortBy.field]);
          let bValue = b[divResponsibilitySortBy?.field?.toLowerCase()] || b[divResponsibilitySortBy.field] || Number(b[divResponsibilitySortBy.field]);
          if (aValue < bValue) {
            return -1 * sortOrder;
          }
          if (aValue > bValue) {
            return 1 * sortOrder;
          }
          return 0;
        }
      });
      setDivsionShowData(filterData)
    }
  }, [myDivResponsibilityData, divResSearch, divResponsibilitySortBy?.sort, checkedValues])

  const toggleModal = () => {
    setshowModal(!showModal);
    setPrevSelectEmp([]);
    setMapDivision("");
    setIsAdd(null)
    setSelectedEmp([])
  };

  const handleChange = ({ data, key }: any) => {
    if (key === "myResDiv") {
      setDivsonId(data);
      setOnsearch("")
      setResetInput(true); 
    }
    if (key === "myResDept"){
      setDeptId(data);
      setOnsearch("")
      setResetInput(true); 
    } 
      
    if (key === "divResDiv") { 
      setDivRes(data); 
      setSearchValue(""); 
      setResetInput(true); 
  }
  if (key === "divResDept") { 
      setDivResDept(data); 
      setSearchValue(""); 
      setResetInput(true); 
  }
  }

  const addAssicate = (id: any) => {
    if (!slelectedEmp.includes(id)) {
      setSelectedEmp([...slelectedEmp, id])
    } else {
      setSelectedEmp(slelectedEmp.filter((empId: string) => empId !== id));
    }

  }

  const getSelectedEmp = (data: any) => {
    setIsAdd(data.add)
    setPrevSelectEmp(data.selectedEmp);
  }

  const mappingSuccess = (data?: any) => {
    dispatch(replaceDivResData(data))
    toggleModal();
  };

  const assignEmp = () => {
    if (isAdd === true || isAdd === false) {
      const payload = { associateFKId: slelectedEmp, dndFKId: mapDivision, isAdd };
      dispatch(assignEmpThunk({ payload, mappingSuccess }));
    }
  };

  const myResponsibilityPrint = useReactToPrint({
    content: () => myResponsibilityRef.current || null,
    onAfterPrint: () => setExpandTableRow(false)
  });

  const mydivResponsibilityPrint = useReactToPrint({
    content: () => mydivResponsibilityRef.current || null,
    onAfterPrint: () => setExpandTableRow(false)
  });

  const reporteePrint = useReactToPrint({
    content: () => reporteeRef.current || null,
    onAfterPrint: () => setExpandTableRow(false)
  });

  const print = () => {
    if (activeTab === 0) myResponsibilityPrint();
    else if (activeTab === 1) mydivResponsibilityPrint();
    else if (activeTab === 2) reporteePrint();
  }

  const handleDownload = () => {
    if (activeTab === 0 && showData?.length > 0) {
      const printData = showData?.map((data: any) => ({
        label: data?.label,
        duration: data?.duration,
        classification: data?.classification,
        scope: data?.scope,
        status: data?.status
      }))
      csvDownload(printData);
    }
    else if (activeTab === 1 && divsionShowData?.length > 0) {
      const printData = divsionShowData?.map((data: any) => {
        let emp = data?.employees?.map((data: any) => ({
          email: data?.email,
          // TODO FOR FUTURE UPDATES
          // name: data?.name,
          // employeeId: data?.employeeId,
          // phoneNumber: data?.phoneNumber,
          // scope: data?.designation,
        }))
        return ({
          responsibilities: data?.label,
          employees: emp
        })
      })
      csvDownload(printData);
    }
    else if (activeTab === 2 && reporteesList?.associates?.length > 0) {
      const printData = reporteesList?.associates?.map((data: any) => {
        return ({
          name: data?.name,
          email: data?.email,
          employeeId: data?.employeeId,
          phoneNumber: String(data?.phoneNumber),
          scope: data?.designation,

        })
      })
      csvDownload(printData);
    }

  };

  const handlePrint = () => {
    setExpandTableRow(true);
    setTimeout(() => {
      print();
    }, 500);
  };

  useEffect(() => {
    setOnsearch("");
    setSearchValue("")
    setDivResSearch("");
    setCheckedValues([]);
    setShowData([]);
    setDivsonId('');
    setDeptId('');
    setDivResDept('');
    setDivRes('');
    setCurrentPage(1)
  }, [activeTab]);

  useEffect(() => {
    const reporteeTab = location.state?.reportee;
    if (reporteeTab === 2) {
      setActiveTab(reporteeTab);
      navigate('.', { state: { ...location.state, reportee: undefined } });
    }
    else if (userRecord?.action?.overview) {
      const tab = getActiveTabBasedOnRolePrevilege({ object: userRecord?.action?.overview, tabsList: overViewTabsList });
      setActiveTab(tab);
    }
  }, [userRecord])


  const getEmployeeList = () => {
    return getallEmployeesData?.filter((item: any) => {
      if (isAdd === true) {
        return (item.status.toLocaleLowerCase() === "active" && !prevSelectEmp.includes(item._id))
      } else if (isAdd === false) {
        return (item.status.toLocaleLowerCase() === "active" && prevSelectEmp.includes(item._id))
      }
    })
  }

  useEffect(() => {
    // stop render while isAdd is null scnario
    if (isAdd === true || isAdd === false) {
      setShowEmployee(getEmployeeList())
    }
  }, [isAdd])


  return (
    <>
      <div className='overView mapping-list'>
        <PageTitle title={`Overview`} />
        <div className='tabSec' >
          <div className='tabs custom-scroll'>
            <Tabs menu={`overview`} tabsList={overViewTabsList} setActiveTab={setActiveTab} activeTab={activeTab} />
          </div>
          <div className='downAndPrint'>
            <div className={
              (activeTab === 0 && (!myResponsibilityLoading && showData?.length > 0 ? "options" : "options optionsDisable")) ||
              (activeTab === 1 && (!myDivResponsibilityDataLOading && divsionShowData?.length > 0 ? "options" : "options optionsDisable")) ||
              (activeTab === 2 && (!getReporteesLoading && reporteesList?.associates?.length > 0 ? "options" : "options optionsDisable")) || "options optionsDisable"

            } onClick={() => {
              if (
                (activeTab === 0 && showData?.length > 0) ||
                (activeTab === 1 && divsionShowData?.length > 0) ||
                (activeTab === 2 && reporteesList?.associates?.length > 0)
              ) handlePrint();
            }} >
              <img src={printImg} alt="print" />
            </div>
            <div className={
              (activeTab === 0 && (!myResponsibilityLoading && showData?.length > 0 ? "options" : "options optionsDisable")) ||
              (activeTab === 1 && (!myDivResponsibilityDataLOading && divsionShowData?.length > 0 ? "options" : "options optionsDisable")) ||
              (activeTab === 2 && (!getReporteesLoading && reporteesList?.associates?.length > 0 ? "options" : "options optionsDisable")) || "options optionsDisable"
            }>
              <img src={downloadImg} alt="download" onClick={() => {
                if (
                  (activeTab === 0 && showData?.length > 0) ||
                  (activeTab === 1 && divsionShowData?.length > 0) ||
                  (activeTab === 2 && reporteesList?.associates?.length > 0)
                ) handleDownload();
              }} />
            </div>
          </div>
        </div>

        {activeTab === 0 && <MyResponsibility
          currentSubTab={currentSubTab}
          deptId={deptId}
          divsonId={divsonId}
          setCurrentSubTab={setCurrentSubTab}
          handleChange={handleChange}
          showData={showData}
          searchVal={onsearch}
          onSearch={setOnsearch}
          loading={myResponsibilityLoading}
          sortBy={myresponsibilitySortBy}
          setSortBy={setMyesponsibilitySortBy}
          print={myResponsibilityRef}
          expandTableRow={expandTableRow}
          filterValue={checkedValues}
          setFilterValue={setCheckedValues}
          lists={[{ classification: getClassificationList?.classification }]}
          showfilter={true}
          context={"myResponsibility"}
          dateChange={dateChange}
          selected={selected}
          setResetdata={setResetdata}
          restInput={restInput}
        />}

        {activeTab === 1 && <DivisionResponsibility
          deptId={divResDept}
          divsonId={divRes}
          handleChange={handleChange}
          handleSearch={handleSearch}
          searchVal={searchValue}
          showData={divsionShowData}
          popup={setshowModal}
          selectDivison={setMapDivision}
          alreadySelected={getSelectedEmp}
          sortBy={divResponsibilitySortBy}
          setSortBy={setDivResponsibilitySortBy}
          print={mydivResponsibilityRef}
          expandTableRow={expandTableRow}
          filterValue={checkedValues}
          setFilterValue={setCheckedValues}
          lists={[{ classification: getClassificationList?.classification }, { frequency: getFrequencyList }]}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          restInput={restInput}
        />}

        {activeTab === 2 && <Reportees
          reporteesList={reporteesList}
          setReporteesList={setReporteesList}
          print={reporteeRef}
          expandTableRow={expandTableRow}
        />}
      </div >

      {
        showModal &&
        <AssignEmployees
          data={showemployee}
          showModal={showModal}
          toggle={toggleModal}
          modalTitle={isAdd ? "Assign Employees" : "Remove Employees"}
          onSelect={addAssicate}
          onSubmit={assignEmp}
          selected={slelectedEmp}
          loader={assignEmpThunkLoading}
        />
      }


    </>
  )
}

export default OverView

