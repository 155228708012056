import { createAsyncThunk } from "@reduxjs/toolkit";
import { getorganisation, updateorganisation, getdivisionscope, getdepartmentscope } from "../../../helpers/backend_helper";
import toast from "react-hot-toast";

interface scopeProps {
    currentPage: number,
    pageSize: number,
    data?: {
        search: string,
        status: string
    }
}

export const getorganisationThunk = createAsyncThunk(
    "getorganisation/getorganisationThunk",
    async () => {
        try {
            const response = await getorganisation();
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);

//update
export const updateorganisationThunk = createAsyncThunk(
    "updateorganisation/updateorganisationThunk",
    async (datas: {
        data: {
            label: string,
            scope: string,
            id: string
        },
        afterSuccess: Function
    }) => {
        const { data, afterSuccess } = datas;
        try {
            const response = await updateorganisation(data);
            if (response?.status?.toLowerCase() === "success") {
                afterSuccess(response)
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
            return await response
        }
        catch (error: any) {
            return await error?.response?.data;
        }

    }
);

// get divisonscope
export const getdivisionThunk = createAsyncThunk(
    "getdivision/getdivisionThunk",
    async (props: scopeProps) => {
        try {
            const { currentPage = 0, pageSize = 0, data } = props;
            const response = await getdivisionscope(data, currentPage, pageSize);
            return await response;
        }
        catch (error) {
            return error;
        }
    }
);

// get departmentscope
export const getdepartmentThunk = createAsyncThunk(
    "getdepartment/getdepartmentThunk",
    async (props: scopeProps) => {
        try {
            const { currentPage = 0, pageSize = 0, data } = props
            const response = await getdepartmentscope(data, currentPage, pageSize);
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);