import { ChangeEvent } from 'react';
import InputSearch from '../../../../components/InputSearch';
import MappingSelectionSkeleton from '../../../../components/skeleton/MappingSelectionSkeleton';
import NoData from '../../../../components/NoData';

interface mapping {
    mappingHead: string,
    mappingdata: any,
    search?: boolean,
    onChange: Function,
    selected: string | "",
    departmentSearch?: Function,
    resetValue?: boolean,
    isSkeleton?: Boolean,
    isOverLay?: boolean,
}

const MappingSelection = (props: mapping) => {
    const { mappingHead, mappingdata, search, onChange, selected, departmentSearch, resetValue = false, isSkeleton, isOverLay = false } = props;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e);
    }

    return (
        <div className='card h-100'>
            {isOverLay && <div className='overlay'></div>}
            <div className='mp-head'>
                <h5>{mappingHead}</h5>
                {search && <InputSearch onSearch={departmentSearch} resetValue={resetValue} />}
            </div>
            <div className='mapping-grp custom-scroll'>
                {isSkeleton ? <MappingSelectionSkeleton datacount={10} /> :
                    mappingdata?.length > 0 ? <>
                        {mappingdata?.map((item: any) => (
                            <label className='mapping-item d-flex' key={`${mappingHead}-${item.value}`}>
                                <p>{item?.label}</p>
                                <div className="form-check">
                                    <input className="form-check-input" checked={selected === item?._id} name={mappingHead?.toLowerCase()} type="radio" value={item?._id} id={item?.value} onChange={(e) => handleChange(e)} />
                                    <label className="form-check-label" htmlFor={item?.value}>
                                    </label>
                                </div>
                            </label>
                        ))}
                    </> : <NoData />
                }
            </div>
        </div>
    );
};

export default MappingSelection;