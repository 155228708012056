import { useEffect, useState } from 'react'
import { AccordionBody, AccordionHeader, AccordionItem, Label, UncontrolledAccordion } from 'reactstrap'
import { useForm } from "react-hook-form"
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import SecondaryButton from '../../../components/buttons/SecondaryButton';
import ErrorValidationMessage from '../../../components/ErrorValidationMessage';
import PasswordInput from '../../../components/PasswordInput';
import PasswordStrengthMeter from '../../../components/PasswordStrengthMeter';
import { changePasswordThunk } from '../../../store/asyncThunks/profile/Myprofile';
import { AsyncThunkAction, AnyAction } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '../../../store/store';

const ChangePassword = () => {

  const dispatch = useAppDispatch();

  const [open, setOpen] = useState('');
  const [password, setPassword] = useState<string | undefined>('');
  const [PasswordValidate, setPasswordValidate] = useState<number | undefined>(0);

  const saveLoader = useAppSelector(state => state.changePasswordReducer.loading);

  const changePasswordSchema = yup.object().shape({
    oldPassword: yup.string().required().trim(),
    newPassword: yup.string().required().test('custom', 'Old password and new password must not be same', (val, allValues) => {
      if (allValues?.parent?.oldPassword === val) {
        return false;
      } else {
        return true;
      }
    }).test('custom', 'Password must be reach medium strength', val => {
      if (PasswordValidate && PasswordValidate >= 2) {
        return true;
      } else {
        return false;
      }
    }).trim(),
  });

  const formValues = {
    oldPassword: '',
    newPassword: '',
  }

  const { handleSubmit, register, watch, reset, formState: { errors } } = useForm({
    defaultValues: formValues,
    resolver: yupResolver(changePasswordSchema),
  })

  const onSubmit = (values: typeof formValues) => {
    const data: any = {}
    data['data'] = values;
    data['afterSuccess'] = clear;
    dispatch(changePasswordThunk(data));
  }

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'newPassword') {
        setPassword(value?.newPassword);
      }
    });
    return () => subscription.unsubscribe()
  }, [watch])

  const toggle = (id: string) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };

  const clear = () => {
    reset()
    setPasswordValidate(0)
    setPassword('')
  }
  return (
    <UncontrolledAccordion stayOpen toggle={toggle}      >
      <AccordionItem>
        <AccordionHeader targetId="1">
          Change password
        </AccordionHeader>
        <AccordionBody accordionId="1">
          <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <div className="groups">
              <div className='group-item'>
                <Label className='required'>Old password</Label>
                <input type='text' className={`custom-input form-control  ${errors.oldPassword?.message ? "error-input" : ""}`} {...register("oldPassword")} />
                {errors.oldPassword?.message && <ErrorValidationMessage errors={errors.oldPassword} name="old password" />}
              </div>
              <div className='group-item'>
                <Label className='required'>New password</Label>
                <PasswordInput className={`custom-input form-control ${errors.newPassword?.message ? "error-input" : ""}`} register={register} registerName={"newPassword"} />
                {errors.newPassword?.message && <ErrorValidationMessage errors={errors.newPassword} name="new password" />}
                <PasswordStrengthMeter password={password} setPasswordValidate={setPasswordValidate} />
              </div>
            </div>
            <div className="submit-action">
              <PrimaryButton btnName='Save' btnType='submit' loader={saveLoader} />
              <SecondaryButton onClick={clear} btnName='Clear' btnType='button' />
            </div>
          </form>
        </AccordionBody>
      </AccordionItem>
    </UncontrolledAccordion>
  )
}

export default ChangePassword


