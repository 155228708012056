import React from 'react'

const PageLoader = () => {
    return (
        <div className="page-loader-overlay">
            <div className='page-loader'></div>
        </div>
    )
}

export default PageLoader