import { AccordionBody, AccordionHeader, AccordionItem, DropdownMenu, DropdownToggle, Label, UncontrolledAccordion, UncontrolledDropdown } from 'reactstrap';
import arrowDown from '../assets/images/svgIcons/accordian_arrow.svg';
import { SetStateAction, useEffect, useState } from 'react';
import reset from '../assets/images/svgIcons/reset_filter.svg';

interface FilterDropDownProps {
    filterIcon?: string;
    checkedValues?: any[];
    setCheckedValues?: SetStateAction<any>
    lists?: any;
}

const FilterDropDown = (props: FilterDropDownProps) => {
    const { filterIcon = "", checkedValues, setCheckedValues, lists } = props;

    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [open, setOpen] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const toggle = (id: string) => {
        if (open === id) {
            setOpen('');
        } else {
            setOpen(id);
        }
    };


    const handleCheckboxToggle = (subItem: any, header: any) => {
        setCheckedValues((prevCheckedValues: any) => {
            if (typeof prevCheckedValues === 'object' && !Array.isArray(prevCheckedValues)) {
                const updatedValues = { ...prevCheckedValues };
                if (updatedValues[header]) {
                    const index = updatedValues[header].indexOf(subItem);
                    if (index !== -1) {
                        updatedValues[header].splice(index, 1);
                    } else {
                        updatedValues[header].push(subItem);
                    }
                } else {
                    updatedValues[header] = [subItem];
                }
                // Remove keys with empty arrays
                for (const key in updatedValues) {
                    if (updatedValues.hasOwnProperty(key) && updatedValues[key].length === 0) {
                        delete updatedValues[key];
                    }
                }
                return updatedValues;
            } else {
                return { [header]: [subItem] };
            }
        });

        setIsChecked(!isChecked);
    };



    const handleReset = () => {
        setCheckedValues([]);
    };

    return (
        <div className='filter-dropdown'>
            <span className={Object.keys(checkedValues!).length > 0 ? "filter-count" : "d-none"}>
                {Object.keys(checkedValues!).length}
            </span>
            <UncontrolledDropdown group>
                <DropdownToggle
                    caret
                    color="primary"
                ><img src={filterIcon} alt="icon" /></DropdownToggle>
                <DropdownMenu>
                    <div className="filter-header dropdown-item">
                        <p className="filter-title">Filter <span className="filter-coun">
                        </span></p>
                        <span className="reset-filter cursor-pointer" onClick={handleReset}><img src={reset} alt="reset" /></span>
                    </div>
                    <div className="filter-body custom-scroll">
                        <UncontrolledAccordion stayOpen toggle={toggle} >
                            {
                                lists && lists?.map((item: any, index: any) => {
                                    let header = Object.keys(item)

                                    return <AccordionItem key={`filter-item-${index}`}>
                                        <AccordionHeader targetId={`filter-item-${index}`}>
                                            {header}
                                        </AccordionHeader>
                                        <AccordionBody accordionId={`filter-item-${index}`}>
                                            <ul className='sublist'>
                                                {item[`${header}`]?.map((subItem: any, subIndex: any) => {

                                                    const isChecked =
                                                        checkedValues &&
                                                        Object.values(checkedValues).some((value) =>
                                                            Array.isArray(value) ? value.includes(subItem.label) : false
                                                        );

                                                    return <li className='sub-item' key={`sub-item-${index}-${subIndex}`}>
                                                        <label htmlFor={`sub-item-${index}-${subIndex}`} className="list">
                                                            {subItem?.label}
                                                            <div className="form-check d-flex align-items-center">
                                                                <label className="form-check-label" htmlFor={`sub-item-${index}-${subIndex}`}>
                                                                    <input className="form-check-input m-0" id={`sub-item-${index}-${subIndex}`} type="checkbox" 
                                                                        checked= {isChecked}
                                                                        onChange={(e) => {
                                                                            handleCheckboxToggle(subItem?.label, header[0])
                                                                        }} />
                                                                </label>
                                                            </div>
                                                        </label>
                                                    </li>
                                                })}
                                            </ul>
                                        </AccordionBody>
                                    </AccordionItem>
                                })
                            }
                        </UncontrolledAccordion >
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div >
    )
}

export default FilterDropDown