import React from "react";
import Skeleton from "react-loading-skeleton";

interface ResponsibilitiesMappingSkeletonProps {
    datacount: number;
}

const ResponsibilitiesMappingSkeleton = ({ datacount }: ResponsibilitiesMappingSkeletonProps) => {
    return (
        <div className='card h-100 me-0'>
            <div>
                <label className='mapping-item pointer-events-none'>
                    <p><Skeleton height={"16px"} width={"100%"} /></p>
                </label>
                {Array.from({ length: datacount }).map((_, index) => (
                    <label className='mapping-item pointer-events-none' key={`responsibilities-skeleton-${index}`}>
                        <div>
                            <p><Skeleton height={"16px"} width={"100%"} /></p>
                            <div className='d-flex align-items-center pt-2'>
                                <p className='me-2'><Skeleton height={"16px"} width={"80px"} /></p>
                                <p ><Skeleton height={"16px"} width={"80px"} /></p>
                            </div>
                        </div>
                        <div className='res-dec'>
                            <p><Skeleton height={"16px"} width={"100%"} /></p>
                        </div>
                        <div className="form-check ">
                            <label className="form-check-label">
                                <Skeleton height={"16px"} width={"20px"} />
                            </label>
                        </div>
                    </label>
                ))}
            </div>
        </div>
    );
};

export default ResponsibilitiesMappingSkeleton;
