import { SetStateAction } from 'react';
import logo_big from '../assets/images/logo.png'
import logo_icon from '../assets/images/fav_icon.png'
import hamburger from '../assets/images/svgIcons/hamburger_menu.svg';
import { NavLink } from 'react-router-dom';

interface props {
    setOpenMenu: SetStateAction<any>,
    openMenu: boolean,
    isShortMenu: boolean,
}

const AppLogo = (props: props) => {
    const { openMenu, setOpenMenu, isShortMenu } = props;

    return (
        <div className={`logo-part ${isShortMenu ? "isShort" : ""}`}>
            <div className="hamburger-menu" onClick={() => setOpenMenu(!openMenu)}>
                <img src={hamburger} alt="menu-collapse" />
            </div>
            <NavLink to={'/'}>
                <div className={`logo`}>
                    <img className='for-big-screen' src={isShortMenu ? logo_icon : logo_big} alt="logo" />
                    <img className='for-small-screen' src={logo_icon} alt="logo" />
                </div>
            </NavLink>
        </div>
    )
}

export default AppLogo