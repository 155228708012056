import { useEffect, useRef, useState } from 'react';
import searchIcon from '../assets/images/svgIcons/search-icon.svg';
interface InputSearchModalProps {
    search: string
    setSearch: (val: any) => void
}

const InputSearchModal = ({ search, setSearch }: InputSearchModalProps) => {
    const ref = useRef<any>(null);

    const handleChange = (e: any) => {
        const value = e.target.value
        setSearch(value)

    }
    useEffect(() => {
        if (search) ref.current.focus()
    }, [search])


    return (
        <div className="input-search">
            <input value={search} onChange={handleChange} ref={ref} type="text" className="custom-input" />
            <div className="search-icon" onClick={() => ref.current.focus()}>
                <img src={searchIcon} alt="search" />
            </div>
        </div>
    )
}

export default InputSearchModal