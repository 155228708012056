import { Label, Modal } from "reactstrap"
import closeIcon from '../../../assets/images/svgIcons/close_icon.svg';
import SecondaryButton from "../../buttons/SecondaryButton";
import PrimaryButton from "../../buttons/PrimaryButton";
import { useForm } from "react-hook-form"
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { manageClassificationThunk, getClassificationThunk } from "../../../store/asyncThunks/manage/setups";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { SetStateAction, useEffect, useRef, useState } from "react";
import ErrorValidationMessage from "../../ErrorValidationMessage";
import { descriptionLimit } from "../../../constants/HandleFormLimit";
import { updateClassificationData } from "../../../store/slices/manage/setups/Classification/getClassification";
import { SketchPicker } from 'react-color'
import OutsideClick from "../../../constants/OutsideClick";

interface ManageClassificationModalProps {
    showModal: boolean,
    toggle: any,
    mode: 'Add' | 'Edit',
    EditData: any,
    handleModalPagination?: Function,
}

const ManageClassificationModal = (props: ManageClassificationModalProps) => {
    const { showModal, toggle, mode, EditData, handleModalPagination } = props;

    const dispatch = useAppDispatch();

    const textColorRef = useRef<any>()
    const bgColorRef = useRef<any>()

    const [sketchPickerTextColor, setSketchPickerTextColor] = useState<any>('#000000');
    const [sketchPickerBgColor, setSketchPickerBgColor] = useState<any>('#D3D3D3');

    const [colorPickerVisible, setColorPickerVisible] = useState(false);
    const [backgrouncolorVisible, setBackgroundColorPickerVisible] = useState(false);

    const addClassificationLoader = useAppSelector((state) => state?.manageClassificationReducer?.loading);

    const FormShcema = yup.object().shape({
        label: yup.string().required().trim(),
        scope: yup.string().required().test("maxLength", '-', val => {
            if (!val) return false;
            return val.length <= descriptionLimit;
        }).trim(),
        order: yup.number().required().typeError('Order must be a number and  greater than or equal to 1 '),
        textColorCode: yup.string(),
        bgColorCode: yup.string()
    })

    const formValues = {
        label: '',
        scope: '',
        order: '',
        textColorCode: '',
        bgColorCode: '',
    }

    const { handleSubmit, register, formState: { errors }, reset, setValue } = useForm({
        defaultValues: formValues,
        resolver: yupResolver<any>(FormShcema),
    })

    const afterSuccess = () => {
        toggleClose();
        handleModalPagination && handleModalPagination();
    };

    const toggleModel = () => {
        if (EditData?._id) {
            resetFormValues()
            setColorPickerVisible(false);
            setBackgroundColorPickerVisible(false);
        } else {
            toggleClose() 
        }
    }

    const toggleClose = () => {
        reset();
        toggle();
        setColorPickerVisible(false);
        setBackgroundColorPickerVisible(false);
        if(!EditData?._id){
            setSketchPickerTextColor('#000000')
            setSketchPickerBgColor('#D3D3D3')
        }
    }

    const onSubmit = (values: any) => {

        const data: any = {}
        data['data'] = values;
        data['afterSuccess'] = afterSuccess;
        if (mode === 'Add') {
            dispatch(manageClassificationThunk(data));
        } else if (mode === 'Edit') {
            data['id'] = EditData?._id;
            dispatch(manageClassificationThunk(data));
        }
    }

    useEffect(() => {
        OutsideClick({ setisOpen: setColorPickerVisible, wrapperRef: textColorRef })
        OutsideClick({ setisOpen: setBackgroundColorPickerVisible, wrapperRef: bgColorRef })
        if (EditData?._id) {
            resetFormValues();
        }
    }, [EditData, EditData?._id])

    const resetFormValues = () => {
        reset({
            label: EditData?.label || "",
            scope: EditData?.scope || "",
            order: EditData?.order || 0,
            textColorCode: EditData?.textColorCode || "",
            bgColorCode: EditData?.bgColorCode || ""
        });
        setSketchPickerTextColor(EditData?.textColorCode)
        setSketchPickerBgColor(EditData?.bgColorCode)
    }

    const handlecolorClick = () => {
        setColorPickerVisible(true);
    };
    const handlebackgroundcolorClick = () => {
        setBackgroundColorPickerVisible(true);
    };



    return (
        <>
            <Modal size="md" isOpen={showModal} centered={true}
                className='manage-setup add-employee-model add-classification-model'
                toggle={toggleModel}
            >
                <div className="modal-header">
                    <h1 className='title'>{mode === 'Add' ? 'Add new classification' : 'Update classification'}</h1>
                    <span onClick={toggleClose} aria-hidden="true" className='close-icon'><img src={closeIcon} alt="close" /></span>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                    <div className="modal-body">
                        <div className="groups">
                            <div className='group-item'>
                                <Label className='required'>Classification name</Label>
                                <input type="text" className={`custom-input form-control ${errors.label?.message ? "error-input" : ""}`} {...register("label")} />
                                {errors.label?.message && <ErrorValidationMessage errors={errors.label} name="classification name" />}
                            </div>
                        </div>
                        <div className="groups">
                            <div className="group-item">
                                <Label className='required'>Description</Label>
                                <textarea
                                    className={`custom-input form-control  custom-scroll text-area ${errors.scope?.message ? "error-input" : ""}`} {...register("scope")}
                                ></textarea>
                                {errors.scope?.message && <ErrorValidationMessage errors={errors.scope} name="description" sizeMax={descriptionLimit} />}
                            </div>
                        </div>
                        <div className="groups">
                            <div className="group-item">
                                <Label className='required'>Order</Label>
                                <input type="number" className={`custom-input form-control ${errors.order?.message ? "error-input" : ""}`} {...register("order")} onWheel={(e) => e.currentTarget.blur()} />
                                {errors.order?.message && <ErrorValidationMessage errors={errors.order} name="order" />}
                            </div>
                        </div>
                        <div className="colorpicker-container">
                            <div className="groups">
                                <div className="group-item">
                                    <Label className='required'>Textcolor</Label>
                                    <div
                                        className="color-input"
                                        style={{
                                            backgroundColor: `${sketchPickerTextColor}`,
                                        }}
                                        onClick={handlecolorClick}
                                    ></div>
                                    {colorPickerVisible && (
                                        <div ref={textColorRef} className="colorpicker-design">
                                            <SketchPicker
                                                className="sketch-picker"
                                                onChange={(color) => {
                                                    setSketchPickerTextColor(color.hex);
                                                    setValue('textColorCode', color.hex);
                                                }}
                                                color={sketchPickerTextColor}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="groups">
                                <div className="group-item">
                                    <Label className='required'>Backgroundcolor</Label>
                                    <div
                                        className="color-input"
                                        style={{
                                            backgroundColor: `${sketchPickerBgColor}`,
                                        }}
                                        onClick={handlebackgroundcolorClick}
                                    ></div>
                                    {backgrouncolorVisible && (
                                        <div ref={bgColorRef} className="colorpicker-design">
                                            <SketchPicker
                                                className="custom-sketch-picker"
                                                onChange={(color) => {
                                                    setSketchPickerBgColor(color.hex);
                                                    setValue('bgColorCode', color.hex);
                                                }}
                                                color={sketchPickerBgColor}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="model-actions">
                            <SecondaryButton btnName={mode === 'Add' ? 'Cancel' : 'Clear'} btnType='button' className='cancel-btn btn-position' onClick={toggleModel} />
                            <PrimaryButton btnName={mode === 'Add' ? 'Add' : 'Update'} btnType='submit' loader={addClassificationLoader} />
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    )
}
export default ManageClassificationModal