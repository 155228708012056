import Select from "react-select";
import DropdownLoader from "./DropdownLoader";

interface dropdownProps {
    classNamePrefix?: string,
    mainClass?: string,
    options: Array<Object>,
    placeholder?: string,
    onChange?: (value: any) => void;
    [key: string]: any;
    loading: boolean | Boolean;
    value?: Object
}

const DropDown = (props: dropdownProps) => {
    const { mainClass = '', classNamePrefix = '', placeholder = 'Select', options = [], value, loading,
        onChange, ...rest } = props;

    return (
        <div className={`position-relative drop-down ${mainClass}`}>
            <Select
                options={options}
                classNamePrefix={`custom_select ${classNamePrefix}`}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                {...rest}
            />
            {loading &&
                <DropdownLoader />
            }
        </div>
    );
};

export default DropDown;
