//LOGIN
export const LOGIN = "/auth/login";
//Email Verifiy
export const FORGOT_PASSWORD = "/auth/forgot-password-email";
export const FORGOT_PASSWORD_RESET = "/auth/forgot-password-reset";
//manage
export const ADD_EMPLOYEE = "/associate/add";
export const GET_EMPLOYEES = "/associate/get-all";
export const GET_BY_EMPLOYEE_ID = "associate/get";
export const UPDATE_EMPLOYEE = "/associate/update";
export const EMPLOYEE_STATUS = "/associate/status";
export const GET_MANAGE_SALARY = "/associate/salary/get-all/0/0";
export const MANAGE_SALARY_ADD = "/associate/salary/add";
export const MAP_RESPONS = "/r-r/dndresponsibility/get-all";

//scope
export const GET_ORGANISATION = "/r-r/organisation/get-all";
export const UPDATE_ORGANISATION = "/r-r/organisation/update";
export const GET_DIVISION_SCOPE = "/r-r/division/get-all";
export const GET_DEPARTMENT_SCOPE = "/r-r/department/get-all";

//setups
export const ADD_ROLE = "/r-r/role/add";
export const ADD_DEPARTMENT = "/r-r/department/add";
export const ADD_DIVISION = "/r-r/division/add";
export const ADD_RESPONSIBILITY = "/r-r/responsibility/add";
export const ADD_CLASSIFICATION = "/r-r/classification/add";
export const ADD_FREQUENCY = "/r-r/frequency/add";

export const UPDATE_ROLE = "/r-r/role/update";
export const UPDATE_DEPARTMENT = "/r-r/department/update";
export const UPDATE_DIVISION = "/r-r/division/update";
export const UPDATE_RESPONSIBILITY = "/r-r/responsibility/update";
export const UPDATE_CLASSIFICATION = "/r-r/classification/update";
export const UPDATE_FREQUENCY = "/r-r/frequency/update";

export const CHANGE_STATUS_ROLE = "/r-r/role/status";
export const CHANGE_STATUS_DEPARTMENT = "/r-r/department/status";
export const CHANGE_STATUS_DIVISION = "/r-r/division/status";
export const CHANGE_STATUS_RESPOSIBILITY = "/r-r/responsibility/status";
export const CHANGE_STATUS_CLASSIFICATION = "/r-r/classification/status";
export const CHANGE_STATUS_FREQUENCY = "/r-r/frequency/status";

export const GET_ROLE = "/r-r/role/get-all";
export const GET_DEPARTMENT = "/r-r/department/get-all";
export const GET_DIVISION = "/r-r/division/get-all";
export const GET_RESPONSIBILITY = "/r-r/responsibility/get-all";
export const GET_CLASSIFICATION = "/r-r/classification/get-all";
export const GET_FREQUENCY = "/r-r/frequency/get-all";

// export const GET_ROLE_BY_ID = "/r-r/role"
// export const GET_DEPARTMENT_BY_ID = "/r-r/department"

// OVERVEW MyResponsblity
export const GET_MYRESPONSIBILITY = "/r-r/mapresponsibility/myresponsibility";

//manage responsibilities
export const GET_RESPONSIBILITIES = "/r-r/dndresponsibility/get";
export const ADD_RESPONSIBILITIES = "/r-r/dndresponsibility/add";
export const GET_DIVISION_RESPONSIBILITY = "/r-r/mapresponsibility/division";
//dnd
export const GET_DND_RESPONSIBILITY = "/r-r/dndresponsibility/get-all/0/0";
//assign emp
export const ASSIGN_EMP = "/r-r/mapresponsibility/employee";
export const GET_REPORTEES_LIST = "/associate/reportees";
export const GET_REPORTEES_BY_ID = "/r-r/mapresponsibility/employee";
export const GET_REPORTEES_MAPPING_BY_ID = "/r-r/mapresponsibility/employee-mapping";
export const GET_REPORTEES_DATA = "/associate/details";

//profile
export const CHANGE_PASSWORD = "/auth/change-password"
export const UPDATE_PROFILE_IMAGE = "/auth/profile-img"
export const MAP_DND_BY_EMP_ID = "/r-r/mapresponsibility/employee-dnd";
export const GET_PROFILE_IMAGE = "/auth/get-profile-img"

//map responsibility status 
export const CHANGE_RESPONSIBILITY_STATUS = "/r-r/mapresponsibility/status"
