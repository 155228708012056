import { useNavigate } from "react-router"
import { axiosApi, tokenKey } from "./api_helper"
import { useEffect, useState } from "react";
import InternalserverModel from "../components/Models/InternalserverModel";
import error_500 from "../assets/images/svgImages/error_500.svg"
import error_404 from "../assets/images/svgImages/error_404.svg"

const Interceptor = () => {

    const navigate = useNavigate();

    const [internalServerModelShow, setinternalServerModelShow] = useState(false);
    const [modal, setModal] = useState<any>();
    const [statusError, setStatusError] = useState(0);

    axiosApi.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error?.response?.status === 403 || error?.response?.status === 401) {
                localStorage.removeItem(tokenKey)
                navigate("/signin");
            } else if (error?.response?.status === 500) {
                setinternalServerModelShow(true);
                setStatusError(500);
            } else if (error?.response?.status === 404) {
                setinternalServerModelShow(true);
                setStatusError(404);
            }
            return Promise.reject(error);
        }
    )

    const toggleInternalServerModelShow = () => {
        setinternalServerModelShow(!internalServerModelShow);
    }

    useEffect(() => {
        if (internalServerModelShow) {
            setModal(() => {
                return <InternalserverModel
                    showModal={internalServerModelShow}
                    toggle={toggleInternalServerModelShow}
                    description={statusError === 500 ? "Sorry for the inconvenience caused by an internal server error. Please try again." : (statusError === 404 ? "The page you are looking is unavailable." : "")}
                    imgPath={statusError === 500 ? error_500 : error_404}
                />
            })
        } else {
            setModal("");
        }
    }, [internalServerModelShow, statusError])

    return (
        <>
            {modal}
        </>
    )
}

export default Interceptor