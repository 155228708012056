import { Card, CardBody, Label, Modal } from "reactstrap"
import closeIcon from '../../assets/images/svgIcons/close_icon.svg';
import DropDown from "../DropDown";
import { departmentOptions, divisionOptions } from "../../constants/datas/dropdownOptions";
import InputSearchModal from "../InputSearchModal";
import { useForm } from "react-hook-form"
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useEffect, useState } from "react";
import { addDndResponsibilitiesThunk, assignDndByEmpIdThunk, getDndResponsibilitiesThunk, getReporteesMappingByIdThunk, getUserDndResponsibilityThunk, mapResponsibilityThunk } from "../../store/asyncThunks/manage/mapping";
import { getDepartmentThunk, getDivisionThunk } from "../../store/asyncThunks/manage/setups";
import InfoCard from "../card/InfoCard";
import TableSkeleton from "../skeleton/TableSkeleton";
import { assignEmpThunk } from "../../store/asyncThunks/overView/overview";
import { getReporteesById } from "../../store/asyncThunks/overView/reportees";
import MappingSelectionSkeleton from "../skeleton/MappingSelectionSkeleton";
import ResponsibilitiesMappingSkeleton from "../skeleton/ResponsibilitiesMappingSkeleton";
import NoData from "../NoData";
import { clearReporteesById } from "../../store/slices/overView/getReporteesByIdSlice";


interface MapResponsibilitiesModalProps {
    toggle: Function,
    showModal: boolean,
    userProps?: any
}

const MapResponsibilitiesModal = (props: MapResponsibilitiesModalProps) => {
    const { showModal, toggle, userProps } = props;

    const dispatch = useAppDispatch();

    const getDivisionList = useAppSelector((state) => state?.getDivisionReducer?.data?.divisions);
    const getDivisionListLoader = useAppSelector((state) => state?.getDivisionReducer?.loading);
    const getDepartmentList = useAppSelector((state) => state?.getDepartmentReducer?.data?.departments);
    const getDepartmentListLoader = useAppSelector((state) => state?.getDepartmentReducer?.loading);
    const mapResponsibilityData = useAppSelector((state: any) => state?.mapResponsibilityReducer?.data?.data);
    const getUserDndResListData = useAppSelector((state) => state?.getUserDndResponsibilities?.data?.data);
    const getUserDndResLoading = useAppSelector((state) => state?.getUserDndResponsibilities?.loading);
    const getReporteesInfo = useAppSelector((state: any) => state?.getReporteesById?.data?.data);
    const mappingEmployeeReportieedata = useAppSelector((state: any) => state?.getMappingReportiesReducer?.data?.data);
    const mappingEmployeeReportieedataLoading = useAppSelector((state: any) => state?.getReporteesById?.loading);
    const assignDndByEmpIdLoading = useAppSelector((state: any) => state?.assignDndByEmpId?.loading);


    const getUserDndResList = getUserDndResListData?.dndresponsibility?.map((item: any) => {

        return {
            id: item?._id,
            resId: item?.responsibilityFKId?._id,
            label: item?.responsibilityFKId.label,
            classification: item?.responsibilityFKId?.classificationFKId,
            scope: item?.responsibilityFKId?.scope,
            frequency: item?.responsibilityFKId?.frequencyFKId,
            duaration: item?.responsibilityFKId?.duration,
            bgColorCode: item?.responsibilityFKId?.bgColorCode,
            textColorCode: item?.responsibilityFKId?.textColorCode
        }
    })

    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [popupSearch, setPopupSearch] = useState("");
    const [addMapId, setAddMapId] = useState<any>([])
    const [divisionId, setDivision] = useState("")
    const [deptId, setDept] = useState("")
    const [showData, setShowData] = useState([])



    const { handleSubmit, register, formState: { errors }, setValue, clearErrors, reset } = useForm()

    useEffect(() => {
        if (userProps?.employeeId && divisionId && deptId) {
            const data: any = {
                userId: userProps?.employeeId,
                search: "",
            }
            data["division"] = divisionId;
            data["department"] = deptId;
            dispatch(getReporteesMappingByIdThunk(data))
        }
        if (deptId && divisionId) {
            const data = { "department": deptId, "division": divisionId, search: "" }
            dispatch(getUserDndResponsibilityThunk(data))
        }

    }, [divisionId, deptId, userProps]);


    useEffect(() => {
        if (mappingEmployeeReportieedata?.responsibility) {
            const Select = mappingEmployeeReportieedata?.responsibility?.filter((item: any) => item.dndFKId).map((item: any) => item.dndFKId)
            setAddMapId(Select)
        }
    }, [mappingEmployeeReportieedata]);

    useEffect(() => {
        const data: any = {}
        data["data"] = { search: searchValue, status: "ACTIVE" }
        data["currentPage"] = 0
        data["pageSize"] = 0
        dispatch((mapResponsibilityThunk(data)))
        dispatch(getDivisionThunk(data));
        dispatch(getDepartmentThunk(data))
    }, []);

    const mapAssicate = (id: any) => {
        if (!addMapId.includes(id)) {
            setAddMapId([...addMapId, id]);
        } else {
            setAddMapId(addMapId.filter((empId: string) => empId !== id));
        }

    }

    const mappingSuccess = () => {
        toggle()
        dispatch(clearReporteesById())
        const data: any = {}

        setAddMapId([])
    }

    const assignEmp = () => {
        if (userProps?.employeeId) {
            let data = {
                data: {
                    associateFKId: userProps?.employeeId,
                    dndFKId: addMapId,
                    "department": deptId,
                    "division": divisionId,

                },
                afterSuccess: mappingSuccess
            };
            dispatch(assignDndByEmpIdThunk(data))

        }
    }

    const handleChange = ({ data, key }: any) => {
        if (key === "Responsibilities") {
            setDivision(data?._id)
            setPopupSearch("")
        }
        if (key === "ResponsibilitiesMapped") {
            setDept(data?._id)
            setPopupSearch("")
        }

    }

    useEffect(() => {
        if (getUserDndResList) {
            const filterData = getUserDndResList?.filter((item: any) => {
                if (popupSearch) {
                    if (
                        item.label?.toLowerCase().includes(popupSearch.trim().toLowerCase()) ||
                        item.frequency?.toLowerCase().includes(popupSearch.trim().toLowerCase()) ||
                        item.classification?.toLowerCase().includes(popupSearch.trim().toLowerCase()) ||
                        item.scope?.toLowerCase().includes(popupSearch.trim().toLowerCase())
                    ) {
                        return item
                    }
                    else {
                        return false
                    }
                }
                else return item
            });
            setShowData(filterData)
        }
    }, [getUserDndResListData, popupSearch])


    return (
        <Modal size="lg" isOpen={showModal} centered={true}
            className='map-responisibilities-model'
            toggle={() => toggle()}
        >
            <div className="modal-header">
                <h1 className='title'>{`Map responsibilities`}</h1>
                <span onClick={() => toggle()} aria-hidden="true" className='close-icon'><img src={closeIcon} alt="close" /></span>
            </div>
            <div className="actions">
                <div className="dropdown-group">
                    <DropDown loading={getDivisionListLoader} onChange={(data: any) => { handleChange({ data, key: "Responsibilities" }) }} options={getDivisionList} mainClass="filter-dropdown" placeholder="Division" />
                    <DropDown loading={getDepartmentListLoader} onChange={(data: any) => { handleChange({ data, key: "ResponsibilitiesMapped" }) }} options={getDepartmentList} mainClass="filter-dropdown" placeholder="Department" />
                </div>
                <InputSearchModal search={popupSearch} setSearch={setPopupSearch} />
            </div>


            {
                divisionId && deptId ?
                    <>
                        {
                            getUserDndResLoading || mappingEmployeeReportieedataLoading === true ?
                                <div className="modal-body ">
                                    <div className="form-inputs">
                                        <ResponsibilitiesMappingSkeleton datacount={4} />
                                    </div>
                                </div>
                                :
                                <div className="modal-body custom-scroll">
                                    <form autoComplete="off">
                                        <div className="form-inputs">
                                            {
                                                showData?.length > 0 ?
                                                    showData?.map((item: any, index: number) => {
                                                        const condition = addMapId?.includes(item?.id)
                                                        return <Card key={`responsibility-card-${index}`}>
                                                            <Label htmlFor={item.resId}>
                                                                <CardBody>
                                                                    <div>
                                                                        <h2>{item?.label}</h2>
                                                                        <div className="badges p-2 ps-0">
                                                                            <p className={`badge-md`}
                                                                                style={{
                                                                                    backgroundColor: `${item.bgColorCode}`
                                                                                }}>
                                                                                <span style={{ color: `${item.textColorCode}` }}>
                                                                                    {item?.classification}</span></p>
                                                                            <p className="badge-normal">{item?.frequency}</p>
                                                                        </div>
                                                                        <div>
                                                                            <h2 className="time-duration">Duration:<span className="time">{item?.duaration} <span className="hr-min">(HR:MIN)</span></span></h2>
                                                                        </div>
                                                                        <h2 className="description-head">Description</h2>
                                                                        <p className="description-content">{item?.scope}</p>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        {
                                                                            condition === true ?
                                                                                <input className="form-check-input" type="checkbox" value={index} checked id={item?.resId}
                                                                                    // {...register('responsibility')}
                                                                                    onChange={() => mapAssicate(item?.id)} />
                                                                                :
                                                                                <input className="form-check-input" type="checkbox" value={index} id={item?.resId}
                                                                                    // {...register('responsibility')}
                                                                                    onChange={() => mapAssicate(item?.id)} />
                                                                        }
                                                                        <label className="form-check-label" htmlFor={item?.resId}>
                                                                        </label>
                                                                    </div>
                                                                </CardBody>
                                                            </Label>
                                                        </Card>
                                                    })
                                                    :
                                                    <div className="modal-body ">
                                                        <div className="form-inputs">
                                                            <NoData />
                                                        </div>
                                                    </div>

                                            }
                                        </div>
                                        {
                                            showData?.length > 0 &&
                                            <div className="form-footer">
                                                <SecondaryButton btnName="Cancel" onClick={() => toggle()} />
                                                <PrimaryButton loader={assignDndByEmpIdLoading} btnType="button" btnName="Submit" onClick={assignEmp} />
                                            </div>
                                        }
                                    </form>
                                </div>
                        }

                    </>
                    :
                    <div className="modal-body ">
                        <div className="form-inputs">
                            <InfoCard info={divisionId && deptId ? "" : <p>  Please select {!divisionId && "division"} {!divisionId && !deptId && "and"} {!deptId && "department"} </p>} />
                        </div>
                    </div>
            }


        </Modal >
    )
}

export default MapResponsibilitiesModal