import { createSlice } from "@reduxjs/toolkit";
import { defaultState } from "../../../../interfaces/auth";
import { getFrequencyThunk } from "../../../../asyncThunks/manage/setups";

const initialState: defaultState = {
    error: null,
    data: [],
    loading: false,
};

export const getFrequncy = createSlice({
    name: "getFrequncy",
    initialState,
    reducers: {
        updateFrequencyData: (state, action) => {
            if (state.data) {
                const updateFrequency = action.payload;
                const index = state.data?.frequency?.findIndex((frequency: { _id: any; }) => frequency._id === updateFrequency._id);
                if (index !== -1) {
                    state.data.frequency[index] = updateFrequency;
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getFrequencyThunk.pending, (state, action) => {
            state.error = null;
            // state.data = null;
            state.loading = true;
        });
        builder.addCase(getFrequencyThunk.fulfilled, (state, action) => {
            if (action?.payload?.status?.toLowerCase() === "success") {
                state.error = null;
                state.data = action?.payload?.data;
            } else {
                state.error = action?.payload;
                state.data = [];
            }
            state.loading = false;
        });
        builder.addCase(getFrequencyThunk.rejected, (state, action) => {
            state.error = action.payload;
            state.data = [];
            state.loading = false;
        });
    },
});

export const { updateFrequencyData } = getFrequncy.actions;

export default getFrequncy.reducer;
