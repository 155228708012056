import { Card, CardBody } from "reactstrap"
import PageTitle from "../../components/PageTitle"
import ChangePassword from "./components/ChangePassword"
import Profile from "./components/Profile"
import ProfilePicture from "./components/ProfilePicture"
import { useEffect } from "react"
import { getProfileImgThunk } from "../../store/asyncThunks/profile/Myprofile"
import { useAppDispatch, useAppSelector } from "../../store/store"


const MyProfile = () => {

    const dispatch = useAppDispatch();

    const userData = useAppSelector((state) => state?.decodeTokenSliceReducer?.data);
    const profileImgKey = useAppSelector((state) => state?.getProfileImgReducer?.data?.data?.key);
    const updateImageToken = useAppSelector((state) => state?.updateProfileImgReducer?.data?.data?.token);

    useEffect(() => {
        if (userData && userData?.profileImg) {
            const data: any = {};
            data['payload'] = { key: userData?.profileImg };
            dispatch(getProfileImgThunk(data));
        }
    }, [userData])

    return (
        <>
            <PageTitle title={`My profile`} />
            <div className="my-profile-wrapper">
                <Card>
                    <ProfilePicture userData={userData} profileImgKey={profileImgKey} updateImageToken={updateImageToken} />
                    <Profile userData={userData} />
                    <ChangePassword />
                </Card>
            </div>
        </>
    )
}

export default MyProfile