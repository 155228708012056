import { useEffect, useMemo, useState } from 'react';
import arrow from '../../../assets/images/svgIcons/accordian_arrow.svg';
import InputSearch from '../../../components/InputSearch';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { getdepartmentThunk } from '../../../store/asyncThunks/scope/scope';
import TableWithCardLoader from '../../../components/TableWithCardLoader';
import { Card } from 'reactstrap';
interface DepartmentScopeProps {
    print?: any;
    expandTableRow?: Boolean;

}
const DepartmentScope = (props: DepartmentScopeProps) => {
    const { print, expandTableRow } = props

    const dispatch = useAppDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const pageSize = process.env.REACT_APP_PAGE_SIZE || 10;
    const departmentState = useAppSelector((state) => state.getdepartmentReducer?.data?.data);
    const departmentStateLoading = useAppSelector((state) => state.getdepartmentReducer?.loading);

    const handleSearch = (value: any) => {
        setCurrentPage(1)
        setSearchValue(value);
    }

    useEffect(() => {
        const data: any = {}
        data["data"] = { search: searchValue, status: "ACTIVE" }
        data["currentPage"] = currentPage
        data["pageSize"] = pageSize
        dispatch(getdepartmentThunk(data));
    }, [searchValue, currentPage]);

    const columns = useMemo(
        () => [
            {
                Header: 'List of department',
                accessor: '',
                Cell: (cell: any) => {
                    return <div className='label-cell'>{cell?.row?.original?.label}</div>;
                },
            },
            {
                accessor: 'search',
                Cell: (cell: any) => {
                    return <div className='actions'>
                        <div className={`arrow d-flex justify-content-end`}>
                            <img src={arrow} alt="arrow" />
                        </div>
                    </div>
                },
            },
        ], [departmentState]);

    const subTable = (row: any) => {
        const { scope } = row.original
        return (
            <>
                <div className="d-scope">
                    <p className="description">{scope}</p>
                </div>
            </>
        )
    }
    return (
        <>
            <div ref={print}  className='department-scope'>
                <div className='table-search'>
                    <InputSearch onSearch={handleSearch} value={searchValue} />
                </div>
                <Card className={departmentState?.departments == 0 || !departmentState ? `loader-card` : ""} >
                    <TableWithCardLoader
                        data={departmentState?.departments || []}
                        columns={columns}
                        totalCount={departmentState?.totalCount}
                        customPageSize={Number(pageSize)}
                        dynamicPagination={true}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        renderRowSubComponent={subTable}
                        isExpanded={true}
                        expandAll={expandTableRow}
                        loader={departmentStateLoading}
                    />
                </Card>
            </div>
        </>
    )
}
export default DepartmentScope