import { BtnProps } from './ButtonInterface';
import loadingIcon from "./../../assets/images/svgIcons/loading_icon.svg";

const PrimaryButton = (props: BtnProps) => {
  const { className = '', btnName, btnType = "submit", onClick, loader, disabled = false, ...rest } = props;
  return (
    <button type={btnType} className={`custom-btn primary-btn ${className}`} onClick={(e) => {
      if (onClick && !loader) {
        onClick(e)
      }
    }} disabled={disabled} {...rest}>
      {loader ? <span className="spinner"><img src={loadingIcon} alt="Loading" /></span> : btnName}
    </button>
  )
}

export default PrimaryButton  