// import DatePicker from "react-datepicker";
import calendar from '../assets/images/svgIcons/calendar_icon.svg';
import { Label } from "reactstrap";
import DatePicker, { DateObject } from "react-multi-date-picker";

interface datepickerProps {
    selected: Date | DateObject | null | undefined,
    onChange: Function,
    className?: string,
    id?: string,
    placeholder?: string,
    tab?: any,
    calendar?: any,
}

const Datepicker = (props: datepickerProps) => {
    const { selected, onChange, className = '', id = '', placeholder, tab } = props;
 
    return (
        <div className="date-picker-wrapper">
            <DatePicker
                inputClass={`date-input rmdp-input ${className}`}
                value={selected}
                onChange={(e: DateObject | DateObject[] | null) => onChange(e)}
                format="DD/MM/YYYY"
                maxDate={new Date()}
                placeholder={placeholder}
                className={`w-100`}
                id={`datepicker${id}`}
            />
            <Label htmlFor={`datepicker${id}`} className='calendar-icon'><img src={calendar} alt="calendar" /></Label>
        </div>
    )
}

export default Datepicker