import sampleImg from '../../../assets/images/svgImages/avatar.svg';
import editIcon from '../../../assets/images/svgIcons/edit-white.svg';
import { useAppDispatch } from '../../../store/store';
import toast from 'react-hot-toast';
import { getProfileImgThunk, updateProfileImgThunk } from '../../../store/asyncThunks/profile/Myprofile';
import { jwtDecode } from 'jwt-decode';
import { setToken } from '../../../helpers/api_helper';
import { decodeToken } from '../../../store/slices/auth/decodeToken';

interface userDataProps {
    name?: string;
    email?: string;
    role?: string;
}

interface userprops {
    userData: userDataProps;
    profileImgKey: string;
    updateImageToken: string;
}

const ProfilePicture = (props: userprops) => {
    const { userData, profileImgKey, updateImageToken } = props;

    const dispatch = useAppDispatch();

    const afterUpdateImage = (updateImageToken: any) => {
        setToken();
        const decoded: any = jwtDecode(updateImageToken);
        dispatch(decodeToken(decoded));
        if (decoded?.profileImg) {
            const data: any = {};
            data['payload'] = { key: decoded?.profileImg };
            dispatch(getProfileImgThunk(data));
        }
    };

    const handleProfileImg = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const size = e.target.files[0].size / 1024 / 1024;
            if (size < 1) {
                if (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/png') {
                    const data: any = {};
                    data['payload'] = { files: e.target.files };
                    data['afterUpdateImage'] = afterUpdateImage;
                    dispatch(updateProfileImgThunk(data));
                } else {
                    toast.error("Supported file formats are PNG or JPEG")
                }
            } else {
                toast.error("Max allowed file size is 1 MB")
            }
        }
    }

    return (
        <div className='profile-pic-section'>
            <div className='avatar-image'>
                {/* <label htmlFor="profileUpload" className='img-overlay'>
                    <img src={editIcon} alt='editIcon' />
                </label> */}
                <div className='pro-pic'>
                    {/* <img src={profileImgKey || sampleImg} alt='sampleImg' /> */}
                    <p className='profile-circle'>{userData?.name?.charAt(0).toUpperCase()}</p>
                    {<input type="file"
                        id="profileUpload"
                        accept="image/jpg,image/jpeg,image/png"
                        name="profileUpload"
                        hidden
                        onChange={(e) => handleProfileImg(e)}
                    />}
                </div>
            </div>
            <div className="profile-details">
                <h1 className='name text-capitalize'>{userData?.name}</h1>
                <p className="email">{userData?.email}</p>
                <p className="badge-normal w-auto">{userData?.role?.toLowerCase()}</p>
            </div>
        </div>
    )
}

export default ProfilePicture