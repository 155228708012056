import { createSlice } from "@reduxjs/toolkit";
import { defaultState } from "../../../../interfaces/auth";
import { changestatusRoleThunk } from "../../../../asyncThunks/manage/setups";

const initialState: defaultState = {
    error: null,
    data: null,
    loading: false,
};

export const changeStatusRole = createSlice({

    name: "changeStatusRole",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(changestatusRoleThunk.pending, (state, action) => {
            state.error = null;
            state.data = null;
            state.loading = true;
        });
        builder.addCase(changestatusRoleThunk.fulfilled, (state, action) => {
            if (action?.payload?.status?.toLowerCase() === "success") {
                state.error = null;
                state.data = action.payload;
            } else {
                state.error = action?.payload;
                state.data = null;
            }
            state.loading = false;
        });
        builder.addCase(changestatusRoleThunk.rejected, (state, action) => {
            state.error = action.payload;
            state.data = null;
            state.loading = false;
        });
    },
});

export default changeStatusRole.reducer;
