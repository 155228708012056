import { createSlice } from "@reduxjs/toolkit";
import { defaultState } from "../../../../interfaces/auth";
import { getClassificationThunk } from "../../../../asyncThunks/manage/setups";

const initialState: defaultState = {
    error: null,
    data: [],
    loading: false,
};

export const getClassification = createSlice({
    name: "getClassification",
    initialState,
    reducers: {
        updateClassificationData: (state, action) => {
            const updateClassification = action.payload;
            if (state.data) {
                const index = state.data?.classification?.findIndex((classification: { _id: any; }) => classification._id === updateClassification._id);
                if (index !== -1) {
                    state.data.classification[index] = updateClassification;
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getClassificationThunk.pending, (state, action) => {
            state.error = null;
            // state.data = null;
            state.loading = true;
        });
        builder.addCase(getClassificationThunk.fulfilled, (state, action) => {
            if (action?.payload?.status?.toLowerCase() === "success") {
                state.error = null;
                state.data = action.payload?.data;
            } else {
                state.error = action?.payload;
                state.data = [];
            }
            state.loading = false;
        });
        builder.addCase(getClassificationThunk.rejected, (state, action) => {
            state.error = action.payload;
            state.data = [];
            state.loading = false;
        });
    },
});

export const { updateClassificationData } = getClassification.actions;

export default getClassification.reducer;
