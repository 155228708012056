import { createSlice } from "@reduxjs/toolkit";
import { defaultState } from "../../interfaces/auth";
import { loginThunk } from "../../asyncThunks/auth";

const initialState: defaultState = {
  error: null,
  data: null,
  loading: false,
};

export const login = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginThunk.pending, (state, action) => {
      state.error = null;
      state.data = null;
      state.loading = true;
    });
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      if (action?.payload?.status?.toLowerCase() === "success") {
        state.error = null;
        state.data = action.payload;
      } else {
        state.error = action?.payload;
        state.data = null;
      }
      state.loading = false;
    });
    builder.addCase(loginThunk.rejected, (state, action) => {
      state.error = action.payload;
      state.data = null;
      state.loading = false;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {  } = login.actions;

export default login.reducer;
