import React, { SetStateAction } from 'react';
import { useEffect, useMemo, useState } from 'react';
import TableContainer from '../../../components/Table/TableContainer';
import ToggleSwitch from '../../../components/ToggleSwitch';
import edit from '../../../assets/images/svgIcons/edit_iocn.svg';
import InputSearch from '../../../components/InputSearch';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import ManageRoleModal from '../../../components/Models/ManageSetupsModalComponent/ManageRoleModal';
import { changestatusRoleThunk, getRoleThunk } from '../../../store/asyncThunks/manage/setups';
import ConfirmationModal from '../../../components/Models/ConfirmationModal';
import TableSkeleton from '../../../components/skeleton/TableSkeleton';
import { updateRoleData } from '../../../store/slices/manage/setups/role/getRole';
import { Card } from 'reactstrap';
import TableWithCardLoader from '../../../components/TableWithCardLoader';

interface RoleProps {
    currentPage: number;
    setCurrentPage: SetStateAction<any>;
    reset: number
}

const Roles = (props: RoleProps) => {
    const { currentPage, setCurrentPage, reset } = props;

    document.title = `Setups | ${process.env.REACT_APP_DOCUMENT_TITLE}`;

    const dispatch = useAppDispatch();

    const [searchValue, setSearchValue] = useState("");
    const [EditData, setEditData] = useState<any>();
    const [statusData, setStatusData] = useState<any>(null);
    const [showModal, setshowModal] = useState(true);
    const [statusShowModal, setstatusShowModal] = useState(true);
    const pageSize = process.env.REACT_APP_PAGE_SIZE || 10;
    const getRoleList = useAppSelector((state) => state?.getRoleReducer?.data?.role);
    const getRoleLoading = useAppSelector((state) => state?.getRoleReducer?.loading);
    const RoleCount = useAppSelector((state) => state?.getRoleReducer?.data?.totalCount);
    const RoleStatusLoader = useAppSelector((state) => state?.chnageStatusRoleReducer?.loading);

    const handleSearch = (value: any) => {
        setSearchValue(value);
        setCurrentPage(1);
    }

    useEffect(() => {
        getData()
    }, [searchValue, currentPage, reset]);

    const getData = async () => {
        const data: any = {}
        data["currentPage"] = currentPage
        data["pageSize"] = pageSize
        data["data"] = { search: searchValue }
        await dispatch(getRoleThunk(data));
    }

    const toggleModal = () => {
        setshowModal(!showModal);
    };

    const toggleStatusModal = () => {
        setstatusShowModal(!statusShowModal);
    };

    const handleEditClick = (Rowdata: any) => {
        setEditData(Rowdata);
        setshowModal(true);
    };

    const handleToggleSwitch = (Rowdata: any) => {
        setStatusData(Rowdata);
        setstatusShowModal(true);
    }

    const RoleUpdateStatus = () => {
        const status = {
            status: statusData?.status === "INACTIVE" ? "ACTIVE" : "INACTIVE",
        }
        const data: any = {
            data: status,
            id: statusData?._id,
            afterSuccess: (newStatus: any) => {
                dispatch(updateRoleData(newStatus));
                setstatusShowModal(false);
            },
        };
        dispatch(changestatusRoleThunk(data));
    };

    const handleModalPagination = () => {
        getData();
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'firstName',
                Cell: (cell: any) => {
                    return <div className='label-cell'>{cell?.row?.original?.label}</div>;
                },
            },
            {
                Header: 'Description',
                accessor: 'description',
                Cell: (cell: any) => {
                    return <div className='description-cell'>{cell?.row?.original?.scope}</div>;
                },
            },
            {
                Header: 'Status',
                Cell: (cell: any) => {
                    const Rowdata = cell?.row?.original;
                    const roleHR = cell?.row?.original?.label === "HR" ? true : false;

                    return <ToggleSwitch id={cell.row.index} switchStyle={{ cursor: roleHR ? 'no-drop' : 'pointer', opacity: roleHR ? 0.6 : 1 }} disabled={roleHR ? true : false} defaultChecked={cell.row.original.status?.toUpperCase() === "ACTIVE" ? true : false}
                        onChange={(e: any) => {
                            handleToggleSwitch(Rowdata)
                        }} />
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: (cell: any) => {
                    const Rowdata = cell?.row?.original;
                    return <div className='actions'>
                        <div className="edit" onClick={() => handleEditClick(Rowdata)}>
                            <img src={edit} alt="edit" />
                        </div>
                    </div>
                }
            },
        ], [getRoleList]);

    return (
        <>
            <div className='table-search'>
                <InputSearch onSearch={handleSearch} value={searchValue} />
            </div>
            <Card className={!getRoleList ? `loader-card` : ""} >
                <TableWithCardLoader
                    data={getRoleList || []}
                    columns={columns}
                    loader={getRoleLoading}
                    totalCount={RoleCount}
                    customPageSize={Number(pageSize)}
                    dynamicPagination={true}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </Card>
            {EditData && (
                <ManageRoleModal
                    showModal={showModal}
                    toggle={toggleModal}
                    mode='Edit'
                    EditData={EditData}
                    handleModalPagination={handleModalPagination}
                />
            )}
            {statusData && (
                <ConfirmationModal
                    showModal={statusShowModal}
                    toggle={toggleStatusModal}
                    secondaryAction={toggleStatusModal}
                    modalTitle={`Confirmation!`}
                    primaryAction={RoleUpdateStatus}
                    loader={RoleStatusLoader}
                    primaryBtnName={`${statusData?.status === "INACTIVE" ? 'Activate' : 'Deactivate'}`}
                    secoundaryBtnName="Cancel"
                    description={`Are you sure, do you want to  ${statusData?.status === "ACTIVE" ? 'deactivate' : 'activate'} this role  "${statusData?.label} "?`} />
            )}
        </>
    )
}

export default Roles