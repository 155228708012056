import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { useEffect, useState } from "react";
import accessToken from "../helpers/jwt-token-access/accessToken";
import { customNavigation, setToken, tokenKey } from "../helpers/api_helper";
import { jwtDecode } from "jwt-decode";
import { useAppDispatch, useAppSelector } from "../store/store";
import { decodeToken } from "../store/slices/auth/decodeToken";
import { getProfileImgThunk } from "../store/asyncThunks/profile/Myprofile";
import Interceptor from "../helpers/Interceptor";

const Layout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const accessToken = localStorage.getItem(tokenKey);
  const setNav = customNavigation(navigate)
  const [isAuthenticated, setSsAuthenticated] = useState(false);


  const [openMenu, setOpenMenu] = useState(false)
  const [isShortMenu, setIsShortMenu] = useState(false);

  useEffect(() => {
    let width = window.innerWidth;
    if (width <= 1200 && width >= 768) {
      setIsShortMenu(true);
    } else {
      setIsShortMenu(false);
    }
  }, [])

  useEffect(() => {
    if (!accessToken) {
      navigate('/signin');
    } else if (accessToken) {
      setToken();
      const decoded: any = jwtDecode(accessToken);
      dispatch(decodeToken(decoded));
      //updateprofile
      if (decoded && decoded?.profile) {
        const data: any = {};
        data['payload'] = { key: decoded?.profile };
        dispatch(getProfileImgThunk(data))
      }
      //signin
      if (decoded?.exp && decoded) {
        if (Number(decoded?.exp) * 1000 < Date.now()) {
          localStorage.removeItem(tokenKey)
          navigate('/signin') //Token has expired'
          return;
        }
      }
      setSsAuthenticated(true);
    }
  }, [accessToken])

  return (
    <>
      <Interceptor />
      {isAuthenticated && <>
        <Header openMenu={openMenu} setOpenMenu={setOpenMenu} isShortMenu={isShortMenu} />
        <div className="main-container">
          <Sidebar openMenu={openMenu} setOpenMenu={setOpenMenu} isShortMenu={isShortMenu} setIsShortMenu={setIsShortMenu} />
          <div className="main-content custom-scroll">
            <div className="page-content">
              <Outlet />
            </div>
          </div>
        </div>
        <Footer />
      </>}
    </>
  );
};

export default Layout;
