import { ChangeEvent, useEffect, useState } from 'react'
import MappingSelection from './subComponent/MappingSelection'
import ResponsibilitiesMapping from './subComponent/ResponsibilitiesMapping'
import SecondaryButton from '../../../components/buttons/SecondaryButton'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { addDndResponsibilitiesThunk, getDndResponsibilitiesThunk } from '../../../store/asyncThunks/manage/mapping'
import { getDepartmentThunk } from '../../../store/asyncThunks/manage/setups'
import { useForm } from 'react-hook-form'
import MappingSelectionSkeleton from '../../../components/skeleton/MappingSelectionSkeleton'
import ResponsibilitiesMappingSkeleton from '../../../components/skeleton/ResponsibilitiesMappingSkeleton'
import InfoCard from '../../../components/card/InfoCard'
import TableSkeleton from '../../../components/skeleton/TableSkeleton'

const Responsibility = () => {
    const dispatch = useAppDispatch();

    const [divisionSelected, setDivisionSelected] = useState('');
    const [departmentSelected, setDepartmentSelected] = useState('');
    const [resetDeapartmentSearchValue, setResetDeapartmentSearchValue] = useState(false);
    const [resetResponsibilitySearchValue, setResetResponsibilitySearchValue] = useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [departmentSearchVal, setDepartmentSearchVal] = useState("");

    const getDivisionList = useAppSelector((state) => state?.getDivisionReducer?.data?.divisions);
    const getDepartmentList = useAppSelector((state) => state?.getDepartmentReducer?.data?.departments);
    const getDndResponsibilitiesList = useAppSelector((state) => state?.getDndResponsibilitiesReducer?.data?.data);
    const addDndResponsibilitiesLoader = useAppSelector((state) => state?.addDndResponsibilitiesReducer?.loading);
    const getDivisionLoading = useAppSelector((state) => state?.getDivisionReducer?.loading);
    const getDepartmentLoading = useAppSelector((state) => state?.getDepartmentReducer?.loading);
    const getResponsibilityLoading = useAppSelector((state) => state?.getResponsibilityReducer?.loading);
    const getDndResponsibilitiesLoading = useAppSelector((state) => state?.getDndResponsibilitiesReducer?.loading);

    const { register, handleSubmit, reset, getValues } = useForm();

    const setDivisionValue = (e: ChangeEvent<HTMLInputElement>) => {
        setDivisionSelected(e?.target?.value);
        setDepartmentSelected('');
        if (departmentSearchVal) {
            departmentSearch("");
        }
        setResetDeapartmentSearchValue(true);
        setTimeout(() => {
            setResetDeapartmentSearchValue(false);
        }, 200);
    }

    const getResponsibility = (e: ChangeEvent<HTMLInputElement>) => {
        setResetResponsibilitySearchValue(true);
        setTimeout(() => {
            setResetResponsibilitySearchValue(false);
        }, 200);
        reset();
        setDepartmentSelected(e?.target?.value);
        getResponsibilitiesfunc(divisionSelected, e?.target?.value)
    }

    const departmentSearch = (val: string) => {
        const data = {
            currentPage: 0,
            pageSize: 0,
            data: {
                search: val,
                status: "ACTIVE"
            }
        }
        dispatch(getDepartmentThunk(data));
    }

    const onSubmit = (val: any) => {
        let data = {
            data: {
                divisionFKId: divisionSelected,
                departmentFKId: departmentSelected,
                responsibilityFKId: val?.responsibility ? val?.responsibility : []
            },
            afterSuccess: afterSuccess
        };

        dispatch(addDndResponsibilitiesThunk(data))
    }

    const afterSuccess = () => {
        getResponsibilitiesfunc(divisionSelected, departmentSelected, "", searchVal)
    }

    function getResponsibilitiesfunc(division: string, department: string, search?: string, searchVal?: string) {
        if (!searchVal) {
            setSearchVal("");
        }
        const values = {
            division: division,
            department: department,
            search: searchVal || search || ""
        };
        dispatch(getDndResponsibilitiesThunk(values))
    }

    const responsibilitySearch = (val: string) => {
        getResponsibilitiesfunc(divisionSelected, departmentSelected, val)
        setSearchVal(val)
    }

    const clearForm = () => {
        setDivisionSelected('');
        setDepartmentSelected('');
        setSearchVal('');
        reset();
    }


    return (
        <>
            <form>
                <div className='reponssibility-grp'>
                    <div className='h-100'>
                        <MappingSelection
                            mappingHead={"Divisions"}
                            mappingdata={getDivisionList}
                            onChange={setDivisionValue}
                            isOverLay={getDndResponsibilitiesLoading ? true : false}
                            selected={divisionSelected}
                            isSkeleton={getDivisionLoading || !getDivisionList}
                        />
                    </div>
                    <div className='h-100 '>
                        {divisionSelected ? (
                            <MappingSelection
                                mappingHead={"Departments"}
                                mappingdata={getDepartmentList}
                                search={true}
                                onChange={getResponsibility}
                                selected={departmentSelected}
                                departmentSearch={departmentSearch}
                                resetValue={resetDeapartmentSearchValue}
                                isOverLay={(divisionSelected ? false : true) || (getDndResponsibilitiesLoading ? true : false)}
                                isSkeleton={getDepartmentLoading || !getDepartmentList}
                            />
                        ) : (
                            <>
                                <div className='departmentinfo-card h-100'>
                                    <InfoCard info={divisionSelected ? "" : <p>  Please select {!divisionSelected && "division"} </p>} />
                                </div>
                            </>
                        )}
                    </div>
                    <div className='h-100 res-mapping'>
                        {
                            divisionSelected && departmentSelected ?
                                <ResponsibilitiesMapping
                                    mappingHead={"Responsibilities"}
                                    mappingdata={getDndResponsibilitiesList}
                                    register={register}
                                    resetValue={resetResponsibilitySearchValue}
                                    responsibilitySearch={responsibilitySearch}
                                    searchVal={searchVal}
                                    isSkeleton={getDndResponsibilitiesLoading}
                                /> :
                                <InfoCard info={divisionSelected && departmentSelected ? "" : <p>  Please select {!divisionSelected && "division"} {!divisionSelected && !departmentSelected && "and"} {!departmentSelected && "department"} </p>} />
                        }
                    </div>
                </div>
                <div className="submit-actions">
                    <SecondaryButton btnName='Cancel' btnType='button' className='cancel-btn' onClick={clearForm} disabled={(!divisionSelected   || getDndResponsibilitiesLoading) ? true : false} />
                    <PrimaryButton btnName='Save' btnType='button' loader={addDndResponsibilitiesLoader} disabled={(!divisionSelected || !departmentSelected || getDndResponsibilitiesLoading) ? true : false}
                        onClick={() => {
                            onSubmit(getValues());
                        }}
                    />
                </div>
            </form>
        </>
    )
}

export default Responsibility