import { SetStateAction, useEffect, useMemo, useState } from 'react'
import TableContainer from '../../../../components/Table/TableContainer';
import arrow from '../../../../assets/images/svgIcons/accordian_arrow.svg';
import avatar from '../../../../assets/images/svgImages/avatar.svg';
import filterIcon from '../../../../assets/images/svgIcons/filter.svg';
import InputSearch from '../../../../components/InputSearch';
import FilterDropDown from '../../../../components/FilterDropDown';
import AssignEmployees from '../../../../components/Models/AssignEmployees';
import InfoCard from '../../../../components/card/InfoCard';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import TableSkeleton from '../../../../components/skeleton/TableSkeleton';
import { Card } from 'reactstrap';
import TableWithCardLoader from '../../../../components/TableWithCardLoader';
import { getEmployeesThunk, getAllEmployeesThunk } from '../../../../store/asyncThunks/manage/mapping';
interface ResponsibilitySubComponent {
    divsonId?: string;
    deptId?: string;
    showData?: any;
    onSearch?: (data: any) => void;
    searchVal?: string | any;
    selectDivison?: any;
    alreadySelected?: any;
    popup?: SetStateAction<any>
    getResponsibilities: Function
    setSortBy?: SetStateAction<any>,
    sortBy?: object,
    filterValue?: any[];
    setFilterValue?: SetStateAction<any>;
    lists?: any;
    getDivisionListLoader?: boolean;
    getDepartmentListLoader?: boolean
    currentPage:number;
    setCurrentPage:number;
    pageSize:string | number;
    restInput: boolean;
}

const ResponsibilitySubComponent = (props: ResponsibilitySubComponent) => {

    const {
        deptId,
        divsonId,
        showData,
        searchVal,
        onSearch,
        selectDivison,
        alreadySelected,
        popup,
        getResponsibilities,
        setSortBy,
        sortBy,
        filterValue,
        setFilterValue,
        lists,
        getDepartmentListLoader,
        getDivisionListLoader,
        currentPage,
        setCurrentPage,
        pageSize,
        restInput
    } = props

    const responsibiityLoading = useAppSelector((state) => state?.getDivisionResponsibilityReducer?.loading);
    const [showAssignEmployeeModal, setShowAssignEmployeeModal] = useState(false);
    const totalDivResponsibilityCount = useAppSelector((state) => state?.getDivisionResponsibilityReducer?.data?.data?.totalCount);

    const dispatch = useAppDispatch()

    const columns = useMemo(
        () => [
            {
                Header: 'List of responsibilities',
                accessor: 'responsibilities',
                sort: true
            },
            {
                Header: 'Classification',
                sort: true,
                Cell: (cell: any) => {
                    return <div className={`badge-md`}
                        style={{ backgroundColor: `${cell?.row?.original?.bgColorCode}` }}>
                        <span style={{ color: `${cell?.row?.original?.textColorCode}` }}>
                            {cell.row.original?.classification}</span>
                    </div>
                }
            },
            {
                Header: 'Frequency',
                sort: true,
                accessor: "frequency"
            },
            {
                Header: 'Assigned employees',
                Cell: (cell: any) => {
                    const lengthOfAssignedEmployess = cell.row.original?.employees?.length;
                    const assignedEmployees = lengthOfAssignedEmployess > 0 ? cell.row.original?.employees.slice(0, 2) : [];
                    return <div className='profile-badge-wrapper  allow-max-width'>
                        {getAssignedEmployeeBages(assignedEmployees)}
                        <p className="plus" onClick={(e) => {
                            e.stopPropagation()
                            const selectedEmp = cell.row.original?.employees?.map((item: any) => item._id)
                            alreadySelected({ selectedEmp, add: true })
                            selectDivison(cell.row.original?.id)
                            popup(true)
                            toggleShowAssignEmployeeModal()
                        }}>+</p>
                        <p className="plus" onClick={(e) => {
                            e.stopPropagation()
                            const selectedEmp = cell.row.original?.employees?.map((item: any) => item._id)
                            alreadySelected({ selectedEmp, add: false })
                            selectDivison(cell.row.original?.id)
                            popup(true)
                            toggleShowAssignEmployeeModal()
                        }}>-</p>
                        {lengthOfAssignedEmployess > 2 &&
                            <p className="plus-count">+{lengthOfAssignedEmployess - 2}</p>
                        }
                    </div>
                }
            },
            {
                accessor: 'id',
                Cell: () => {
                    return <div className={`arrow`}>
                        <img src={arrow} alt="arrow" />
                    </div>
                }
            },
        ], []);

    const toggleShowAssignEmployeeModal = () => {
        setShowAssignEmployeeModal(!showAssignEmployeeModal)
    }

    const getAssignedEmployeeBages = (assignedEmployees: Array<any>) => {
        return assignedEmployees?.map((item: any, index: number) => {
            return <div className='profile-badge' key={`assigned-employee-badge-${index}`}>
                <div className='profile-img'>
                    {/* <img src={item?.profilePath
                        ? item?.profilePath
                        : avatar} alt="profile" /> */}
                    <p className='profile-circle-md  responsibilityprofile-circle'>{item?.name?.charAt(0).toUpperCase()}</p>
                </div>
                <p className='name'>{item?.name}</p>
            </div>
        })
    }

    const subTable = (row: any) => {
        const assignedEmployees = row.original?.employees;
        const description = row.original?.scope;
        return <>
            <p className="description">{description}</p>
            <h2>Assigned Employees</h2>
            <div className='profile-badge-wrapper'>
                {getAssignedEmployeeBages(assignedEmployees)}
            </div>
        </>
    }

    useEffect(() => {
        const data: any = { payload: { status: "Active" } };
        dispatch(getAllEmployeesThunk(data));
        getResponsibilities();
    }, [])

    return (
        <div className='responsibility-sub-component'>
            {divsonId && deptId &&
                <div className='reportee-filters pb-0 mt-3'>
                    <div className='table-search mb-0 w-100'>
                        <div className='d-flex align-items-center gap-3'>
                            <InputSearch onSearch={onSearch} value={searchVal} resetValue={restInput} />
                            {/* <FilterDropDown filterIcon={filterIcon} checkedValues={filterValue} setCheckedValues={setFilterValue} lists={lists} /> */}
                        </div>
                    </div>
                </div>
            }
            <div className="mt-3">
                {
                    divsonId && deptId ?
                        <Card className={showData?.length === 0 ? `loader-card` : ""} >
                            <TableWithCardLoader
                                data={showData}
                                columns={columns}
                                sortBy={sortBy}
                                setSortBy={setSortBy}
                                renderRowSubComponent={subTable}
                                isExpanded={true}
                                totalCount={totalDivResponsibilityCount}
                                customPageSize={Number(pageSize)}
                                dynamicPagination={true}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                loader={responsibiityLoading || getDivisionListLoader || getDepartmentListLoader}
                            />
                        </Card>
                        :
                        <InfoCard info={divsonId && deptId ? "" : <p>  Please select {!divsonId && "division"} {!divsonId && !deptId && "and"} {!deptId && "department"} </p>} />
                }
            </div>
        </div>
    )
}

export default ResponsibilitySubComponent