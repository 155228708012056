import { createSlice } from "@reduxjs/toolkit";
import { defaultState } from "../../interfaces/auth";  
import { getReporteesList } from "../../asyncThunks/overView/reportees";

const initialState: defaultState = {
    error: null,
    data: null,
    loading: false,
};

export const reporteesList = createSlice({
    name: "overView/reporteesList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getReporteesList?.pending, (state, action) => {
            state.error = null;
            // state.data = null;
            state.loading = true;
        });
        builder.addCase(getReporteesList?.fulfilled, (state, action) => { 
            if (action?.payload?.status?.toLowerCase() === "success") {
                state.error = null;
                state.data = action.payload;
            } else {
                state.error = action?.payload;
                state.data = null;
            }
            state.loading = false;
        });
        builder.addCase(getReporteesList?.rejected, (state, action) => {
            state.error = action.payload;
            state.data = null;
            state.loading = false;
        });
    },
});

export default reporteesList.reducer;
