import { createSlice } from "@reduxjs/toolkit";
import { defaultState } from "../../interfaces/auth";
import { getMyResponsibilityThunk } from "../../asyncThunks/overView/overview";

const initialState: defaultState = {
    error: null,
    data: null,
    loading: false,
};

export const getMyResponsibility = createSlice({
    name: "getMyResponsibilityThunk",
    initialState,
    reducers: {
        replaceMyResState: (state, action) => {
            if (state?.data?.data?.responsibility) {
                const index = state?.data?.data?.responsibility?.findIndex((item: any) => {
                    return item?.mapResId === action.payload?.mapResId
                })
                state.data.data.responsibility[index] = action.payload;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getMyResponsibilityThunk.pending, (state, action) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getMyResponsibilityThunk.fulfilled, (state, action) => {
            if (action?.payload?.status?.toLowerCase() === "success") {
                state.error = null;
                state.data = action.payload;
            } else {
                state.error = action?.payload;
                state.data = null;
            }
            state.loading = false;
        });
        builder.addCase(getMyResponsibilityThunk.rejected, (state, action) => {
            state.error = action.payload;
            state.data = null;
            state.loading = false;
        });
    },
});

export const { replaceMyResState } = getMyResponsibility.actions;

export default getMyResponsibility.reducer;
