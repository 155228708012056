import { useEffect, useRef, useState } from 'react'
import OutsideClick from '../../constants/OutsideClick';
import arrowdown from '../../assets/images/svgIcons/arrow_down.svg'
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { setToken, tokenKey } from '../../helpers/api_helper';
import { getProfileImgThunk } from '../../store/asyncThunks/profile/Myprofile';
import { jwtDecode } from 'jwt-decode';
import { decodeToken } from '../../store/slices/auth/decodeToken';

const Profile = () => {

  const navigate = useNavigate();

  const [isOpen, setisOpen] = useState(false);

  const dispatch = useAppDispatch();

  const wrapperRef = useRef<any>();

  const UserData = useAppSelector((state) => state?.decodeTokenSliceReducer?.data);
  const getProfileImg = useAppSelector((state) => state?.getProfileImgReducer?.data?.data?.key);

  useEffect(() => {
    if (UserData && UserData?.profileImg) {
      const data: any = {};
      data['payload'] = { key: UserData?.profileImg };
      dispatch(getProfileImgThunk(data));
    }
  }, [UserData])

  useEffect(() => {
    OutsideClick({ wrapperRef, setisOpen });
  }, []);

  const handleClick = (path: string) => {
    navigate(path);
    setisOpen(false);
  }

  return (
    <>
      <div ref={wrapperRef} className='profile' onClick={() => setisOpen(!isOpen)}>
        <div>
          <div className="profile-circle">
            {getProfileImg ?
              <img src={getProfileImg} width={40} alt='profile' /> :
              <span>{UserData?.name && UserData.name.charAt(0).toUpperCase()}</span>
            }

          </div>
        </div>
        <div className="profile-content">
          <div>
            <p className='name'>{UserData?.name || ""}</p>
            <p className='role'>{UserData?.role?.toLowerCase() || ""}</p>
          </div>
          <div className={`ms-2 arrow ${isOpen ? "opened" : ""}`}>
            <img src={arrowdown} alt="show-menu" />
          </div>
        </div>
        <div className={`dropdown ${isOpen ? "" : "d-none"}`} onClick={(e) => e.stopPropagation()}>
          <ul className='item-wrapper'>
            <li className='item' onClick={(e) => {
              e.stopPropagation();
              handleClick('/profile');
            }}>
              <p className='text'>Profile</p>
            </li>
            <li className='item' onClick={(e) => {
              e.stopPropagation();
              handleClick('/signin');
              localStorage.removeItem(tokenKey)
            }}>
              <p className='text'>Logout</p>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Profile