import { BtnProps } from "./ButtonInterface";

const SecondaryButton = (props: BtnProps) => {
  const { className = '', btnName, btnType = "submit", onClick, disabled = false, ...rest } = props;
  return (
    <button type={btnType} className={`custom-btn secondary-btn ${className}`} onClick={(e) => {
      if (onClick) {
        onClick(e)
      }
    }} disabled={disabled} {...rest}>
      {btnName}
    </button>
  )
}

export default SecondaryButton