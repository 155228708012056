import React, { SetStateAction } from 'react';
import { useEffect, useMemo, useState } from 'react';
import TableContainer from '../../../components/Table/TableContainer';
import ToggleSwitch from '../../../components/ToggleSwitch';
import InputSearch from '../../../components/InputSearch';
import edit from '../../../assets/images/svgIcons/edit_iocn.svg';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import ManageFrequencyModal from '../../../components/Models/ManageSetupsModalComponent/ManageFrequencyModal';
import { changestatusFrequencyThunk, getFrequencyThunk } from '../../../store/asyncThunks/manage/setups';
import ConfirmationModal from '../../../components/Models/ConfirmationModal';
import TableSkeleton from '../../../components/skeleton/TableSkeleton';
import { updateFrequencyData } from '../../../store/slices/manage/setups/Frequency/getFrequency';
import { Card } from 'reactstrap';
import TableWithCardLoader from '../../../components/TableWithCardLoader';

interface FrequencyProps {
  currentPage: number;
  setCurrentPage: SetStateAction<any>;
  reset: number;
}

const Frequency = (props: FrequencyProps) => {
  const { currentPage, setCurrentPage, reset } = props;

  document.title = `Setups | ${process.env.REACT_APP_DOCUMENT_TITLE}`;

  const dispatch = useAppDispatch()

  const MemoizedTableContainer = React.memo(TableContainer);

  const [searchValue, setSearchValue] = useState("");
  const [EditData, setEditData] = useState<any>(null);
  const [statusData, setStatusData] = useState<any>(null);
  const [showModal, setshowModal] = useState(true);
  const [statusShowModal, setstatusShowModal] = useState(true);
  const pageSize = process.env.REACT_APP_PAGE_SIZE || 10;
  const getFrequencyList = useAppSelector((state) => state?.getFrequencyReducer?.data?.frequency);
  const getFrequencyLoading = useAppSelector((state) => state?.getFrequencyReducer?.loading);
  const frequencyCount = useAppSelector((state) => state?.getFrequencyReducer?.data?.totalCount);
  const FrequencyStatusLoader = useAppSelector((state) => state?.changeStatusFrequencyReducer?.loading)

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setCurrentPage(1);
  }

  useEffect(() => {
    getData()
  }, [searchValue, currentPage, reset]);

  const getData = async () => {
    const data: any = {}
    data["currentPage"] = currentPage
    data["pageSize"] = pageSize
    data["data"] = { search: searchValue }
    await dispatch(getFrequencyThunk(data));
  }

  const toggleModal = () => {
    setshowModal(!showModal);
  };

  const toggleStatusModal = () => {
    setstatusShowModal(!statusShowModal);
  };

  const handleEditClick = (Rowdata: any) => {
    setEditData(Rowdata);
    setshowModal(true);
  };

  const handleToggleSwitch = (Rowdata: any) => {
    setStatusData(Rowdata);
    setstatusShowModal(true);
  }

  const FrequencyUpdateStatus = () => {
    const status = {
      status: statusData?.status === "INACTIVE" ? "ACTIVE" : "INACTIVE",
    }
    let id: any = statusData?._id;
    const data: any = {
      data: status,
      id: id,
      afterSuccess: (newStatus:any) => {
        dispatch(updateFrequencyData(newStatus));
        setstatusShowModal(false);
      },
    };
    dispatch(changestatusFrequencyThunk(data));

  };

  const handleModalPagination = () => {
    getData();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'firstName',
        Cell: (cell: any) => {
          return <div className='label-cell'>{cell?.row?.original?.label}</div>;
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: (cell: any) => {
          return <div className='description-cell'>{cell?.row?.original?.scope}</div>;
        },
      },
      {
        Header: 'Status',
        Cell: (cell: any) => {
          const Rowdata = cell?.row?.original;
          return <ToggleSwitch id={cell.row.index} defaultChecked={cell.row.original.status?.toUpperCase() === "ACTIVE" ? true : false}
            onChange={(e: any) => {
              handleToggleSwitch(Rowdata)
            }} />
        }
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: (cell: any) => {
          const Rowdata = cell?.row?.original;
          return <div className='actions'>
            <div className="edit" onClick={() => handleEditClick(Rowdata)}>
              <img src={edit} alt="edit" />
            </div>
          </div>
        }
      },
    ], [getFrequencyList]);

  return (
    <>
      <div className='table-search'>
        <InputSearch onSearch={handleSearch} value={searchValue} />
      </div>
      <Card className={!getFrequencyList ? `loader-card` : ""} >
        <TableWithCardLoader
          data={getFrequencyList || []}
          columns={columns}
          loader={getFrequencyLoading}
          totalCount={frequencyCount}
          customPageSize={Number(pageSize)}
          dynamicPagination={true}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Card>
      {EditData && (
        <ManageFrequencyModal
          showModal={showModal}
          toggle={toggleModal}
          mode='Edit'
          EditData={EditData}
          handleModalPagination={handleModalPagination}
        />
      )}
      {statusData && (
        <ConfirmationModal
          showModal={statusShowModal}
          toggle={toggleStatusModal}
          modalTitle={`Confirmation!`}
          primaryAction={FrequencyUpdateStatus}
          secondaryAction={toggleStatusModal}
          loader={FrequencyStatusLoader}
          primaryBtnName={`${statusData?.status === "INACTIVE" ? 'Activate' : 'Deactivate'}`}
          secoundaryBtnName="Cancel"
          description={`Are you sure, do you want to  ${statusData?.status === "ACTIVE" ? 'deactivate' : 'activate'} this frequency  "${statusData?.label} "?`} />
      )}
    </>
  )
}

export default Frequency