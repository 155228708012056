import { useEffect, useState } from 'react'
import SecondaryButton from '../../components/buttons/SecondaryButton';
import AddEmployeeModel from '../../components/Models/AddEmployeeModel';
import PageTitle from '../../components/PageTitle';
import Employee from './mappingComponent/Employee';
import Responsibility from './mappingComponent/Responsibility';
import { mappingTabsList } from '../../constants/datas/tabsList';
import Tabs from '../../components/Tabs';
import EditEmployeeModal from '../../components/Models/EditEmployeeModal';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { getClassificationThunk, getDepartmentThunk, getDivisionThunk, getFrequencyThunk } from '../../store/asyncThunks/manage/setups';
import getActiveTabBasedOnRolePrevilege from '../../constants/getActiveTabBasedOnRolePrevilege';
import PrimaryButton from '../../components/buttons/PrimaryButton';

const Mapping = () => {
  document.title = `Mapping | ${process.env.REACT_APP_DOCUMENT_TITLE}`;

  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState(1);

  const userRecord = useAppSelector((state) => state?.decodeTokenSliceReducer?.data);

  const [activeTab, setActiveTab] = useState<number | null | undefined>(null);
  const [showAddEmployeeModel, setShowAddEmployeeModel] = useState(false);
  const [currentSubTab, setCurrentSubTab] = useState(0);

  const toggleAddEmployeeModel = () => {
    setShowAddEmployeeModel(!showAddEmployeeModel);
  }

  useEffect(() => {
    const payload: any = {};
    payload["data"] = { status: "ACTIVE" };
    dispatch(getDivisionThunk(payload));
    dispatch(getDepartmentThunk(payload));
    dispatch(getClassificationThunk(payload))
    dispatch(getFrequencyThunk(payload))
  }, []);

  useEffect(() => {
    if (userRecord?.action?.manage?.mapping) {
      const tab = getActiveTabBasedOnRolePrevilege({ object: userRecord?.action?.manage?.mapping, tabsList: mappingTabsList });
      setActiveTab(tab);
    }
  }, [userRecord])

  return (
    <div className='mapping-list'>
      <PageTitle title={`Mapping`} />
      <div className="screen-actions">
        <Tabs menu={`manage`} submenu={`mapping`} tabsList={mappingTabsList} setActiveTab={setActiveTab} activeTab={activeTab} onClick={() => {
          setCurrentSubTab(0)
        }} />
        {currentSubTab === 0 && activeTab === 0 &&
          <PrimaryButton btnName='Add New' onClick={() => setShowAddEmployeeModel(true)} />
        }
      </div>

    {activeTab === 0 && <Employee currentPage={currentPage} setCurrentPage={setCurrentPage}  currentTab={currentSubTab} setCurrentTab={setCurrentSubTab} />}
      {activeTab === 1 && <Responsibility />}

      {/* Add employee model */}
      <AddEmployeeModel currentPage={currentPage} setCurrentPage={setCurrentPage} modalTitle='Add Employee' showModal={showAddEmployeeModel} toggle={toggleAddEmployeeModel} />
    </div>
  )
}

export default Mapping