import React, { SetStateAction } from 'react';
import { useEffect, useMemo, useState } from 'react';
import TableContainer from '../../../components/Table/TableContainer';
import ToggleSwitch from '../../../components/ToggleSwitch';
import edit from '../../../assets/images/svgIcons/edit_iocn.svg';
import arrow from '../../../assets/images/svgIcons/accordian_arrow.svg';
import InputSearch from '../../../components/InputSearch';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import ConfirmationModal from '../../../components/Models/ConfirmationModal';
import { changestatusResponsibilityThunk, getClassificationThunk, getFrequencyThunk, getResponsibilityThunk } from '../../../store/asyncThunks/manage/setups';
import ManageResponsibilityModal from '../../../components/Models/ManageSetupsModalComponent/ManageResponsibilityModal';
import TableSkeleton from '../../../components/skeleton/TableSkeleton';
import { updateResponsibilitiesData } from '../../../store/slices/manage/setups/Responsibility/getResponsibility';
import { Card } from 'reactstrap';
import TableWithCardLoader from '../../../components/TableWithCardLoader';

interface ResponsibilityProps {
  currentPage: number;
  setCurrentPage: SetStateAction<any>;
  reset: number;
}

const Responsibilities = (props: ResponsibilityProps) => {
  const { currentPage, setCurrentPage, reset } = props;

  document.title = `Setups | ${process.env.REACT_APP_DOCUMENT_TITLE}`;

  const dispatch = useAppDispatch()

  const [searchValue, setSearchValue] = useState("");
  const [EditData, setEditData] = useState<any>(null);
  const [statusData, setStatusData] = useState<any>(null);
  const [showModal, setshowModal] = useState(true);
  const [statusShowModal, setstatusShowModal] = useState(true)
  const pageSize = process.env.REACT_APP_PAGE_SIZE || 10;
  const getResponsibilityList = useAppSelector((state) => state?.getResponsibilityReducer?.data?.responsibility);
  const getResponsibilityLoading = useAppSelector((state) => state?.getResponsibilityReducer?.loading);
  const responsibilityCount = useAppSelector((state) => state?.getResponsibilityReducer?.data?.totalCount)
  const ResponsibilityStatusLoader = useAppSelector((state) => state?.changeStatusResponsibilityReducer?.loading)

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setCurrentPage(1);
  }

  useEffect(() => {
    getData()
  }, [searchValue, currentPage, reset]);

  const getData = async () => {
    const data: any = {}
    data["currentPage"] = currentPage
    data["pageSize"] = pageSize
    data["data"] = { search: searchValue }
    await dispatch(getResponsibilityThunk(data));
  }

  useEffect(() => {
    const payload = {
      data: { status: "ACTIVE" }
    }
    dispatch(getFrequencyThunk(payload));
    dispatch(getClassificationThunk(payload));
  }, [])

  const handleEditClick = (Rowdata: any) => {
    setEditData(Rowdata);
    setshowModal(true);
  };

  const toggleModal = () => {
    setshowModal(!showModal);
  };

  const toggleStatusModal = () => {
    setstatusShowModal(!statusShowModal);
  };

  const handleToggleSwitch = (Rowdata: any) => {
    setStatusData(Rowdata);
    setstatusShowModal(true);
  }

  const FrequencyUpdateStatus = () => {
    const status = {
      status: statusData?.status === "INACTIVE" ? "ACTIVE" : "INACTIVE",
    }
    const data: any = {
      data: status,
      id: statusData?._id,
      afterSuccess: (newStatus: any) => {
        dispatch(updateResponsibilitiesData(newStatus));
        setstatusShowModal(false);
      },
    };
    dispatch(changestatusResponsibilityThunk(data));

  };

  const handleModalPagination = () => {
    getData();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'firstName',
        Cell: (cell: any) => {
          return <div>{cell?.row?.original?.label}</div>;
        },
      },
      {
        Header: 'Classification',
        accessor: 'classification',
        Cell: (cell: any) => {
          return <div
            className={`badge-md`}
            style={{
              backgroundColor: `${cell?.row?.original?.bgColorCode}`
            }}>
            <span style={{ color: `${cell?.row?.original?.textColorCode}` }}>
              {cell?.row?.original?.classificationFKId} </span>
          </div>;
        },
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
        Cell: (cell: any) => {
          return <div className='description-cell'>{cell?.row?.original?.frequencyFKId
          }</div>;
        },
      },
      {
        Header: 'Time',
        accessor: 'time',
        Cell: (cell: any) => {
          return <div className='description-cell'>{cell?.row?.original?.duration}</div>;
        },
      },
      {
        Header: 'Status',
        Cell: (cell: any) => {
          const Rowdata = cell?.row?.original;
          return <ToggleSwitch id={cell.row.index} defaultChecked={cell.row.original.status?.toUpperCase() === "ACTIVE" ? true : false}
            onChange={(e: any) => {
              handleToggleSwitch(Rowdata)
            }} />
        }
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: (cell: any) => {
          const Rowdata = cell?.row?.original;
          return <div className='actions responsibility-edit'>
            <div className="edit " onClick={() => handleEditClick(Rowdata)}>
              <img src={edit} alt="edit" />
            </div>
            <div className={`arrow`}>
              <img src={arrow} alt="arrow" />
            </div>
          </div>
        },
      },

    ], [getResponsibilityList]);

  const subTable = (row: any) => {
    const { scope } = row.original
    return (
      <>
        <h2>Description</h2>
        <p className="description">{scope}</p>
      </>
    )
  }

  return (
    <>
      <div className='table-search'>
        <InputSearch onSearch={handleSearch} value={searchValue} />
      </div>
      <Card className={!getResponsibilityList ? `loader-card` : ""} >
        <TableWithCardLoader
          data={getResponsibilityList || []}
          columns={columns}
          loader={getResponsibilityLoading}
          totalCount={responsibilityCount}
          customPageSize={Number(pageSize)}
          dynamicPagination={true}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          renderRowSubComponent={subTable}
          isExpanded={true}
        />
      </Card>
      {EditData && (
        <ManageResponsibilityModal
          showModal={showModal}
          toggle={toggleModal}
          mode='Edit'
          EditData={EditData}
          handleModalPagination={handleModalPagination}
        />
      )}
      {statusData && (
        <ConfirmationModal
          showModal={statusShowModal}
          toggle={toggleStatusModal}
          modalTitle={`Confirmation!`}
          primaryAction={FrequencyUpdateStatus}
          secondaryAction={toggleStatusModal}
          loader={ResponsibilityStatusLoader}
          primaryBtnName={`${statusData?.status === "INACTIVE" ? 'Activate' : 'Deactivate'}`}
          secoundaryBtnName="Cancel"
          description={`Are you sure, do you want to  ${statusData?.status === "ACTIVE" ? 'deactivate' : 'activate'} this responsibility  "${statusData?.label} "?`} />
      )}
    </>
  )
}

export default Responsibilities