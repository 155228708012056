import { useEffect, useState } from "react"
import SecondaryButton from "../../components/buttons/SecondaryButton";
import Roles from "./setupsComponent/Roles"
import Department from "./setupsComponent/Department";
import Divisions from "./setupsComponent/Divisions";
import Classifications from "./setupsComponent/Classifications";
import Responsibilities from "./setupsComponent/Responsibilities";
import Frequency from "./setupsComponent/Frequency";
import ManageSetupsModal from "../../components/Models/ManageSetupsModal";
import PageTitle from "../../components/PageTitle";
import { setupsTabsList } from "../../constants/datas/tabsList";
import Tabs from "../../components/Tabs";
import { useAppSelector } from "../../store/store";
import getActiveTabBasedOnRolePrevilege from "../../constants/getActiveTabBasedOnRolePrevilege";
import PrimaryButton from "../../components/buttons/PrimaryButton";

const Setup = () => {

  const userRecord = useAppSelector((state) => state?.decodeTokenSliceReducer?.data);

  const [activeTab, setActiveTab] = useState<number | null | undefined>(null);
  const [CurrentPageChange, setCurrentPageChange] = useState(1);
  const [reset, setReset] = useState(1);

  const [showModal, setshowModal] = useState(false);

  const toggleModal = () => {
    setshowModal(!showModal);
  };

  useEffect(() => {
    if (userRecord?.action?.manage?.setups) {
      const tab = getActiveTabBasedOnRolePrevilege({ object: userRecord?.action?.manage?.setups, tabsList: setupsTabsList });
      setActiveTab(tab);
    }
  }, [userRecord])

  return (
    <div className='setups'>
      <PageTitle title={`Setups`} />

      <div className="screen-actions">
        <Tabs menu={`manage`} submenu={`setups`} tabsList={setupsTabsList} setActiveTab={setActiveTab} activeTab={activeTab} onClick={() => setCurrentPageChange(1)} />
        <PrimaryButton btnName='Add New' onClick={toggleModal} />
      </div>
      {activeTab === 0 && <Roles reset={reset} currentPage={CurrentPageChange} setCurrentPage={setCurrentPageChange} />}
      {activeTab === 1 && <Department reset={reset} currentPage={CurrentPageChange} setCurrentPage={setCurrentPageChange} />}
      {activeTab === 2 && <Divisions reset={reset} currentPage={CurrentPageChange} setCurrentPage={setCurrentPageChange} />}
      {activeTab === 3 && <Classifications reset={reset} currentPage={CurrentPageChange} setCurrentPage={setCurrentPageChange} />}
      {activeTab === 4 && <Frequency reset={reset} currentPage={CurrentPageChange} setCurrentPage={setCurrentPageChange} />}
      {activeTab === 5 && <Responsibilities reset={reset} currentPage={CurrentPageChange} setCurrentPage={setCurrentPageChange} />}
      {/* //modal popup*/}
      <ManageSetupsModal
        showModal={showModal}
        toggle={toggleModal}
        activeTabIndex={activeTab}
        handleModalPagination={() => {
          const page = 1;
          setCurrentPageChange(page);
          setReset(prev => prev + 1);
        }}
      />
    </div>
  )
}

export default Setup