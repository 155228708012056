import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SalaryTableSkeleton = () => {
    return (
        <div className="body-content">
            <table className='table p-0'>
                <thead>
                    <tr>
                        <th><Skeleton width={'90px'} height={"18px"} /></th>
                        <th><Skeleton width={'90px'} height={"18px"} /></th>
                        <th><Skeleton width={'90px'} height={"18px"} /></th>
                        <th><Skeleton width={'80px'} height={"18px"} /></th>
                    </tr>
                </thead>
                <tbody className='custom-scroll'>
                    <tr>
                        {[...Array(5)].map((index) => {
                            return (
                                <tr>
                                    <td><Skeleton width={'90px'} height={"18px"} /></td>
                                    <td><Skeleton width={'90px'} height={"18px"} /></td>
                                    <td><Skeleton width={'90px'} height={"18px"} /></td>
                                    <td><Skeleton width={'80px'} height={"18px"} /></td>
                                </tr>
                            );
                        })}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default SalaryTableSkeleton;