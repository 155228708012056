import { createAsyncThunk } from "@reduxjs/toolkit";
import { addEmployee, addResponsibilities, addManageSalary, employeeStatus, getByEmployeeId, getEmployeesList, getManageSalary, getResponsibilities , mapResponsibility, updateEmployee, getDndResponsiblity, assignDndbyEmp, changeResponsibilityStatus, getemployeeMappingByIdUrl } from "../../../helpers/backend_helper";
import toast from "react-hot-toast";

interface addEmployeeProps {
    data: any,
    afterSuccess: Function,
    datasubmited: Function,
    viewEmpId: any
}

export const addEmployeeThunk = createAsyncThunk(
    "addEmployee/addEmployeeThunk",
    async (props: addEmployeeProps) => {
        try {
            const { afterSuccess, data, datasubmited } = props;

            const response = await addEmployee(data);

            if (response?.status?.toLowerCase() === "success") {
                afterSuccess();
                datasubmited();
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return error?.response?.data;
        }
    }
);

//Get By Employee Id
export const getByEmployeeIdThunk = createAsyncThunk(
    "getEmployeeId/getEmployeeIdThunk",
    async (props: addEmployeeProps) => {
        try {
            const data = props;
            const response = await getByEmployeeId(data);

            if (response?.status?.toLowerCase() === "success") {
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return error?.response?.data;
        }
    }
);

//Update Employee
export const updateEmployeeThunk = createAsyncThunk(
    "updateEmployee/updateEmployeeThunk",
    async (props: addEmployeeProps) => {
        try {
            const { afterSuccess, data, datasubmited, viewEmpId } = props;

            const response = await updateEmployee(data, viewEmpId);

            if (response?.status?.toLowerCase() === "success") {
                afterSuccess()
                datasubmited(response?.data)
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return error?.response?.data;
        }
    }
);

// Get Employees
export const getEmployeesThunk = createAsyncThunk(
    "getEmployee/getEmployeeThunk",
    async (props: {
        afterSuccess?: Function;
        payload?: Record<string, any>,
        currentPage?: number;
        pageSize?: number;
    }) => {
        try {
            const { afterSuccess, payload, currentPage = 0, pageSize = 0 } = props;
            const response = await getEmployeesList(payload ? payload : {}, currentPage, pageSize);
            if (response?.status?.toLowerCase() === "success") {
                if (afterSuccess) {
                    afterSuccess();
                }
            }
            return await response;
        } catch (error: any) {
            return error?.response?.data;
        }
    }
);

//get responsibility employee
export const getAllEmployeesThunk = createAsyncThunk(
    "getResponsibilityEmployee/getAllEmployeesThunk",
    async (props: {
        afterSuccess?: Function;
        payload?: Record<string, any>,
        currentPage?: number;
        pageSize?: number;
    }) => {
        try {
            const { afterSuccess, payload, currentPage = 0, pageSize = 0 } = props;
            const response = await getEmployeesList(payload ? payload : {}, currentPage, pageSize);
            if (response?.status?.toLowerCase() === "success") {
                if (afterSuccess) {
                    afterSuccess();
                }
            }
            return await response;
        } catch (error: any) {
            return error?.response?.data;
        }
    }
);
//get dnd responsibilities
export const getDndResponsibilitiesThunk = createAsyncThunk(
    "getDndResponsibilities/getDndResponsibilitiesThunk",
    async (props: {
        division: string,
        department: string,
        search: string
    }) => {
        try {
            const response = await getResponsibilities(props);
            if (response?.status?.toLowerCase() === "error") {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return error?.response?.data;
        }
    }
);

//add dnd responsibilities
export const addDndResponsibilitiesThunk = createAsyncThunk(
    "addDndResponsibilities/addDndResponsibilitiesThunk",
    async (props: {
        data: {
            divisionFKId: string,
            departmentFKId: string,
            responsibilityFKId: [string]
        },
        afterSuccess: Function
    }) => {
        try {
            const { data, afterSuccess } = props;

            const response = await addResponsibilities(data);
            if (response?.status?.toLowerCase() === "success") {
                afterSuccess();
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return error?.response?.data;
        }
    }
);
//Change Employee Status
interface statusChange {
    status: any,
    employeeId: any,
    closeModal: Function,
    afterSuccess:Function
}

export const changeStatusThunk = createAsyncThunk(
    "changeStatus/changeStatusThunk",
    async (props: statusChange) => {
        try {
            const { status, employeeId, closeModal ,afterSuccess} = props;
            const response = await employeeStatus(status, employeeId);
            if (response?.status?.toLowerCase() === "success") {
                closeModal(false)
                afterSuccess()
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return error?.response?.data;
        }
    }
)

//Get Manage Salary
export const getManageSalaryThunk = createAsyncThunk(
    "getManageSalary/getManageSalaryThunk",
    async (props: addEmployeeProps) => {
        try {
            const response = await getManageSalary(props);
            if (response?.status?.toLowerCase() === 'error') {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return error?.response?.data;
        }
    }
);

//Add Manage Salary 
export const addManageSalaryThunk = createAsyncThunk(
    "addManageSalary/addManageSalaryThunk",
    async (props: addEmployeeProps) => {
        try {
            const { afterSuccess, data, datasubmited } = props;

            const response = await addManageSalary(data);

            if (response?.status?.toLowerCase() === "success") {
                toast.success(response?.message);
                datasubmited()
                afterSuccess()
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return error?.response?.data;
        }
    }
);

// Get Employees
interface mapREspons {
    data: any,
    currentPage: number,
    pageSize: number

}
export const mapResponsibilityThunk = createAsyncThunk(
    "mapResponsibility/mapResponsibility",
    async (props: mapREspons) => {
        try {
            const { data, currentPage, pageSize } = props;
            const response = await mapResponsibility(data, currentPage, pageSize);
            return await response;
        } catch (error: any) {
            return error?.response?.data;
        }
    }
);
export const getUserDndResponsibilityThunk = createAsyncThunk(
    "getDndResponsibilityThunk",
    async (data: {
        division: string,
        department: string,
        search?: string
    }) => {
        try {
            const response = await getDndResponsiblity(data);
            return await response;
        } catch (error: any) {
            return error?.response?.data;
        }
    }
);
export const assignDndByEmpIdThunk = createAsyncThunk(
    "assignDndByEmpIdThunk",
    async (props: {
        data: {
            associateFKId: string,
            dndFKId: [string]
        },
        afterSuccess: Function
    }) => {
        try {
            const response = await assignDndbyEmp(props?.data);
            if (response?.status?.toLowerCase() === "success") {
                props?.afterSuccess()
                toast.success(response?.message);
            }
            return await response;
        } catch (error: any) {
            return error?.response?.data;
        }
    }
);
//change maprespomsibility status

interface activityStatusChange {
    data: any,
    id: Number,
    afterSuccess: Function
}

export const changeStatusResponsibilityThunk = createAsyncThunk(
    "changeStatusResponsibility/changeStatusResponsibilityThunk",
    async (props: activityStatusChange) => {
        const { data, id, afterSuccess } = props;
        try {
            const response = await changeResponsibilityStatus(data, id);
            if (response?.status?.toLowerCase() === "success") {
                if (afterSuccess) afterSuccess(response.data);
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return error?.response?.data;
        }
    }
)

//employeemapping list

interface ReporteesData {
    userId: string | undefined;
    division?: string;
    department?: string;
}

export const getReporteesMappingByIdThunk = createAsyncThunk(
    "overView/getReporteesMappingById",
    async (payload: ReporteesData) => {
        try {
            const response = await getemployeeMappingByIdUrl(payload?.userId, payload);
            if (response?.status?.toLowerCase() === "success") {
                return await response;
            } else {
                toast.error(response?.message);
            }
            return await response;
        } catch (error: any) {
            return await error?.response?.data;
        }
    }
);