import { SetStateAction, useEffect, useMemo, useState } from 'react'
import DropDown from '../../../components/DropDown'
import InputSearch from '../../../components/InputSearch'
import avatar from '../../../assets/images/svgImages/avatar.svg';
import { useNavigate } from 'react-router-dom'
import SecondaryButton from '../../../components/buttons/SecondaryButton'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { getReporteesList } from '../../../store/asyncThunks/overView/reportees'
import { getRoleThunk } from '../../../store/asyncThunks/manage/setups'
import { Card, Label } from 'reactstrap'
import TableWithCardLoader from '../../../components/TableWithCardLoader'
import Pagination from '../../../components/Table/Pagination/Pagination';
import NoData from '../../../components/NoData';
interface reporteesProps {
    print?: any;
    setReporteesList: SetStateAction<any>;
    reporteesList: any;
    expandTableRow?: Boolean;
}
export default function Reportees(props: reporteesProps) {
    const { print, reporteesList, setReporteesList, expandTableRow } = props
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const getReportees = useAppSelector((state: any) => state?.getReporteesList);
    const getReporteesLoading = useAppSelector((state: any) => state?.getReporteesList.loading);
    const getRoleList = useAppSelector((state: any) => state?.getRoleReducer?.data?.role);
    const getRoleListLoader = useAppSelector((state: any) => state?.getRoleReducer?.loading);
    const userRoleList = getRoleList && [{ label: "All", value: "" }, ...getRoleList];

    const pageSize = process.env.REACT_APP_PAGE_SIZE || 12;

    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [designation, setDesignation] = useState<any>({ label: "All", value: "" })
    const [sortBy, setSortBy] = useState({
        field: "created_at",
        sort: null,
        value: "descending"
    });

    useEffect(() => {
        const values: any = {
            data: { status: "ACTIVE" }
        }
        dispatch(getRoleThunk(values));
    }, []);

    useEffect(() => {
        const data = getReportees?.data?.data;
        setReporteesList(data);
    }, [getReportees]);

    const columns = useMemo(
        () => [
            {
                Header: 'Reportees name',
                accessor: 'associateFKId',
                Cell: (cell: any) => {
                    const user = cell?.row?.original

                    return <div className='userDetail'>
                        {/* <img src={user?.profilePath || avatar} alt="user" /> */}
                        <p className='profile-circle-md '>{user?.name?.charAt(0).toUpperCase()}</p>
                        <div>
                            <p className='userName'>{user?.name}</p>
                            <p className='userRole'>{user?.designation}</p>
                        </div>
                    </div>
                }
            },
            {
                accessor: 'id',
                Cell: (cell: any) => {
                    return <div className='center-item'>
                        <SecondaryButton className='reportees-view-btn' disable={true} btnName='View' onClick={(e) => {
                            navigate(`/overview/reportees/${cell?.row?.original?._id}`)
                        }} />
                    </div>
                }
            },
        ], [reporteesList]);


    const handleSearch = (value: any) => {
        setSearchValue(value);
        setCurrentPage(1);
    }

    useEffect(() => {
        const data: any = {}
        data["currentPage"] = currentPage
        data["pageSize"] = pageSize
        data["payload"] = { search: searchValue, designation: designation?._id ? designation?._id : "ALL" }
        dispatch(getReporteesList(data));
        if (designation) {

        }
    }, [currentPage, pageSize, searchValue, designation]);

    const handleRoleChange = (data: any) => {
        setDesignation(data);
        setCurrentPage(1);
    }

    return (
        <div ref={print}>
            <div className='reportees employee-main'>

                <div className='reportee-filters'>
                    <div className="dropdowns mb-0">
                        <DropDown loading={getRoleListLoader} options={userRoleList} mainClass="filter-dropdown" placeholder="Designation" onChange={(data: any) =>
                            handleRoleChange(data)} value={designation} />
                    </div>
                    <div className='table-search mb-0'>
                        <div className='d-flex align-items-center gap-3'>
                            <InputSearch value={searchValue} onSearch={handleSearch} />
                        </div>
                    </div>
                </div>
                <div className='h-100 responsibility-sub-component'>
                    <Card className={getReporteesLoading ? `loader-card` : ""} >
                        <TableWithCardLoader
                            data={reporteesList?.associates || []}
                            columns={columns}
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                            loader={getRoleListLoader || getReporteesLoading}
                        />
                    </Card>
                </div>
            </div>
            <div className="pagination-wrapper ">
                <div className="total-count">
                    {
                        reporteesList?.totalCount > 0 &&
                        <Label className={`mb-0`}>Total count: <span>{reporteesList?.totalCount || 0}</span></Label>
                    }
                </div>
                <div>
                    {reporteesList?.associates?.length > 0 ? (
                        <div className="page-count">
                            <Pagination
                                className="pagination-bar custom-pagination"
                                currentPage={currentPage}
                                totalCount={reporteesList?.totalCount || 0}
                                pageSize={pageSize}
                                onPageChange={(page: any) => setCurrentPage(page)}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}
