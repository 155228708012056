import { SetStateAction, useState } from 'react'
import arrowdown from '../assets/images/svgIcons/arrow_down.svg'
import menuIcon from '../assets/images/svgIcons/menu.svg'
import { MenuItems } from '../constants/MenuItems';
import { NavLink, useNavigate } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { useAppSelector } from '../store/store';


interface props {
  setOpenMenu: SetStateAction<any>,
  openMenu: boolean,
  setIsShortMenu: SetStateAction<any>,
  isShortMenu: boolean
}

const Sidebar = (props: props) => {
  const navigate = useNavigate();
  const { openMenu, setOpenMenu, setIsShortMenu, isShortMenu } = props;
  const userRecord = useAppSelector((state) => state?.decodeTokenSliceReducer?.data);
  const [activeIndex, setActiveIndex] = useState<null | number>(null);
  const [activeSubIndex, setActiveSubIndex] = useState<null | number>(null);
  const [activeMenu, setActiveMenu] = useState<null | number>(null);

  const getNavigatePath = (menu: string, submenu: Array<any>) => {
    let path = '';
    for (let i = 0; i < submenu.length; i++) {
      if (userRecord?.action[menu][submenu[i].label?.toLowerCase()].pageView) {
        path = submenu[i].path;
        break;
      }
    }
    return path;
  }

  return (
    <div className='sidebar-wrapper'>
      {/* for responsive open */}
      <div className="toggle">
        <div className={`toggle-menu ${isShortMenu ? "short-menu" : ""}`} onClick={() => {
          setIsShortMenu(!isShortMenu)
        }}>
          <img src={menuIcon} alt="show-menu" />
        </div>
      </div>

      {/* menu item list */}
      <div className={`sidebar custom-scroll  ${openMenu ? "opened" : ""} ${isShortMenu ? "short-menu" : ""}`}>
        <ul className={`menu-items`}>
          {MenuItems?.map((item: any, index: any) => {
            const label = item.label.toLowerCase();
            let submenuIsThere = false;

            if (item?.subMenu) {
              const path = getNavigatePath(label, item?.subMenu);
              submenuIsThere = path ? true : false;
            }

            if (item?.subMenu && userRecord?.action[label].pageView && submenuIsThere) {
              return <li key={`menu-item-${index}`} className={`submenu-list ${activeIndex === index || activeMenu === index ? 'active-list' : ''}`}>
                {/* with submenu */}
                <div id={`menu-item-${index}`} className={`${activeIndex === index || activeMenu === index ? 'active' : 'inactive'} ${activeMenu === index ? 'open' : ""} item with-submenu`}
                  onClick={() => {
                    const path = getNavigatePath(label, item?.subMenu);
                    if (index === activeMenu) {
                      setActiveMenu(null);
                    } else if (activeIndex !== index) {
                      navigate(path);
                      setActiveMenu(index);
                    } else {
                      setActiveMenu(index);
                    }
                  }}
                >
                  <div className="label-section">
                    {
                      isShortMenu && <>
                        <span className='icon'><img src={activeIndex === index || activeMenu === index ? item.active_icon : item?.icon} alt="icon" /></span>
                        <UncontrolledTooltip className='custom-tooltip' placement='right' target={`menu-item-${index}`}>
                          {item.label}
                        </UncontrolledTooltip>
                      </>
                    }
                    {!isShortMenu &&
                      <>
                        <span className='icon'><img src={activeIndex === index || activeMenu === index ? item.active_icon : item?.icon} alt="icon" /></span>
                        <span>{item.label}</span>
                      </>
                    }
                  </div>
                  {
                    !isShortMenu &&
                    <span className='arrow'><img src={arrowdown} alt="dropdown" /></span>
                  }
                </div>
                {/* submenu list */}
                <ul className="dropdown">
                  {item.subMenu.map((submenu: any, innerindex: any) => {
                    const submenuLabel = submenu.label.toLowerCase();
                    if (userRecord?.action[label][submenuLabel].pageView) {
                      return (
                        <li key={`menu-item-${index}-submenu-${innerindex}`} id={`menu-item-${index}-submenu-${innerindex}`}>
                          <NavLink to={submenu.path} className={({ isActive }) => {
                            if (isActive) {
                              setActiveIndex(index)
                              setActiveSubIndex(innerindex)
                            }
                            return (isActive ? 'active' : 'inactive') + ' item sub-item'
                          }} onClick={() => {
                            setActiveMenu(index)
                            setOpenMenu(false);
                          }}>
                            {
                              isShortMenu && <>
                                <span className='icon'><img src={(activeIndex === index && activeSubIndex === innerindex) ? submenu?.active_icon : submenu?.icon} alt="icon" /></span>
                                <UncontrolledTooltip className='custom-tooltip' placement='right' target={`menu-item-${index}-submenu-${innerindex}`}>
                                  {submenu.label}
                                </UncontrolledTooltip>
                              </>
                            }
                            {
                              !isShortMenu &&
                              <>
                                <span className='icon'><img src={(activeIndex === index && activeSubIndex === innerindex) ? submenu?.active_icon : submenu?.icon} alt="icon" /></span>
                                <span>{submenu.label}</span>
                              </>
                            }
                          </NavLink>
                        </li>
                      )
                    }
                  })}
                </ul>
              </li>
            } else if (userRecord?.action[label].pageView && !item?.subMenu) {
              return <li id={`menu-item-${index}`} key={`menu-item-${index}`} className={`${activeIndex === index && activeMenu === null ? 'active-list' : ''}`}>
                {/* without submenu */}
                <NavLink to={item.path} className={({ isActive }) => {
                  if (isActive) {
                    setActiveIndex(index)
                  }
                  return (isActive && activeMenu === null ? 'active' : 'inactive') + " item"
                }} onClick={() => {
                  setOpenMenu(false);
                  setActiveMenu(null);
                }}>
                  {
                    isShortMenu && <>
                      <span className='icon'><img src={activeIndex === index && activeMenu === null ? item.active_icon : item?.icon} alt="icon" /></span>
                      <UncontrolledTooltip className='custom-tooltip' placement='right' target={`menu-item-${index}`}>
                        {item.label}
                      </UncontrolledTooltip>
                    </>
                  }
                  {
                    !isShortMenu && <>
                      <span className='icon'><img src={activeIndex === index && activeMenu === null ? item.active_icon : item?.icon} alt="icon" /></span>
                      <span>{item.label}</span>
                    </>
                  }
                </NavLink>
              </li>
            }
          })}
        </ul>
      </div>
    </div>
  )
}

export default Sidebar