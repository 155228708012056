import { Fragment, SetStateAction, useEffect } from "react";
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
} from "react-table";
import { Table, Row, Col, Label } from "reactstrap";
import Pagination from "./Pagination/Pagination";
import PrimaryButton from "../buttons/PrimaryButton";
import TableHeaderSection from "./component/TableHeaderSection";
import TableBodySection from "./component/TableBodySection";
import NoData from "../NoData";

interface tableProps {
    totalCount?: number,
    sortBy?: any,
    setSortBy?: SetStateAction<any>,
    currentPage?: number,
    setCurrentPage?: SetStateAction<any>,
    dynamicPagination?: boolean,
    columns: Array<any>,
    data: Array<any>,
    isAddOptions?: boolean,
    handleBtnClick?: any,
    customPageSize?: number,
    className?: string,
    renderRowSubComponent?: any,
    filter?: any,
    setFilter?: SetStateAction<any>,
    loader?: boolean,
    isExpanded?: boolean,
    datacount?: number;
    listcount?: number;
    expandAll?: boolean | Boolean | undefined
}

const TableContainer = ({
    totalCount = 0,
    sortBy,
    setSortBy,
    currentPage = 1,
    setCurrentPage,
    dynamicPagination,
    columns,
    data,
    isAddOptions,
    handleBtnClick,
    customPageSize = 0,
    className,
    filter,
    setFilter,
    loader,
    renderRowSubComponent,
    isExpanded, expandAll
}: tableProps) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        toggleAllRowsExpanded
    }: any = useTable(
        {
            columns,
            data
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    );

    useEffect(() => {
        toggleAllRowsExpanded(expandAll ? true : false);
    }, [expandAll])

    return (
        <Fragment>
            {/* button */}
            {
                isAddOptions &&
                <div className="d-flex justify-content-end">
                    <PrimaryButton
                        btnType="button"
                        className="mb-2 me-2 secountry-btn"
                        onClick={handleBtnClick}
                        btnName={'aa'}
                    />
                </div>
            }


            {/* table section */}
            <div className="table-responsive react-table custom-table custom-scroll">
                <Table bordered {...getTableProps()} className={`${className}`}>
                    {/* table header */}
                    <TableHeaderSection columns={columns} currentPage={currentPage} filter={filter} headerGroups={headerGroups} setCurrentPage={setCurrentPage} setFilter={setFilter} setSortBy={setSortBy} sortBy={sortBy} />

                    {/* table body */}
                    <TableBodySection getTableBodyProps={getTableBodyProps} page={rows} prepareRow={prepareRow} renderRowSubComponent={renderRowSubComponent} visibleColumns={visibleColumns} isExpanded={isExpanded} />
                </Table>
                {/* data not available */}
                {
                    <>
                        {loader === false &&
                            <>
                                {
                                    data?.length === 0 && <NoData />
                                }
                            </>
                        }
                    </>
                }

                {/* pagination */}
                {totalCount > customPageSize &&
                    <div className="pagination-wrapper">
                        <div className="total-count">
                            {totalCount > 0 && !loader ? <Label className={`mb-0`}>Total count: <span>{totalCount}</span></Label> : null}
                        </div>
                        <div>
                            {totalCount > customPageSize && dynamicPagination ? (
                                <div className="page-count">
                                    <Pagination
                                        className="pagination-bar custom-pagination"
                                        currentPage={currentPage}
                                        totalCount={totalCount}
                                        pageSize={customPageSize}
                                        onPageChange={(page: any) => setCurrentPage(page)}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                }
            </div>
        </Fragment>
    );
};

export default TableContainer;
