import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

// Login Method
export const login = (data: any) => post(url.LOGIN, data);
export const forgotPassword = (data: any) => post(url.FORGOT_PASSWORD, data);
export const forgotPasswordReset = (data: any) => post(url.FORGOT_PASSWORD_RESET, data);

//manage
export const addEmployee = (data: any) => post(url.ADD_EMPLOYEE, data);
export const getEmployeesList = (data: any, currentPage: number, pageSize: number) => post(`${url.GET_EMPLOYEES}/${currentPage}/${pageSize}`, data);
export const getByEmployeeId = (id: any) => get(`${url.GET_BY_EMPLOYEE_ID}/${id}`);
export const updateEmployee = (data: any, id: number) => put(`${url.UPDATE_EMPLOYEE}/${id}`, data);
export const employeeStatus = (status: any, id: number) => put(`${url.EMPLOYEE_STATUS}/${id}`, status);
export const getManageSalary = (data: any) => post(url.GET_MANAGE_SALARY, data);
export const addManageSalary = (data: any) => post(url.MANAGE_SALARY_ADD, data);
export const mapResponsibility = (data: any, currentPage: number, pageSize: number) => post(`${url.MAP_RESPONS}/${currentPage}/${pageSize}`, data);

//scope
export const getorganisation = () => get(url.GET_ORGANISATION);
export const updateorganisation = (data: any) => { return put(`${url.UPDATE_ORGANISATION}/${data.id}`, data) }
export const getdivisionscope = (data: any, currentPage: number, pageSize: number) => post(`${url.GET_DIVISION_SCOPE}/${currentPage}/${pageSize}`, data);
export const getdepartmentscope = (data: any, currentPage: number, pageSize: number) => post(`${url.GET_DEPARTMENT_SCOPE}/${currentPage}/${pageSize}`, data);

//setups:-
//Role
export const addRole = (data: any) => post(url.ADD_ROLE, data);
export const updateRole = (id: number, data: any) => { return put(`${url.UPDATE_ROLE}/${id}`, data) };
export const changestatusRole = (id: number, data: any) => { return put(`${url.CHANGE_STATUS_ROLE}/${id}`, data) };
//Department
export const addDepartment = (data: any) => post(url.ADD_DEPARTMENT, data);
export const updateDepartment = (id: number, data: any) => { return put(`${url.UPDATE_DEPARTMENT}/${id}`, data) };
export const changestatusDepartment = (id: number, data: any) => { return put(`${url.CHANGE_STATUS_DEPARTMENT}/${id}`, data) };
//Division
export const addDivision = (data: any) => post(url.ADD_DIVISION, data);
export const updateDivision = (id: number, data: any) => { return put(`${url.UPDATE_DIVISION}/${id}`, data) };
export const changestatusDivision = (id: number, data: any) => { return put(`${url.CHANGE_STATUS_DIVISION}/${id}`, data) };
//Classification
export const addClassification = (data: any) => post(url.ADD_CLASSIFICATION, data);
export const updateClassification = (id: number, data: any) => { return put(`${url.UPDATE_CLASSIFICATION}/${id}`, data) };
export const changestatusClassification = (id: number, data: any) => { return put(`${url.CHANGE_STATUS_CLASSIFICATION}/${id}`, data) };
//Responsibility
export const addResponsibility = (data: any) => post(url.ADD_RESPONSIBILITY, data);
export const updateresponsibility = (id: number, data: any) => { return put(`${url.UPDATE_RESPONSIBILITY}/${id}`, data) };
export const changestatusResponsibility = (id: number, data: any) => { return put(`${url.CHANGE_STATUS_RESPOSIBILITY}/${id}`, data) };
//Frequency
export const addFrequency = (data: any) => post(url.ADD_FREQUENCY, data);
export const updateFrequency = (id: number, data: any) => { return put(`${url.UPDATE_FREQUENCY}/${id}`, data) };
export const changestatusFrequency = (id: number, data: any) => { return put(`${url.CHANGE_STATUS_FREQUENCY}/${id}`, data) };
//Get setups
export const getRole = (data: any, currentPage: number, pageSize: number) => post(`${url.GET_ROLE}/${currentPage}/${pageSize}`, data);
export const getDepartment = (data: any, currentPage: number, pageSize: number) => post(`${url.GET_DEPARTMENT}/${currentPage}/${pageSize}`, data);
export const getDivision = (data: any, currentPage: number, pageSize: number) => post(`${url.GET_DIVISION}/${currentPage}/${pageSize}`, data);
export const getResponsibility = (data: any, currentPage: number, pageSize: number) => post(`${url.GET_RESPONSIBILITY}/${currentPage}/${pageSize}`, data);
export const getclassification = (data: any, currentPage: number, pageSize: number) => post(`${url.GET_CLASSIFICATION}/${currentPage}/${pageSize}`, data);
export const getfrequency = (data: any, currentPage: number, pageSize: number) => post(`${url.GET_FREQUENCY}/${currentPage}/${pageSize}`, data);
// overview getMyResponsiblity
export const getMyResponsiblity = (data: any) => post(`${url.GET_MYRESPONSIBILITY}`, data);
export const getDivisionResponsiblity = (data: any, currentPage: number, pageSize: number) => post(`${url.GET_DIVISION_RESPONSIBILITY}/${currentPage}/${pageSize}`, data);

//manage responsibilities
export const getResponsibilities = (data: {
    division: string,
    department: string,
}) => post(url.GET_RESPONSIBILITIES, data);

//manage responsibilities
export const addResponsibilities = (data: {
    divisionFKId: string,
    departmentFKId: string,
    responsibilityFKId: [string],
}) => post(url.ADD_RESPONSIBILITIES, data);
export const getDndResponsiblity = (data: any) => post(`${url.GET_DND_RESPONSIBILITY}`, data);
export const changeResponsibilityStatus = (data: any, id: any) => put(`${url.CHANGE_RESPONSIBILITY_STATUS}/${id}`, data)
//assign emp
export const assignEmp = (data: any) => post(`${url.ASSIGN_EMP}`, data);
export const getreporteesListUrl = (data: any) => post(`${url.GET_REPORTEES_LIST}/${data?.currentPage}/${data?.pageSize}`, data?.payload);
export const getreporteesByIdUrl = (id: any, data: any) => post(`${url.GET_REPORTEES_BY_ID}/${id}`, data);
export const getemployeeMappingByIdUrl = (id: any, data: any) => post(`${url.GET_REPORTEES_MAPPING_BY_ID}/${id}`, data);
export const getreporteesData = (id: any) => get(`${url.GET_REPORTEES_DATA}/${id}`);


//profile
export const changePassword = (data: any) => post(`${url.CHANGE_PASSWORD}`, data)
export const updateProfile = (data: any, headers: any) => post(`${url.UPDATE_PROFILE_IMAGE}`, data, headers)
export const assignDndbyEmp = (data: any) => post(`${url.MAP_DND_BY_EMP_ID}`, data);
export const getProfile = (data: any) => post(`${url.GET_PROFILE_IMAGE}`, data)

//get setups by ID
// export const getRoleById = (id: any) => { return get(`${url.GET_ROLE_BY_ID}/${id}`) };
// export const getDepartmentById = (id: any) => get(`${url.GET_DEPARTMENT_BY_ID}/${id}`);