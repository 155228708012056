import React, { useEffect, useState } from 'react'
import approvedIcon from "../../assets/images/svgIcons/status/approved.svg"
import doneIcon from "../../assets/images/svgIcons/status/done.svg"
import infoIcon from "../../assets/images/svgIcons/status/info.svg"
import pendingIcon from "../../assets/images/svgIcons/status/pending.svg"
import rejectIcon from "../../assets/images/svgIcons/status/rejected.svg"
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
interface StatusDropdownProps {
    selectOptoion: (value: any) => void,
    defaultValue?: string | undefined,
    id: Number,
    context: string,
    associateFKId: Number;
    rejectreason: string
}

function StatusDropdown(props: StatusDropdownProps) {
    const { selectOptoion, defaultValue, id, context, associateFKId, rejectreason } = props

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(defaultValue);

    let options: string[] = [];

    if (context === 'myResponsibility' || defaultValue === "rejected") {
        options = ['Done'];
    } else if (context === 'reporteeDetails') {
        options = ['Approve', 'Reject'];
    }

    const toggle = () => {
        if (context === 'myResponsibility' && defaultValue === "pending" || context === 'reporteeDetails' && defaultValue === "done" || context === 'myResponsibility' && defaultValue === "rejected") {
            setDropdownOpen((prevState) => !prevState)
        }
    };

    const handleSelect = (value: string) => {
        setSelectedValue(value);
        setDropdownOpen(true);
        const payload: any = {
            data: { status: value },
            id: id
        };
        if (value === 'Approve') {
            payload.data.associateFKId = associateFKId;
        }
        selectOptoion(payload);
    }

    return (
        <div className='dropdownSelect'>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction='up'>
                <DropdownToggle
                    className={`customdropdown ${!dropdownOpen && (defaultValue === 'pending' || defaultValue === 'approved' || defaultValue === 'done' || defaultValue === 'rejected') ? 'changeview' : 'customdropdown'}`}
                    defaultValue={defaultValue}>
                    <div className='options'>
                        <img src={defaultValue === "pending" ? pendingIcon : defaultValue === "done" ? doneIcon : defaultValue === "approved" ? approvedIcon : rejectIcon} alt="" />
                        <p>{defaultValue}</p>
                        {defaultValue === "rejected" ? (
                            <div className='tips'>
                                <img src={infoIcon} alt="tooltip" />
                                <span className="tooltiptext">{rejectreason}</span>
                            </div>
                        ) : defaultValue === "done" ? (
                            <div className='tips'>
                                <img src={infoIcon} alt="tooltip" />
                                <span className="tooltiptext">Submitted to Manager Approval</span>
                            </div>
                        ) : null}
                    </div>
                </DropdownToggle>
                <DropdownMenu className='toggleMenu'>
                    {options?.map(option => {
                        return (
                            <DropdownItem key={option} onClick={() => handleSelect(option)}>
                                <div className='options'>
                                    <img src={option === 'Done' ? doneIcon : option === 'Approve' ? approvedIcon : option === 'Pending' ? pendingIcon : rejectIcon} alt={option} />
                                    <p>{option}</p>
                                </div>
                            </DropdownItem>
                        )
                    }
                    )}
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}

export default StatusDropdown