import { Label, Modal } from "reactstrap"
import PrimaryButton from "../buttons/PrimaryButton"
import SecondaryButton from "../buttons/SecondaryButton"
import closeIcon from '../../assets/images/svgIcons/close_icon.svg';
import DropDown from "../DropDown";
import { monthsOptions, yearsOptions } from "../../constants/datas/dropdownOptions";
import { useForm } from "react-hook-form"
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import ErrorValidationMessage from "../ErrorValidationMessage";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { addManageSalaryThunk, getManageSalaryThunk } from "../../store/asyncThunks/manage/mapping";
import SalaryTableSkeleton from "../skeleton/SalaryTableSkeleton";
import circleTick from "../../assets/images/svgIcons/circle_tick.svg";
import NoData from "../NoData";

interface manageSalaryProps {
    showModal: boolean,
    toggle: Function,
    secondaryAction: Function,
    userData: any
}

const ManageSalaryModal = (props: manageSalaryProps) => {

    const dispatch = useAppDispatch();

    const { toggle, showModal, secondaryAction, userData } = props;
    const { profileImg, name, designation, empId } = userData;

    const [yearValue, setYearValue] = useState({ label: '', value: "" });
    const [monthValue, setMonthValue] = useState({ label: '', value: "" });

    const employeeSalary = useAppSelector((state: any) => state?.getManageSalaryReducer?.data?.data);
    const employeeSalaryLoading = useAppSelector((state: any) => state?.getManageSalaryReducer?.loading);
    const addSalaryLoader = useAppSelector((state: any) => state?.addManageSalaryReducer?.loading);

    // getManageSalaryReducer
    const addEmployeeFormSchema = yup.object().shape({
        year: yup.string().test("select", '-', val => {
            if (!val) return false;
            else return true
        }),
        month: yup.string().test("select", '-', val => {
            if (!val) return false;
            else return true
        }),
        salary: yup.string().required().trim(),
        incentive: yup.string().required().trim(),
    });

    const formValues = {
        associateFKId: userData?.employeeId,
        year: '',
        month: '',
        salary: '',
        incentive: '',
    }

    const { handleSubmit, register, formState: { errors }, setValue, clearErrors, reset } = useForm({
        defaultValues: formValues,
        resolver: yupResolver(addEmployeeFormSchema),
    })

    const onSubmit = (values: any) => {
        const data: any = {}
        data['data'] = values;
        data['datasubmited'] = salaryData;
        data['afterSuccess'] = clearFormData;
        dispatch(addManageSalaryThunk(data));
    };

    const clearFormData = () => {
        reset();
        setMonthValue({ label: '', value: "" });
        setYearValue({ label: '', value: "" });
    };
    const handleYearChange = (value: any) => {
        setYearValue(value);
        setValue('year', value?.value);
        clearErrors('year');
    }

    const handleMonthChange = (value: any) => {
        setMonthValue(value);
        setValue('month', value?.value);
        clearErrors('month');
    }

    const salaryData = () => {
        const data: any = {}
        data['associateFKId'] = userData?.employeeId;
        dispatch(getManageSalaryThunk(data));
    }
    useEffect(() => {
        salaryData();
    }, [userData?.employeeId]);


    return (
        <Modal size="md" isOpen={showModal} centered={true}
            className='manage-salary-modal '
            toggle={() => toggle()}
        >
            <div className="modal-header">
                <h1 className='title'>Manage salary</h1>
                <span onClick={() => toggle()} aria-hidden="true" className='close-icon'><img src={closeIcon} alt="close" /></span>
            </div>
            <div className="modal-body ">
                <div className="profile-section">
                    <div className="profile-img">
                        {
                            profileImg ?
                                <img src={profileImg} alt="profile-img" /> :
                                <span>{name.charAt(0).toUpperCase()}</span>
                        }
                    </div>
                    <div className="profile-details">
                        <h2 className="name">{name?.toLowerCase() || ""}</h2>
                        <div className="badges">
                            <p className="badge-primary">{designation || ""}</p>
                            <p className="emp-id">
                                <img src={circleTick} alt="tick" />
                                {empId || ""}
                            </p>
                        </div>
                    </div>
                </div>

                <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-fields">
                        <div className="groups">
                            <div className="group-item">
                                <Label className='required'>Year</Label>
                                <DropDown
                                    loading={false}
                                    classNamePrefix={errors.year?.message ? "error-input " : ""}
                                    options={yearsOptions}
                                    value={yearValue}
                                    onChange={handleYearChange}
                                />
                                {errors.year?.message && <ErrorValidationMessage errors={errors.year} name="year" />}
                            </div>
                            <div className="group-item">
                                <Label className='required'>Month</Label>
                                <DropDown
                                    loading={false}
                                    classNamePrefix={errors.month?.message ? "error-input " : ""}
                                    options={monthsOptions}
                                    value={monthValue}
                                    onChange={handleMonthChange}
                                />
                                {errors.month?.message && <ErrorValidationMessage errors={errors.month} name="month" />}
                            </div>
                        </div>
                        <div className="groups">
                            <div className='group-item'>
                                <Label className='required'>Salary(₹)</Label>
                                <input type="number" className={`custom-input form-control ${errors.salary?.message ? "error-input" : ""}`} {...register("salary")} />
                                {errors.salary?.message && <ErrorValidationMessage errors={errors.salary} name="salary" />}
                            </div>
                            <div className='group-item'>
                                <Label className='required'>Incentive(₹)</Label>
                                <input type="number" className={`custom-input form-control ${errors.incentive?.message ? "error-input" : ""}`} {...register("incentive")} />
                                {errors.incentive?.message && <ErrorValidationMessage errors={errors.incentive} name="incentive" />}
                            </div>
                        </div>
                    </div>

                    <div className="model-actions">
                        <SecondaryButton
                            btnName={`Clear`}
                            className="btn-position"
                            btnType="button"
                            onClick={(e) => {
                                // secondaryAction(e);
                                clearFormData()
                            }}
                        />
                        <PrimaryButton btnName={`Add`} loader={addSalaryLoader} />
                    </div>
                </form>

                <div className="table-section">
                    <p className="table-heading">Salary's added</p>
                    {employeeSalaryLoading ? <SalaryTableSkeleton /> :
                        <div className="body-content">
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Year</th>
                                        <th>Month</th>
                                        <th>Salary(₹)</th>
                                        <th>Incentive(₹)</th>
                                    </tr>
                                </thead>
                                <tbody className='custom-scroll'>
                                    {employeeSalary && employeeSalary?.length === 0 ?
                                        <>
                                            <tr>
                                                <td>
                                                    <NoData />
                                                </td>
                                            </tr>

                                        </>
                                        : <>
                                            {employeeSalary?.map((item: any, index: any) => (
                                                <tr key={`manage-salary-tablerow-${index}`}>
                                                    <td>{item?.year}</td>
                                                    <td>{item?.month}</td>
                                                    <td>{item?.salary}</td>
                                                    <td>{item?.incentive}</td>
                                                </tr>
                                            ))}
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </Modal >
    )
}

export default ManageSalaryModal