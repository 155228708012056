import React, { SetStateAction } from 'react';
import { useEffect, useMemo, useState } from 'react';
import TableContainer from '../../../components/Table/TableContainer';
import ToggleSwitch from '../../../components/ToggleSwitch';
import edit from '../../../assets/images/svgIcons/edit_iocn.svg';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import InputSearch from '../../../components/InputSearch';
import ManageDepartmentModal from '../../../components/Models/ManageSetupsModalComponent/ManageDepartmentModal';
import { changestatusDepartmentThunk, getDepartmentThunk } from '../../../store/asyncThunks/manage/setups';
import ConfirmationModal from '../../../components/Models/ConfirmationModal';
import TableSkeleton from '../../../components/skeleton/TableSkeleton';
import { updateDepartmentData } from '../../../store/slices/manage/setups/Department/getDepartment';
import { Card } from 'reactstrap';
import TableWithCardLoader from '../../../components/TableWithCardLoader';

interface DepartmentProps {
    currentPage: number;
    setCurrentPage: SetStateAction<any>;
    reset: number
}

const Department = (props: DepartmentProps) => {
    const { currentPage, setCurrentPage, reset } = props;

    document.title = `Setups | ${process.env.REACT_APP_DOCUMENT_TITLE}`;

    const dispatch = useAppDispatch()

    const [searchValue, setSearchValue] = useState("");
    const [EditData, setEditData] = useState<any>();
    const [statusData, setStatusData] = useState<any>(null);
    const [showModal, setshowModal] = useState(true);
    const [statusShowModal, setstatusShowModal] = useState(true);
    const pageSize = process.env.REACT_APP_PAGE_SIZE || 10;
    const getDepartmentList = useAppSelector((state) => state?.getDepartmentReducer?.data?.departments);
    const getDepartmentLoading = useAppSelector((state) => state?.getDepartmentReducer?.loading);
    const DepartmentCount = useAppSelector((state) => state?.getDepartmentReducer?.data?.totalCount)
    const DepartmentStatusLoader = useAppSelector((state) => state?.changestatusDepartmentReducer?.loading)

    const handleSearch = (value: any) => {
        setSearchValue(value);
        setCurrentPage(1);
    }

    useEffect(() => {
        getData()
    }, [searchValue, currentPage, reset]);

    const getData = async () => {
        const data: any = {}
        data["currentPage"] = currentPage
        data["pageSize"] = pageSize
        data["data"] = { search: searchValue }
        await dispatch(getDepartmentThunk(data));
    }

    const toggleModal = () => {
        setshowModal(!showModal);
    };

    const toggleStatusModal = () => {
        setstatusShowModal(!statusShowModal);
    };

    const handleEditClick = (Rowdata: any) => {
        setEditData(Rowdata);
        setshowModal(true);
    };

    const handleToggleSwitch = (Rowdata: any) => {
        setStatusData(Rowdata);
        setstatusShowModal(true);
    }
    const DepartmentUpdateStatus = () => {
        const status = {
            status: statusData?.status === "INACTIVE" ? "ACTIVE" : "INACTIVE",
        }
        const data: any = {
            data: status,
            id: statusData?._id,
            afterSuccess: (newStatus:any) => {
                dispatch(updateDepartmentData(newStatus));
                setstatusShowModal(false);
            },
        };
        dispatch(changestatusDepartmentThunk(data));

    };

    const handleModalPagination = () => {
        getData();
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'firstName',
                Cell: (cell: any) => {
                    return <div className='label-cell'>{cell?.row?.original?.label}</div>;
                },
            },
            {
                Header: 'Description',
                accessor: 'description',
                Cell: (cell: any) => {
                    return <div className='description-cell'>{cell?.row?.original?.scope}</div>;
                },
            },
            {
                Header: 'Status',
                Cell: (cell: any) => {
                    const Rowdata = cell?.row?.original;
                    return <ToggleSwitch id={cell.row.index} defaultChecked={cell.row.original.status?.toUpperCase() === "ACTIVE" ? true : false}
                        onChange={(e: any) => {
                            handleToggleSwitch(Rowdata)
                        }} />
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: (cell: any) => {
                    const Rowdata = cell?.row?.original;
                    return <div className='actions'>
                        <div className="edit" onClick={() => handleEditClick(Rowdata)}>
                            <img src={edit} alt="edit" />
                        </div>
                    </div>
                }
            },
        ], [getDepartmentList]);

    return (
        <div className='dept-setups'>
            <div className='table-search'>
                <InputSearch onSearch={handleSearch} value={searchValue} />
            </div>
            <Card className={!getDepartmentList ? `loader-card` : ""} >
                <TableWithCardLoader
                    data={getDepartmentList || []}
                    columns={columns}
                    loader={getDepartmentLoading}
                    totalCount={DepartmentCount}
                    customPageSize={Number(pageSize)}
                    dynamicPagination={true}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </Card>
            {EditData && (
                <ManageDepartmentModal
                    showModal={showModal}
                    toggle={toggleModal}
                    mode='Edit'
                    EditData={EditData}
                    handleModalPagination={handleModalPagination}
                />
            )}
            {statusData && (
                <ConfirmationModal
                    showModal={statusShowModal}
                    toggle={toggleStatusModal}
                    modalTitle={`Confirmation!`}
                    primaryAction={DepartmentUpdateStatus}
                    secondaryAction={toggleStatusModal}
                    loader={DepartmentStatusLoader}
                    primaryBtnName={`${statusData?.status === "INACTIVE" ? 'Activate' : 'Deactivate'}`}
                    secoundaryBtnName="Cancel"
                    description={`Are you sure, do you want to  ${statusData?.status === "ACTIVE" ? 'deactivate' : 'activate'} this department  "${statusData?.label} "?`} />
            )}
        </div>
    )
}

export default Department