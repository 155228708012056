import { createSlice } from "@reduxjs/toolkit";
import { defaultState } from "../../../../interfaces/auth";
import { getRoleThunk } from "../../../../asyncThunks/manage/setups";

const initialState: defaultState = {
    error: null,
    data: [],
    loading: false,
};

export const getRole = createSlice({
    name: "getRole",
    initialState,
    reducers: {
        updateRoleData: (state, action) => {
            const updatedRole = action.payload;
            if (state.data) {
                const index = state.data?.role?.findIndex((role: { _id: any; }) => role._id === updatedRole._id);
                if (index !== -1) {
                    state.data.role[index] = updatedRole;
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getRoleThunk.pending, (state, action) => {
            state.error = null;
            // state.data = null;
            state.loading = true;
        });
        builder.addCase(getRoleThunk.fulfilled, (state, action) => {
            if (action?.payload?.status?.toLowerCase() === "success") {
                state.error = null;
                state.data = action.payload?.data;
            } else {
                state.error = action?.payload;
                state.data = [];
            }
            state.loading = false;
        });
        builder.addCase(getRoleThunk.rejected, (state, action) => {
            state.error = action.payload;
            state.data = [];
            state.loading = false;
        });
    },
});

export const { updateRoleData } = getRole.actions;

export default getRole.reducer;
