import { useEffect, useState } from 'react'
import IdleModal from '../components/Models/IdleModal';

const CheckIdle = () => {

    const [showModal, setShowModal] = useState(false);

    const checkInactivity = () => {
        const expiryTime: any = localStorage.getItem('expireTime');
        if (expiryTime && Number(expiryTime) < Date.now()) {
            setShowModal(true);
        }
    }

    const updateExpireTime = () => {
        const expireTime = Date.now() + 600000;
        localStorage.setItem('expireTime', expireTime?.toString());
    }

    useEffect(() => {
        const interval = setInterval(() => {
            checkInactivity();
        }, 5000);

        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        updateExpireTime();

        window.addEventListener('click', updateExpireTime);
        window.addEventListener('keypress', updateExpireTime);
        window.addEventListener('scroll', updateExpireTime);
        window.addEventListener('mousemove', updateExpireTime);

        return () => {
            window.removeEventListener('click', updateExpireTime);
            window.removeEventListener('keypress', updateExpireTime);
            window.removeEventListener('scroll', updateExpireTime);
            window.removeEventListener('mousemove', updateExpireTime);
        }
    }, [])

    return (
        <>
            <IdleModal showModal={showModal} toggle={() => setShowModal(!showModal)} />
        </>
    )
}

export default CheckIdle