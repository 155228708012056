import React from 'react';
import noDataImage from '../assets/images/svgImages/no-data.svg'

const NotFound = () => {
    return (
        <div>
            <div className="no-data-msg" style={{ height: "100vh" }}>
                <div>
                    <img src={noDataImage} alt='page not found' />
                    <h6 className="text-center">Page not found</h6>
                </div>
            </div>
        </div>
    );
};

export default NotFound;