interface getActiveTabBasedOnRolePrevilegeProps {
    object: Record<string, any>,
    tabsList: Array<any>
}

const getActiveTabBasedOnRolePrevilege = ({ object, tabsList }: getActiveTabBasedOnRolePrevilegeProps) => {
    const objectKeys = Object.keys(object);
    let index: number | null = null;
    for (let i = 0; i < tabsList?.length; i++) {
        const item = tabsList[i];
        const tab = item?.toLowerCase()?.split(" ")?.join("");
        if (index !== null && index >= 0) {
            break;
        }
        for (let j = 0; j < objectKeys.length; j++) {
            const objItem = objectKeys[j];
            if ((tab === objItem?.toLowerCase()) && object[objItem]?.pageView) {
                index = i;
                break;
            }
        }
    }
    return index;
}

export default getActiveTabBasedOnRolePrevilege
