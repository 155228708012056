import { Label, Modal } from "reactstrap";
import closeIcon from '../../../assets/images/svgIcons/close_icon.svg';
import SecondaryButton from "../../buttons/SecondaryButton";
import PrimaryButton from "../../buttons/PrimaryButton";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { manageRoleThunk, getRoleThunk } from "../../../store/asyncThunks/manage/setups";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { useEffect } from "react";
import ErrorValidationMessage from "../../ErrorValidationMessage";
import { descriptionLimit } from "../../../constants/HandleFormLimit";
import { updateRoleData } from "../../../store/slices/manage/setups/role/getRole";

interface ManageRoleModalProps {
    showModal: boolean,
    toggle: any,
    mode: 'Add' | 'Edit',
    EditData: any,
    handleModalPagination?: Function,
}

const ManageRoleModal = (props: ManageRoleModalProps) => {
    const { showModal, toggle, mode, EditData, handleModalPagination } = props;

    const dispatch = useAppDispatch();

    const addRoleLoader = useAppSelector((state) => state?.manageRoleReducer?.loading);

    const actionSchema = yup.object().shape({
        overview: yup.object().shape({
            pageView: yup.boolean().required(),
            reportees: yup.object().shape({
                pageView: yup.boolean().required(),
            }),
            divisionResponsibilities: yup.object().shape({
                pageView: yup.boolean().required(),
            }),
            myResponsibilities: yup.object().shape({
                pageView: yup.boolean().required(),
            }),
        }),
        scope: yup.object().shape({
            pageView: yup.boolean().required(),
            organisationScope: yup.object().shape({
                pageView: yup.boolean().required(),
            }),
            divisionScope: yup.object().shape({
                pageView: yup.boolean().required(),
            }),
            departmentScope: yup.object().shape({
                pageView: yup.boolean().required(),
            }),
        }),
        manage: yup.object().shape({
            pageView: yup.boolean().required(),
            setups: yup.object().shape({
                pageView: yup.boolean().required(),
                roles: yup.object().shape({
                    pageView: yup.boolean().required(),
                }),
                department: yup.object().shape({
                    pageView: yup.boolean().required(),
                }),
                divisions: yup.object().shape({
                    pageView: yup.boolean().required(),
                }),
                classifications: yup.object().shape({
                    pageView: yup.boolean().required(),
                }),
                frequency: yup.object().shape({
                    pageView: yup.boolean().required(),
                }),
                responsibilities: yup.object().shape({
                    pageView: yup.boolean().required(),
                }),
            }),
            mapping: yup.object().shape({
                pageView: yup.boolean().required(),
                employees: yup.object().shape({
                    pageView: yup.boolean().required(),
                }),
                responsibilities: yup.object().shape({
                    pageView: yup.boolean().required(),
                }),
            }),
        }),
    });

    const FormShcema = yup.object().shape({
        label: yup.string().required().trim(),
        scope: yup.string().required().test("maxLength", '-', val => {
            if (!val) return false;
            return val.length <= descriptionLimit;
        }).trim(),
        order: yup.number().required().typeError('Order must be a number and  greater than or equal to 1 '),
        action: actionSchema,
    })

    const formValues = {
        label: '',
        scope: '',
        order: '',
        action: {
            overview: {
                pageView: false,
                reportees: {
                    pageView: false,
                },
                divisionResponsibilities: {
                    pageView: false,
                },
                myResponsibilities: {
                    pageView: false,
                }
            },
            scope: {
                pageView: false,
                organisationScope: {
                    pageView: false,
                },
                divisionScope: {
                    pageView: false,
                },
                departmentScope: {
                    pageView: false,
                }
            },
            manage: {
                pageView: false,
                setups: {
                    pageView: false,
                    roles: {
                        pageView: false,
                    },
                    department: {
                        pageView: false,
                    },
                    divisions: {
                        pageView: false,
                    },
                    classifications: {
                        pageView: false,
                    },
                    frequency: {
                        pageView: false,
                    },
                    responsibilities: {
                        pageView: false,
                    },
                },
                mapping: {
                    pageView: false,
                    employees: {
                        pageView: false,
                    },
                    responsibilities: {
                        pageView: false,
                    }
                }
            },
        }
    }

    const { handleSubmit, register, formState: { errors }, reset, setValue } = useForm({
        defaultValues: formValues,
        resolver: yupResolver<any>(FormShcema),
    })

    const afterSuccess = () => {
        toggleClose();
        handleModalPagination && handleModalPagination();
    };

    const toggleModel = () => {
        if (EditData?._id) {
            resetFormValues();
        } else {
            toggleClose();
        }
    }

    const toggleClose = () => {
        reset();
        toggle();
    }

    const onSubmit = (values: any) => {
        const data: any = {}
        data['data'] = values;
        data['afterSuccess'] = afterSuccess;
        if (mode === 'Add') {
            dispatch(manageRoleThunk(data));
        } else if (mode === 'Edit') {
            data['id'] = EditData?._id;
            dispatch(manageRoleThunk(data));
        }
    }

    useEffect(() => {
        if (EditData?._id) {
            resetFormValues();
        }
    }, [EditData, EditData?._id])

    

    const resetFormValues = () => {
        reset({
            label: EditData?.label || "",
            scope: EditData?.scope || "",
            order: EditData?.order || 0,
            action: EditData?.action || {}
        });
    }

    return (
        <>
            <Modal size="md" isOpen={showModal} centered={true}
                className='manage-setup add-employee-model add-role-model'
                toggle={toggleModel}
            >
                <div className="modal-header">
                    <h1 className='title'>{mode === 'Add' ? 'Add new role' : 'Update role'}</h1>
                    <span onClick={toggleClose} aria-hidden="true" className='close-icon'><img src={closeIcon} alt="close" /></span>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                    <div className="modal-body custom-scroll">
                        <div className="groups">
                            <div className='group-item'>
                                <Label className='required'>Role name</Label>
                                <input type="text" className={`custom-input form-control ${errors.label?.message ? "error-input" : ""}`} {...register("label")} />
                                {errors.label?.message && <ErrorValidationMessage errors={errors.label} name="role name" />}
                            </div>
                        </div>
                        <div className="groups">
                            <div className="group-item">
                                <Label className='required'>Description</Label>
                                <textarea
                                    className={`custom-input form-control  custom-scroll text-area ${errors.scope?.message ? "error-input" : ""}`} {...register("scope")}
                                ></textarea>
                                {errors.scope?.message && <ErrorValidationMessage errors={errors.scope} name="description" sizeMax={descriptionLimit} />}
                            </div>
                        </div>
                        <div className="groups">
                            <div className="group-item">
                                <Label className='required'>Order</Label>
                                <input type="number" className={`custom-input form-control ${errors.order?.message ? "error-input" : ""}`} {...register("order")} onWheel={(e) => e.currentTarget.blur()} />
                                {errors.order?.message && <ErrorValidationMessage errors={errors.order} name="order" />}
                            </div>
                        </div>
                        <div className="previleges">
                            <div className="heading">
                                <h2>Previleges</h2>
                                <h2>View</h2>
                            </div>
                            <div className="content">
                                <div className="groups">
                                    <div className="group-item">
                                        <div className="list">
                                            <Label htmlFor={`action.overview.pageView`} className="title">Overview</Label>
                                            <div className="inputs">
                                                <div className="form-check">
                                                    <input className="form-check-input" id="action.overview.pageView" {...register('action.overview.pageView')} type="checkbox" />
                                                    <label className="form-check-label" htmlFor={`action.overview.pageView`}>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sub-list">
                                            <div className="list">
                                                <Label className="cursor-pointer" htmlFor={`action.overview.myResponsibilities.pageView`} >My responsibilities</Label>
                                                <div className="inputs">
                                                    <div className="form-check">
                                                        <input className="form-check-input" id="action.overview.myResponsibilities.pageView" {...register('action.overview.myResponsibilities.pageView')} type="checkbox" />
                                                        <label className="form-check-label" htmlFor={`action.overview.myResponsibilities.pageView`}>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list">
                                                <Label className="cursor-pointer" htmlFor={`action.overview.divisionResponsibilities.pageView`} >Division responsibilities</Label>
                                                <div className="inputs">
                                                    <div className="form-check">
                                                        <input className="form-check-input" id="action.overview.divisionResponsibilities.pageView" {...register('action.overview.divisionResponsibilities.pageView')} type="checkbox" />
                                                        <label className="form-check-label" htmlFor={`action.overview.divisionResponsibilities.pageView`}>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list">
                                                <Label className="cursor-pointer" htmlFor={`action.overview.reportees.pageView`}>Reportees</Label>
                                                <div className="inputs">
                                                    <div className="form-check">
                                                        <input className="form-check-input" id="action.overview.reportees.pageView" {...register('action.overview.reportees.pageView')} type="checkbox" />
                                                        <label className="form-check-label" htmlFor={`action.overview.reportees.pageView`}>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="groups">
                                    <div className="group-item">
                                        <div className="list">
                                            <Label htmlFor={`action.scope.pageView`} className="title">Scope</Label>
                                            <div className="inputs">
                                                <div className="form-check">
                                                    <input className="form-check-input" id="action.scope.pageView" {...register('action.scope.pageView')} type="checkbox" />
                                                    <label className="form-check-label" htmlFor={`action.scope.pageView`}>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sub-list">
                                            <div className="list">
                                                <Label className="cursor-pointer" htmlFor={`action.scope.organisationScope.pageView`}>Organisation scope</Label>
                                                <div className="inputs">
                                                    <div className="form-check">
                                                        <input className="form-check-input" id="action.scope.organisationScope.pageView" {...register('action.scope.organisationScope.pageView')} type="checkbox" />
                                                        <label className="form-check-label" htmlFor={`action.scope.organisationScope.pageView`}>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list">
                                                <Label className="cursor-pointer" htmlFor={`action.scope.divisionScope.pageView`}>Division scope</Label>
                                                <div className="inputs">
                                                    <div className="form-check">
                                                        <input className="form-check-input" id="action.scope.divisionScope.pageView" {...register('action.scope.divisionScope.pageView')} type="checkbox" />
                                                        <label className="form-check-label" htmlFor={`action.scope.divisionScope.pageView`}>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list">
                                                <Label className="cursor-pointer" htmlFor={`action.scope.departmentScope.pageView`}>Department scope</Label>
                                                <div className="inputs">
                                                    <div className="form-check">
                                                        <input className="form-check-input" id="action.scope.departmentScope.pageView" {...register('action.scope.departmentScope.pageView')} type="checkbox" />
                                                        <label className="form-check-label" htmlFor={`action.scope.departmentScope.pageView`}>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="groups">
                                    <div className="group-item">
                                        <div className="list">
                                            <Label className="title" htmlFor={`action.manage.pageView`}>Manage</Label>
                                            <div className="inputs">
                                                <div className="form-check">
                                                    <input className="form-check-input" id="action.manage.pageView" {...register('action.manage.pageView')} type="checkbox" />
                                                    <label className="form-check-label" htmlFor={`action.manage.pageView`}>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="sub-list">
                                            <div className="list">
                                                <Label className="title" htmlFor={`action.manage.setups.pageView`}>Setups</Label>
                                                <div className="inputs">
                                                    <div className="form-check">
                                                        <input className="form-check-input" id="action.manage.setups.pageView" {...register('action.manage.setups.pageView')} type="checkbox" />
                                                        <label className="form-check-label" htmlFor={`action.manage.setups.pageView`}>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sub-list">
                                                <div className="list">
                                                    <Label className="cursor-pointer" htmlFor={`action.manage.setups.roles.pageView`}>Roles</Label>
                                                    <div className="inputs">
                                                        <div className="form-check">
                                                            <input className="form-check-input" id="action.manage.setups.roles.pageView" {...register('action.manage.setups.roles.pageView')} type="checkbox" />
                                                            <label className="form-check-label" htmlFor={`action.manage.setups.roles.pageView`}>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list">
                                                    <Label className="cursor-pointer" htmlFor={`action.manage.setups.department.pageView`}>Department</Label>
                                                    <div className="inputs">
                                                        <div className="form-check">
                                                            <input className="form-check-input" id="action.manage.setups.department.pageView" {...register('action.manage.setups.department.pageView')} type="checkbox" />
                                                            <label className="form-check-label" htmlFor={`action.manage.setups.department.pageView`}>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list">
                                                    <Label className="cursor-pointer" htmlFor={`action.manage.setups.divisions.pageView`}>Divisions</Label>
                                                    <div className="inputs">
                                                        <div className="form-check">
                                                            <input className="form-check-input" id="action.manage.setups.divisions.pageView" {...register('action.manage.setups.divisions.pageView')} type="checkbox" />
                                                            <label className="form-check-label" htmlFor={`action.manage.setups.divisions.pageView`}>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list">
                                                    <Label className="cursor-pointer" htmlFor={`action.manage.setups.classifications.pageView`} >Classifications</Label>
                                                    <div className="inputs">
                                                        <div className="form-check">
                                                            <input className="form-check-input" id="action.manage.setups.classifications.pageView" {...register('action.manage.setups.classifications.pageView')} type="checkbox" />
                                                            <label className="form-check-label" htmlFor={`action.manage.setups.classifications.pageView`}>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list">
                                                    <Label className="cursor-pointer" htmlFor={`action.manage.setups.frequency.pageView`}>Frequency</Label>
                                                    <div className="inputs">
                                                        <div className="form-check">
                                                            <input className="form-check-input" id="action.manage.setups.frequency.pageView" {...register('action.manage.setups.frequency.pageView')} type="checkbox" />
                                                            <label className="form-check-label" htmlFor={`action.manage.setups.frequency.pageView`}>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list">
                                                    <Label className="cursor-pointer" htmlFor={`action.manage.setups.responsibilities.pageView`}>Responsibilities</Label>
                                                    <div className="inputs">
                                                        <div className="form-check">
                                                            <input className="form-check-input" id="action.manage.setups.responsibilities.pageView" {...register('action.manage.setups.responsibilities.pageView')} type="checkbox" />
                                                            <label className="form-check-label" htmlFor={`action.manage.setups.responsibilities.pageView`}>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="list">
                                                <Label className="title" htmlFor={`action.manage.mapping.pageView`}>Mapping</Label>
                                                <div className="inputs">
                                                    <div className="form-check">
                                                        <input className="form-check-input" id="action.manage.mapping.pageView" {...register('action.manage.mapping.pageView')} type="checkbox" />
                                                        <label className="form-check-label" htmlFor={`action.manage.mapping.pageView`}>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sub-list">
                                                <div className="list">
                                                    <Label className="cursor-pointer" htmlFor={`action.manage.mapping.employees.pageView`}>Employees</Label>
                                                    <div className="inputs">
                                                        <div className="form-check">
                                                            <input className="form-check-input" id="action.manage.mapping.employees.pageView" {...register('action.manage.mapping.employees.pageView')} type="checkbox" />
                                                            <label className="form-check-label" htmlFor={`action.manage.mapping.employees.pageView`}>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list">
                                                    <Label className="cursor-pointer" htmlFor={`action.manage.mapping.responsibilities.pageView`}>Responsibilities</Label>
                                                    <div className="inputs">
                                                        <div className="form-check">
                                                            <input className="form-check-input" id="action.manage.mapping.responsibilities.pageView" {...register('action.manage.mapping.responsibilities.pageView')} type="checkbox" />
                                                            <label className="form-check-label" htmlFor={`action.manage.mapping.responsibilities.pageView`}>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="model-actions">
                            <SecondaryButton btnName={mode === 'Add' ? 'Cancel' : 'Clear'} btnType='button' className='cancel-btn btn-position' onClick={toggleModel} />
                            <PrimaryButton btnName={mode === 'Add' ? 'Add' : 'Update'} btnType='submit' loader={addRoleLoader} />
                        </div>
                    </div>
                </form>
            </Modal >
        </>
    )
}
export default ManageRoleModal


