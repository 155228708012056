import { SetStateAction } from "react";
import AppLogo from "../components/AppLogo";
import Notification from "./headerComponents/Notification";
import Profile from "./headerComponents/Profile";

interface props {
  setOpenMenu: SetStateAction<any>,
  openMenu: boolean,
  isShortMenu: boolean,
}

const Header = (props: props) => {
  const { openMenu, setOpenMenu, isShortMenu } = props;

  return (
    <div className="header">
      <AppLogo openMenu={openMenu} setOpenMenu={setOpenMenu} isShortMenu={isShortMenu} />
      <div className="actions">
        {/* <Notification /> */}
        {/* light and dark mode */}
        {/* <ModeChange /> */}

        <Profile />
      </div>
    </div>
  );
};

export default Header;
