interface pageTitleProps {
    title: string
}

const PageTitle = (props: pageTitleProps) => {
    const { title } = props;

    return (
        <h1 className='page-title mb-4'>{title}</h1>
    )
}

export default PageTitle