import { Card, CardBody } from 'reactstrap';
import responsibility_approved from '../../assets/images/svgIcons/responsibility_approved.svg';
import reportToSvg from '../../assets/images/svgIcons/report_to.svg';
import UserCardMenuDropdown from '../UserCardMenuDropdown';
import threeDots from '../../assets/images/svgIcons/three_dots.svg';
import EditEmployeeModal from '../Models/EditEmployeeModal';
import { useState } from 'react';

export interface userCardProps {
    profileImg?: string,
    name: string,
    designation: string,
    email: string,
    reportTo: string,
    empId: string,
    status: string,
    employeeId: string
}

const UserCard = (props: userCardProps) => {
    const { profileImg, name, designation, email, reportTo, empId, status, employeeId } = props;

    return (
        <>

            <Card className='user-card'>
                <CardBody>
                    <div>
                        <div className={`profile-img ${status?.toLowerCase()}`} >
                            {
                                profileImg ?
                                    <img src={profileImg} alt="profileImg" /> :
                                    <span>{name.charAt(0).toUpperCase()}</span>
                            }
                        </div>
                    </div>
                    <div className="profile-details">
                        <p className="name text-capitalize">{name}</p>
                        <div className="badges">
                            <p className="designation badge-primary text-capitalize w-auto">{designation}</p>
                            <div className="counts">
                                <div className="responsibility-approved">
                                    <img src={responsibility_approved} alt="responsibility" />
                                </div>
                                <p className="count">{empId}</p>
                            </div>
                        </div>
                        <p className="email">{email}</p>
                        <div className="report-to">
                            <div className="img">
                                <img src={reportToSvg} alt="reports-to" />
                            </div>
                            <p className='text-capitalize'>{reportTo}</p>
                        </div>
                    </div>
                    <div className='menu filter-dropdown'>
                        <UserCardMenuDropdown
                            name={name}
                            designation={designation}
                            email={email}
                            reportTo={reportTo}
                            empId={empId}
                            status={status}
                            employeeId={employeeId}
                            profileImg={profileImg}
                        />
                    </div>
                </CardBody>
            </Card>


        </>
    )
}

export default UserCard