import { createSlice } from "@reduxjs/toolkit";
import { defaultState } from "../../../../interfaces/auth";
import { assignDndByEmpIdThunk } from "../../../../asyncThunks/manage/mapping";

const initialState: defaultState = {
  error: null,
  data: null,
  loading: false,
};

export const assignDndByEmpId = createSlice({
  name: "assignDndByEmpIdThunk",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(assignDndByEmpIdThunk.pending, (state, action) => {
      state.error = null;
      state.data = null;
      state.loading = true;
    });
    builder.addCase(assignDndByEmpIdThunk.fulfilled, (state, action) => {
      if (action?.payload?.status?.toLowerCase() === "success") {
        state.error = null;
        state.data = action.payload;
      } else {
        state.error = action?.payload;
        state.data = null;
      }
      state.loading = false;
    });
    builder.addCase(assignDndByEmpIdThunk.rejected, (state, action) => {
      state.error = action.payload;
      state.data = null;
      state.loading = false;
    });
  },
});

export default assignDndByEmpId.reducer;
