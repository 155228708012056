const csvDownload = (jsonData: any) => {
    const convertToCSV = (objArray: any) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';

        // Add header row
        let headerRow = '';
        for (let index in array[0]) {
            if (headerRow !== '') headerRow += ',';
            headerRow += index;
        }
        str += headerRow + '\r\n';

        // Add data rowssss
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line !== '') line += ',';
                if (typeof array[i][index] === "string") {
                    line += array[i][index];
                }
                else if (typeof array[i][index] === "object") {
                    let collect = []
                    for (let data in array[i][index]) {
                        collect.push(Object.values(array[i][index][data])[0])
                    }
                    line += collect
                }
            }
            str += line + '\r\n';
        }
        return str;
    };

    const csv = convertToCSV(jsonData);
    const downloadLink = document.createElement('a');
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = 'data.csv';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export default csvDownload