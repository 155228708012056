import React, { SetStateAction } from 'react'
import TableContainer from './Table/TableContainer'

interface TableWithCardLoaderProps {
    totalCount?: number,
    sortBy?: any,
    setSortBy?: SetStateAction<any>,
    currentPage?: number,
    setCurrentPage?: SetStateAction<any>,
    dynamicPagination?: boolean,
    columns: Array<any>,
    data: Array<any>,
    isAddOptions?: boolean,
    handleBtnClick?: any,
    customPageSize?: number,
    className?: string,
    renderRowSubComponent?: any,
    filter?: any,
    setFilter?: SetStateAction<any>,
    loader?: boolean,
    isExpanded?: boolean,
    datacount?: number;
    listcount?: number;
    expandAll?: boolean | Boolean | undefined;
}

const TableWithCardLoader = ({
    data,
    columns,
    loader,
    totalCount,
    customPageSize,
    dynamicPagination,
    currentPage,
    setCurrentPage,
    className,
    datacount,
    expandAll,
    filter,
    handleBtnClick,
    isAddOptions,
    isExpanded,
    listcount,
    renderRowSubComponent,
    setFilter,
    setSortBy,
    sortBy
}: TableWithCardLoaderProps) => {

    const MemoizedTableContainer = React.memo(TableContainer);

    return (
        <div >
            {
                loader === true &&
                <div className="page-loader-overlay">
                    <div className='page-loader'>  </div>
                </div>
            }
            <MemoizedTableContainer
                data={data}
                columns={columns}
                totalCount={totalCount}
                customPageSize={customPageSize}
                dynamicPagination={dynamicPagination}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                loader={loader}
                className={className}
                datacount={datacount}
                expandAll={expandAll}
                filter={filter}
                handleBtnClick={handleBtnClick}
                isAddOptions={isAddOptions}
                isExpanded={isExpanded}
                listcount={listcount}
                renderRowSubComponent={renderRowSubComponent}
                setFilter={setFilter}
                setSortBy={setSortBy}
                sortBy={sortBy}
            />
        </div>
    )
}

export default TableWithCardLoader