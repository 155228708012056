import { useState } from 'react'
import { AccordionBody, AccordionHeader, AccordionItem, Label, UncontrolledAccordion } from 'reactstrap'

interface userDataProps {
  name?: string;
  employeeId?: string;
  mobileNumber?: number;
}

interface userprops {
  userData?: userDataProps;
}

const Profile = (props: userprops) => {
  const { userData } = props;

  const [open, setOpen] = useState('');

  const toggle = (id: string) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };

  return (
    <UncontrolledAccordion stayOpen toggle={toggle}      >
      <AccordionItem>
        <AccordionHeader targetId="1">
          Profile
        </AccordionHeader>
        <AccordionBody accordionId="1">
          <div className="groups">
            <div className='group-item'>
              <Label>Name</Label>
              <p className={`custom-input form-control`} >{userData?.name}</p>

            </div>
            <div className='group-item'>
              <Label>Employee ID</Label>
              <p className={`custom-input form-control`} >{userData?.employeeId}</p>
            </div>
          </div>
          <div className="groups">
            <div className='group-item'>
              <Label>Phone number</Label>
              <p className={`custom-input form-control`} >{userData?.mobileNumber}</p>
            </div>
            <div className='group-item'></div>
          </div>
        </AccordionBody>
      </AccordionItem>
    </UncontrolledAccordion>
  )
}

export default Profile