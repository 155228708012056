import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";

// access toke key
export const tokenKey="access_token"

// pass new generated access token here
const token = accessToken;

//apply base url for axios
const API_URL = process.env.REACT_APP_BACKEND_URL;

// for using ts files custom navigation
var navigation: any;

export const axiosApi = axios.create({
  baseURL: API_URL,
});


export const customNavigation = (nav: any) => {
  navigation = nav
}

if (token) {
  axiosApi.defaults.headers.common["Authorization"] = token;
}

export async function get(url: string, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url: string, data: any, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url: string, data: any, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url: string, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

export function clearToken() {
  axiosApi.defaults.headers.common["Authorization"] = "";
}

export function setToken() {
  const accessToken = `Bearer ${localStorage.getItem(tokenKey)}`;
  axiosApi.defaults.headers.common["Authorization"] = accessToken;
}
