import { SetStateAction, useEffect, useMemo, useState } from 'react'
import ToggleButton from '../../../components/buttons/ToggleButton'
import StatusDropdown from '../../../components/dropdown/StatusDropdown';
import InputSearch from '../../../components/InputSearch';
import arrow from '../../../assets/images/svgIcons/accordian_arrow.svg';
import filterIcon from '../../../assets/images/svgIcons/filter.svg';
import RejectResason from '../../../components/Models/RejectResason';
import DropDown from '../../../components/DropDown';
import { getDepartmentThunk, getDivisionThunk } from '../../../store/asyncThunks/manage/setups';
import FilterDropDown from '../../../components/FilterDropDown';
import { dayList } from '../../../constants/datas/tabsList';
import InfoCard from '../../../components/card/InfoCard';
import { changeStatusResponsibilityThunk } from '../../../store/asyncThunks/manage/mapping';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { useParams } from 'react-router-dom';
import Datepicker from '../../../components/Datepicker';
import { DateObject } from 'react-multi-date-picker';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { replaceMyResState } from '../../../store/slices/overView/getMyResponsibility';
import { replaceReporteesById } from '../../../store/slices/overView/getReporteesByIdSlice';
import { Card } from 'reactstrap';
import TableWithCardLoader from '../../../components/TableWithCardLoader';

interface MyResponsibility {
  handleChange?: (data: any) => void;
  divsonId?: string;
  deptId?: string;
  currentSubTab?: any;
  setCurrentSubTab?: (data: any) => void;
  showData: any;
  onSearch?: (data: any) => void;
  searchVal?: string;
  loading?: boolean;
  setSortBy?: SetStateAction<any>;
  sortBy?: object;
  print?: any;
  expandTableRow?: Boolean;
  filterValue?: any[];
  setFilterValue?: SetStateAction<any>;
  lists?: any;
  showfilter?: Boolean;
  context?: any;
  getReporteesData?: any;
  getreportieDetails?: Function;

  dateChange: (data: any) => void,
  selected: any,
  setResetdata?: any;
  restInput: boolean;
}

export default function MyResponsibility(props: MyResponsibility) {

  const dispatch = useAppDispatch();

  const {
    currentSubTab,
    deptId,
    divsonId,
    handleChange,
    setCurrentSubTab,
    showData,
    searchVal,
    onSearch,
    loading,
    setSortBy,
    sortBy, print, expandTableRow,
    filterValue,
    setFilterValue,
    lists, showfilter,
    context,
    getReporteesData,
    getreportieDetails,
    dateChange,
    selected,
    setResetdata,
    restInput
  } = props;

  const { id } = useParams();

  const approvedactivitystatus = useAppSelector((state) => state?.statusResponsibilityReducer?.data?.data?.status?.status);
  const getDivisionList = useAppSelector((state) => state?.getDivisionReducer?.data?.divisions);
  const getDepartmentList = useAppSelector((state) => state?.getDepartmentReducer?.data?.departments);
  const departmentList = getDepartmentList?.filter((item: any) => item.status.toLocaleLowerCase() === "active");
  const divisionList = getDivisionList?.filter((item: any) => item.status.toLocaleLowerCase() === "active");
  const getDivisionLoading = useAppSelector((state) => state?.getDivisionReducer?.loading);
  const getDepartmentLoading = useAppSelector((state) => state?.getDepartmentReducer?.loading);


  const [Statusdata, setStatusdata] = useState<any>();
  const [showModel, setShowModel] = useState(false)
  const [startDate, setStartDate] = useState<DateObject | null | Date>(new Date());
  const [tabdata, setTabdata] = useState("");

  useEffect(() => {
    const data: any = {};
    data['status'] = "ACTIVE";
    if (id) {
      data['associateFKId'] = id;
    } else {
      data['isMyResponsibility'] = true;
    }
    const payload: any = { data: data }
    dispatch(getDivisionThunk(payload));
    dispatch(getDepartmentThunk(payload));
  }, []);

  // TODO FOR REJECT REASON 
  const statusChange = (data: any) => {
    if (data.data.status === "Reject" && setShowModel) {
      setStatusdata(data);
      setShowModel(true);
    } else {
      handleStatusChange(data);
    }
  };

  const afterSuccess = async (data: any) => {
    dispatch(replaceMyResState(data));
    dispatch(replaceReporteesById(data));
  };

  const handleStatusChange = (payload: any) => {
    payload = { ...payload, afterSuccess };
    if (payload) dispatch(changeStatusResponsibilityThunk(payload));
  };

  const toggle = () => {
    setShowModel(false)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'List of responsibilities',
        accessor: 'label',
        sort: true
      },
      {
        Header: 'Duration',
        accessor: 'duration',
        sort: true
      },
      {
        Header: 'Classification',
        sort: true,
        Cell: (cell: any) => {
          return <div className={`badge-md text-capitalize`}
            style={{ backgroundColor: `${cell?.row?.original?.bgColorCode}` }}>
            <span style={{ color: `${cell?.row?.original?.textColorCode}` }}>
              {cell.row.original?.classification}</span>
          </div>
        }
      },
      {
        Header: 'Frequency',
        sort: true,
        accessor: "frequency"
      },
      {
        Header: 'Status',
        accessor: 'status',
        sort: false,
        Cell: (cell: any) => {
          return (
            <div onClick={(e) => e.stopPropagation()}>
              <StatusDropdown rejectreason={cell?.row?.original?.description} associateFKId={getReporteesData && getReporteesData} context={context} selectOptoion={statusChange} defaultValue={cell?.row?.original?.status?.toLowerCase()} id={cell?.row?.original?.mapResId} />
            </div>

          )
        }
      },
      {
        accessor: 'id',
        Cell: () => {
          return <div className={`arrow`}>
            <img src={arrow} alt="arrow" />
          </div>
        }
      },

    ], []);

  const subTable = (row: any) => {
    return <>
      <p className="description">{row.original?.scope}</p>
      <div className='profile-badge-wrapper'>
      </div>
    </>
  }

  const formatAndSetDate = (date: DateObject) => {
    const formattedDateString = `${date} - ${date}`;
    dateChange(formattedDateString);
  }

  const handleDateChange = (date: DateObject) => {
    formatAndSetDate(date);
  }

  useEffect(() => {
    tabChange();

  }, [currentSubTab]);

  const tabChange = () => {
    const data = dayList[currentSubTab]?.value?.toLowerCase();
    setTabdata(data);
    if (data === 'daily') {
      dateChange("");
    }
  }

  useEffect(() => {
    if (divisionList?.length > 0 && !divsonId) {
      if (handleChange) handleChange({ data: divisionList[0], key: "myResDiv" })
    }
    if (departmentList?.length > 0 && !deptId) {
      if (handleChange) handleChange({ data: departmentList[0], key: "myResDept" })
    }
  }, [divisionList, departmentList])

  return (
    <div ref={print}>
      <div className='myResponsibility'>
        <div className='reportee-filters'>
          <div className="dropdowns mb-0">
            <DropDown loading={getDivisionLoading} onChange={(data: any) => { if (handleChange) { handleChange({ data, key: "myResDiv" }) } }} options={divisionList && divisionList} mainClass="filter-dropdown" placeholder="Division" value={divsonId} />
            <DropDown loading={getDepartmentLoading} onChange={(data: any) => { if (handleChange) handleChange({ data, key: "myResDept" }) }} options={departmentList && departmentList} mainClass="filter-dropdown" placeholder="Department" value={deptId} />
          </div>
          <div className='table-search mb-0'>
            <div className='d-flex align-items-center gap-3'>
              <InputSearch onSearch={onSearch} value={searchVal} resetValue={restInput} />
              {showfilter &&
                <FilterDropDown filterIcon={filterIcon} checkedValues={filterValue && filterValue} setCheckedValues={setFilterValue} lists={lists && lists} />
              }
            </div>
          </div>
        </div>

        {
          divsonId && deptId &&
          <div className="days custom-scroll">
            <ToggleButton currentTab={currentSubTab} setCurrentTab={setCurrentSubTab} list={dayList} />
            {
              tabdata && tabdata === "daily" ?
                <Datepicker selected={startDate} onChange={handleDateChange} tab={tabdata} /> :
                <CustomDatePicker tab={currentSubTab}
                  selected={selected}
                  onClick={dateChange}
                />
            }
          </div>
        }

        <div className='h-100 responsibility-sub-component'>
          {
            divsonId && deptId ?
              <>
                {
                  <Card className={showData?.length === 0 ? `loader-card` : ""} >
                    <TableWithCardLoader
                      data={showData}
                      columns={columns}
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                      renderRowSubComponent={subTable}
                      isExpanded={true}
                      expandAll={expandTableRow}
                      loader={loading}
                    />
                  </Card>
                }
              </>
              :
              <InfoCard info={divsonId && deptId ? "" : <p>  Please select {!divsonId && "division"} {!divsonId && !deptId && "and"} {!deptId && "department"} </p>} />
          }

        </div>
        <RejectResason getreportieDetails={getreportieDetails} id={Statusdata?.id} associateFKId={getReporteesData && getReporteesData} toggleModel={toggle} showModel={showModel} />
      </div>
    </div>
  )
}
