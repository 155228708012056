import React, { SetStateAction } from 'react';
import { useEffect, useMemo, useState } from 'react';
import TableContainer from '../../../components/Table/TableContainer';
import ToggleSwitch from '../../../components/ToggleSwitch';
import edit from '../../../assets/images/svgIcons/edit_iocn.svg';
import InputSearch from '../../../components/InputSearch';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import ManageDivisionModal from '../../../components/Models/ManageSetupsModalComponent/ManageDivisionModal';
import { changestatusDivisionThunk, getDivisionThunk } from '../../../store/asyncThunks/manage/setups';
import ConfirmationModal from '../../../components/Models/ConfirmationModal';
import TableSkeleton from '../../../components/skeleton/TableSkeleton';
import { updateDivisionData } from '../../../store/slices/manage/setups/Division/getDivision';
import { Card } from 'reactstrap';
import TableWithCardLoader from '../../../components/TableWithCardLoader';

interface divisionsProps {
  currentPage: number;
  setCurrentPage: SetStateAction<any>;
  reset: number;
}

const Divisions = (props: divisionsProps) => {
  const { currentPage, setCurrentPage, reset } = props;

  document.title = `Setups | ${process.env.REACT_APP_DOCUMENT_TITLE}`;

  const dispatch = useAppDispatch()

  const [searchValue, setSearchValue] = useState("");
  const [EditData, setEditData] = useState<any>();
  const [statusData, setStatusData] = useState<any>(null);
  const [showModal, setshowModal] = useState(true);
  const [statusShowModal, setstatusShowModal] = useState(true)
  const pageSize = process.env.REACT_APP_PAGE_SIZE || 10;
  const getDivisionList = useAppSelector((state) => state?.getDivisionReducer?.data?.divisions);
  const getDivisionLoading = useAppSelector((state) => state?.getDivisionReducer?.loading);
  const divisionsCount = useAppSelector((state) => state?.getDivisionReducer?.data?.totalCount)
  const DivisionStatusLoader = useAppSelector((state) => state?.changeStatusDivisionReducer?.loading)

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setCurrentPage(1);
  }

  useEffect(() => {
    getData()
  }, [searchValue, currentPage, reset]);

  const getData = async () => {
    const data: any = {}
    data["data"] = { search: searchValue }
    data["currentPage"] = currentPage
    data["pageSize"] = pageSize
    await dispatch(getDivisionThunk(data));
  }

  const toggleModal = () => {
    setshowModal(!showModal);
  };

  const toggleStatusModal = () => {
    setstatusShowModal(!statusShowModal);
  };

  const handleEditClick = (Rowdata: any) => {
    setEditData(Rowdata);
    setshowModal(true);
  };

  const handleToggleSwitch = (Rowdata: any) => {
    setStatusData(Rowdata);
    setstatusShowModal(true);
  }

  const DivisionUpdateStatus = () => {
    const status = {
      status: statusData?.status === "INACTIVE" ? "ACTIVE" : "INACTIVE",
    }
    const data: any = {
      data: status,
      id: statusData?._id,
      afterSuccess: (newStatus: any) => {
        dispatch(updateDivisionData(newStatus));
        setstatusShowModal(false);
      },
    };
    dispatch(changestatusDivisionThunk(data));
  };

  const handleModalPagination = () => {
    getData();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'firstName',
        Cell: (cell: any) => {
          return <div className='label-cell'>{cell?.row?.original?.label}</div>;
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: (cell: any) => {
          return <div className='description-cell'>{cell?.row?.original?.scope}</div>;
        },
      },
      {
        Header: 'Status',
        Cell: (cell: any) => {
          const Rowdata = cell?.row?.original;
          return <ToggleSwitch id={cell.row.index} defaultChecked={cell.row.original.status?.toUpperCase() === "ACTIVE" ? true : false}
            onChange={(e: any) => {
              handleToggleSwitch(Rowdata)
            }} />
        }
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: (cell: any) => {
          const Rowdata = cell?.row?.original;
          return <div className='actions'>
            <div className="edit" onClick={() => handleEditClick(Rowdata)}>
              <img src={edit} alt="edit" />
            </div>
          </div>
        }
      },
    ], [getDivisionList]);

  return (
    <>
      <div className='table-search'>
        <InputSearch onSearch={handleSearch} value={searchValue} />
      </div>
      <Card className={!getDivisionList ? `loader-card` : ""} >
        <TableWithCardLoader
          data={getDivisionList || []}
          columns={columns}
          loader={getDivisionLoading}
          totalCount={divisionsCount}
          customPageSize={Number(pageSize)}
          dynamicPagination={true}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Card>
      {EditData && (
        <ManageDivisionModal
          showModal={showModal}
          toggle={toggleModal}
          mode='Edit'
          EditData={EditData}
          currentPage={currentPage}
          handleModalPagination={handleModalPagination}
        />
      )}
      {statusData && (
        <ConfirmationModal
          showModal={statusShowModal}
          toggle={toggleStatusModal}
          modalTitle={`Confirmation!`}
          primaryAction={DivisionUpdateStatus}
          secondaryAction={toggleStatusModal}
          loader={DivisionStatusLoader}
          primaryBtnName={`${statusData?.status === "INACTIVE" ? 'Activate' : 'Deactivate'}`}
          secoundaryBtnName="Cancel"
          description={`Are you sure, do you want to  ${statusData?.status === "ACTIVE" ? 'deactivate' : 'activate'} this division  "${statusData?.label} "?`} />
      )}
    </>
  )
}

export default Divisions