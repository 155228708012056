
import ManageRoleModel from "./ManageSetupsModalComponent/ManageRoleModal";
import ManageDepartmentModel from "./ManageSetupsModalComponent/ManageDepartmentModal";
import ManageDivisionModel from "./ManageSetupsModalComponent/ManageDivisionModal";
import ManageClassificationModel from "./ManageSetupsModalComponent/ManageClassificationModal";
import ManageFrequencyModel from "./ManageSetupsModalComponent/ManageFrequencyModal";
import ManageResponsibilityModel from "./ManageSetupsModalComponent/ManageResponsibilityModal";

interface ManageSetupsModalProps {
    showModal: boolean,
    toggle: any,
    activeTabIndex: number | null | undefined,
    mode?: 'Add' | 'Edit',
    EditData?: any,
    handleModalPagination?: Function
}

const ManageSetupsModal = (props: ManageSetupsModalProps) => {
    const { showModal, toggle, activeTabIndex, EditData, handleModalPagination } = props;

    return (
        <>
            {activeTabIndex === 0 && <ManageRoleModel
                showModal={showModal}
                toggle={toggle}
                mode='Add'
                EditData={EditData}
                handleModalPagination={handleModalPagination} />}
            {activeTabIndex === 1 && <ManageDepartmentModel
                showModal={showModal}
                toggle={toggle}
                mode='Add'
                EditData={EditData}
                handleModalPagination={handleModalPagination} />}
            {activeTabIndex === 2 && <ManageDivisionModel
                showModal={showModal}
                toggle={toggle}
                mode='Add'
                EditData={EditData}
                handleModalPagination={handleModalPagination} />}
            {activeTabIndex === 3 && <ManageClassificationModel
                showModal={showModal}
                toggle={toggle}
                mode='Add'
                EditData={EditData}
                handleModalPagination={handleModalPagination} />}
            {activeTabIndex === 4 && <ManageFrequencyModel
                showModal={showModal}
                toggle={toggle}
                mode='Add'
                EditData={EditData}
                handleModalPagination={handleModalPagination} />}
            {activeTabIndex === 5 && <ManageResponsibilityModel
                showModal={showModal}
                toggle={toggle}
                mode='Add'
                EditData={EditData}
                handleModalPagination={handleModalPagination} />}
        </>
    )
}

export default ManageSetupsModal;