import { createSlice } from "@reduxjs/toolkit";
import { defaultState } from "../../../interfaces/auth";
import { mapResponsibilityThunk } from "../../../asyncThunks/manage/mapping";

const initialState: defaultState = {
    error: null,
    data: null,
    loading: false,
};

export const mapResponsibility = createSlice({
    name: "mapResponsibility",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(mapResponsibilityThunk.pending, (state, action) => {
            state.error = null;
            state.data = null;
            state.loading = true;
        });
        builder.addCase(mapResponsibilityThunk.fulfilled, (state, action) => {
            if (action?.payload?.status?.toLowerCase() === "success") {
                state.error = null;
                state.data = action.payload;
            } else {
                state.error = action?.payload;
                state.data = null;
            }
            state.loading = false;
        });
        builder.addCase(mapResponsibilityThunk.rejected, (state, action) => {
            state.error = action.payload;
            state.data = null;
            state.loading = false;
        });
    },
});

export default mapResponsibility.reducer;
